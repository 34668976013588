// URL for UI
export const YEAR_TEACHER_LIST_URL = 'year_teachers'
export const CUR_YEAR_TEACHER_LIST_URL = 'current_teacher'

export const YEAR_TEACHER_LIST_ERROR = 'YEAR_TEACHER_LIST_ERROR'
export const YEAR_TEACHER_LIST_LOADING = 'YEAR_TEACHER_LIST_LOADING'
export const YEAR_TEACHER_LIST_SUCCESS = 'YEAR_TEACHER_LIST_SUCCESS'

export const YEAR_TEACHER_ADD_URL = 'year_teacher'
export const YEAR_TEACHER_ADD_ERROR = 'YEAR_TEACHER_ADD_ERROR'
export const YEAR_TEACHER_ADD_LOADING = 'YEAR_TEACHER_ADD_LOADING'
export const YEAR_TEACHER_ADD_SUCCESS = 'YEAR_TEACHER_ADD_SUCCESS'

export const YEAR_TEACHER_VIEW_URL = 'year_teacher'
export const YEAR_TEACHER_VIEW_ERROR = 'YEAR_TEACHER_VIEW_ERROR'
export const YEAR_TEACHER_VIEW_LOADING = 'YEAR_TEACHER_VIEW_LOADING'
export const YEAR_TEACHER_VIEW_SUCCESS = 'YEAR_TEACHER_VIEW_SUCCESS'

export const YEAR_TEACHER_DELETE_URL = 'year_teacher/delete'
export const YEAR_TEACHER_DELETE_ERROR = 'YEAR_TEACHER_DELETE_ERROR'
export const YEAR_TEACHER_DELETE_LOADING = 'YEAR_TEACHER_DELETE_LOADING'
export const YEAR_TEACHER_DELETE_SUCCESS = 'YEAR_TEACHER_DELETE_SUCCESS'

// URL for UI
export const YEAR_TEACHER_URL = {
    list: '/year_teachers',
    view: '/year_teacher',
    edit: '/year_teacher/edit',
    url_param: '/:teacherId',
}