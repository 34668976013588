import { combineReducers } from 'redux'
import {
  BANK_ACCOUNT_LIST_ERROR,
  BANK_ACCOUNT_LIST_LOADING,
  BANK_ACCOUNT_LIST_SUCCESS,

  BANK_ACCOUNT_ADD_ERROR,
  BANK_ACCOUNT_ADD_LOADING,
  BANK_ACCOUNT_ADD_SUCCESS,

  BANK_ACCOUNT_VIEW_ERROR,
  BANK_ACCOUNT_VIEW_LOADING,
  BANK_ACCOUNT_VIEW_SUCCESS,
  
  BANK_ACCOUNT_DELETE_ERROR,
  BANK_ACCOUNT_DELETE_LOADING,
  BANK_ACCOUNT_DELETE_SUCCESS,
} from 'views/BankAccount/constant'

const bankAccountListError = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_LIST_ERROR:
      return action.bankAccountListError
    default:
      return state
  }
}

const bankAccountListLoading = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_LIST_LOADING:
      return action.bankAccountListLoading
    default:
      return state
  }
}

const bankAccountListSuccess = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_LIST_SUCCESS:
      return action.bankAccountListSuccess
    default:
      return state
  }
}

const bankAccounts = (state = [], action) => {
  switch (action.type) {
    case BANK_ACCOUNT_LIST_SUCCESS:
      return action.bankAccounts
    default:
      return state
  }
}

const bankAccountAddError = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_ADD_ERROR:
      return action.bankAccountAddError
    default:
      return state
  }
}

const bankAccountAddLoading = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_ADD_LOADING:
      return action.bankAccountAddLoading
    default:
      return state
  }
}

const bankAccountAddSuccess = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_ADD_SUCCESS:
      return action.bankAccountAddSuccess
    default:
      return state
  }
}

const bankAccountAdded = (state = [], action) => {
  switch (action.type) {
    case BANK_ACCOUNT_ADD_SUCCESS:
      return action.bankAccount
    default:
      return state
  }
}

const bankAccountViewError = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_VIEW_ERROR:
      return action.bankAccountViewError
    default:
      return state
  }
}

const bankAccountViewLoading = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_VIEW_LOADING:
      return action.bankAccountViewLoading
    default:
      return state
  }
}

const bankAccountViewSuccess = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_VIEW_SUCCESS:
      return action.bankAccountViewSuccess
    default:
      return state
  }
}

const bankAccount = (state = [], action) => {
  switch (action.type) {
    case BANK_ACCOUNT_VIEW_SUCCESS:
      return action.bankAccount
    default:
      return state
  }
}

const bankAccountDeleteError = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_DELETE_ERROR:
      return action.bankAccountDeleteError
    default:
      return state
  }
}

const bankAccountDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_DELETE_LOADING:
      return action.bankAccountDeleteLoading
    default:
      return state
  }
}

const bankAccountDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_DELETE_SUCCESS:
      return action.bankAccountDeleteSuccess
    default:
      return state
  }
}

const bankAccountDeleted = (state = [], action) => {
  switch (action.type) {
    case BANK_ACCOUNT_DELETE_SUCCESS:
      return action.bankAccount
    default:
      return state
  }
}


const bankAccountReducer = combineReducers({
  bankAccountListError,
  bankAccountListLoading,
  bankAccountListSuccess,
  bankAccounts,

  bankAccountAddError,
  bankAccountAddLoading,
  bankAccountAddSuccess,
  bankAccountAdded,

  bankAccountViewError,
  bankAccountViewLoading,
  bankAccountViewSuccess,
  bankAccount,

  bankAccountDeleteError,
  bankAccountDeleteLoading,
  bankAccountDeleteSuccess,
  bankAccountDeleted,
})

export default bankAccountReducer
