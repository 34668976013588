export const BASE_URL = 'https://app.skasi.id/api/' // API Base URL, require trailing slash
// export const BASE_URL = 'http://localhost:8080/' // API Base URL, local config
export const MEDIA_URL = 'https://object.mycoop.id/skasi-dev/' // media server BASE URL, required trailing slash

export const USERTYPE_KEY = 'usertype@skasi'
export const USERNAME_KEY = 'username@skasi'
export const FULLNAME_KEY = 'fullname@skasi'
export const USERID_KEY = 'userid@skasi'
export const TOKEN_KEY = 'token@skasi'
export const EXPIRE_KEY = 'expire@skasi'
export const ROLE_KEY = 'role@skasi'
export const SCHOOL_KEY = 'school@skasi'

export const APP_NAME = 'SKASI'
export const COMPANY_NAME = 'PT. SKK'
export const APP_VER = 'v2.1.0'

// Staff stufs
export const STAFF_USERNAME_KEY = 'staff_username@skasi'
export const STAFF_FULLNAME_KEY = 'staff_fullname@skasi'
export const STAFF_USERID_KEY = 'staff_userid@skasi'
export const STAFF_TOKEN_KEY = 'staff_token@skasi'

// Countdown time verification in second
export const VERIFICATION_TIME = 120

// Captcha Status
export const IS_CAPTCHA = 'ENABLE'
export const SITE_KEY = '6Le-p28gAAAAAOC6T7PjELdi2WADP_l_RVDrUhtE'
// export const SITE_KEY = '6LfICMQaAAAAAKCV1tMknbGg7033QRlqWBDZ3Bec' // Local config

// User type key
// user type: user, staff, teacher, parent, student
export const userType = {
  user: 'user',
  staff: 'staff',
  teacher: 'teacher',
  parent: 'parent',
  student: 'student',
}

export const userRole = {
  staff: 'staff',
  admin: 'admin',
}

export const materiType = {
  materi: 'Materi',
  tugas: 'Tugas',
}

export const selectionStatus = {
  diterima: 'Diterima',
  ditolak: 'Ditolak',
}

export const candidateStatus = {
  diterima: 'Diterima',
  ditolak: 'Ditolak',
}

export const candidateProcess = {
  new: 'NEW',
  confirm: 'CONFIRM',
  payment: 'PAYMENT',
  selection: 'SELECTION',
  finish: 'FINISH',
}