import { combineReducers } from 'redux'
import {
  RESPONSE_LIST_ERROR,
  RESPONSE_LIST_LOADING,
  RESPONSE_LIST_SUCCESS,

  RESPONSE_ADD_ERROR,
  RESPONSE_ADD_LOADING,
  RESPONSE_ADD_SUCCESS,

  RESPONSE_ATTACH_ADD_ERROR,
  RESPONSE_ATTACH_ADD_LOADING,
  RESPONSE_ATTACH_ADD_SUCCESS,

  RESPONSE_VIEW_ERROR,
  RESPONSE_VIEW_LOADING,
  RESPONSE_VIEW_SUCCESS,

  RESPONSE_DELETE_ERROR,
  RESPONSE_DELETE_LOADING,
  RESPONSE_DELETE_SUCCESS,

  RESPONSE_GRADING_ERROR,
  RESPONSE_GRADING_LOADING,
  RESPONSE_GRADING_SUCCESS,
} from 'views/Response/constant'

const responseListError = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_LIST_ERROR:
      return action.responseListError
    default:
      return state
  }
}

const responseListLoading = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_LIST_LOADING:
      return action.responseListLoading
    default:
      return state
  }
}

const responseListSuccess = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_LIST_SUCCESS:
      return action.responseListSuccess
    default:
      return state
  }
}

const responses = (state = [], action) => {
  switch (action.type) {
    case RESPONSE_LIST_SUCCESS:
      return action.responses
    default:
      return state
  }
}

const responseAddError = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_ADD_ERROR:
      return action.responseAddError
    default:
      return state
  }
}

const responseAddLoading = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_ADD_LOADING:
      return action.responseAddLoading
    default:
      return state
  }
}

const responseAddSuccess = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_ADD_SUCCESS:
      return action.responseAddSuccess
    default:
      return state
  }
}

const responseAdded = (state = [], action) => {
  switch (action.type) {
    case RESPONSE_ADD_SUCCESS:
      return action.response
    default:
      return state
  }
}

const responseAttachAddError = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_ATTACH_ADD_ERROR:
      return action.responseAttachAddError
    default:
      return state
  }
}

const responseAttachAddLoading = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_ATTACH_ADD_LOADING:
      return action.responseAttachAddLoading
    default:
      return state
  }
}

const responseAttachAddSuccess = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_ATTACH_ADD_SUCCESS:
      return action.responseAttachAddSuccess
    default:
      return state
  }
}

const responseAttachAdded = (state = [], action) => {
  switch (action.type) {
    case RESPONSE_ATTACH_ADD_SUCCESS:
      return action.responseAdded
    default:
      return state
  }
}

const responseViewError = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_VIEW_ERROR:
      return action.responseViewError
    default:
      return state
  }
}

const responseViewLoading = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_VIEW_LOADING:
      return action.responseViewLoading
    default:
      return state
  }
}

const responseViewSuccess = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_VIEW_SUCCESS:
      return action.responseViewSuccess
    default:
      return state
  }
}

const response = (state = [], action) => {
  switch (action.type) {
    case RESPONSE_VIEW_SUCCESS:
      return action.response
    default:
      return state
  }
}

const responseDeleteError = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_DELETE_ERROR:
      return action.responseDeleteError
    default:
      return state
  }
}

const responseDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_DELETE_LOADING:
      return action.responseDeleteLoading
    default:
      return state
  }
}

const responseDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_DELETE_SUCCESS:
      return action.responseDeleteSuccess
    default:
      return state
  }
}

const responseDeleted = (state = [], action) => {
  switch (action.type) {
    case RESPONSE_DELETE_SUCCESS:
      return action.response
    default:
      return state
  }
}

const responseGradingError = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_GRADING_ERROR:
      return action.responseGradingError
    default:
      return state
  }
}

const responseGradingLoading = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_GRADING_LOADING:
      return action.responseGradingLoading
    default:
      return state
  }
}

const responseGradingSuccess = (state = false, action) => {
  switch (action.type) {
    case RESPONSE_GRADING_SUCCESS:
      return action.responseGradingSuccess
    default:
      return state
  }
}

const responseGradinged = (state = [], action) => {
  switch (action.type) {
    case RESPONSE_GRADING_SUCCESS:
      return action.response
    default:
      return state
  }
}


const responseReducer = combineReducers({
  responseListError,
  responseListLoading,
  responseListSuccess,
  responses,

  responseAddError,
  responseAddLoading,
  responseAddSuccess,
  responseAdded,

  responseAttachAddError,
  responseAttachAddLoading,
  responseAttachAddSuccess,
  responseAttachAdded,

  responseViewError,
  responseViewLoading,
  responseViewSuccess,
  response,

  responseDeleteError,
  responseDeleteLoading,
  responseDeleteSuccess,
  responseDeleted,

  responseGradingError,
  responseGradingLoading,
  responseGradingSuccess,
  responseGradinged,
})

export default responseReducer
