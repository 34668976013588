import { combineReducers } from 'redux'
import {
  ADMISSION_ACCOUNT_LIST_ERROR,
  ADMISSION_ACCOUNT_LIST_LOADING,
  ADMISSION_ACCOUNT_LIST_SUCCESS,

  ADMISSION_ACCOUNT_ADD_ERROR,
  ADMISSION_ACCOUNT_ADD_LOADING,
  ADMISSION_ACCOUNT_ADD_SUCCESS,

  ADMISSION_ACCOUNT_DELETE_ERROR,
  ADMISSION_ACCOUNT_DELETE_LOADING,
  ADMISSION_ACCOUNT_DELETE_SUCCESS,
} from 'views/AdmissionAccount/constant'
const admissionAccountListError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_LIST_ERROR:
      return action.admissionAccountListError
    default:
      return state
  }
}

const admissionAccountListLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_LIST_LOADING:
      return action.admissionAccountListLoading
    default:
      return state
  }
}

const admissionAccountListSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_LIST_SUCCESS:
      return action.admissionAccountListSuccess
    default:
      return state
  }
}

const admissionAccounts = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_LIST_SUCCESS:
      return action.admissions
    default:
      return state
  }
}

const admissionAccountAddError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_ADD_ERROR:
      return action.admissionAccountAddError
    default:
      return state
  }
}

const admissionAccountAddLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_ADD_LOADING:
      return action.admissionAccountAddLoading
    default:
      return state
  }
}

const admissionAccountAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_ADD_SUCCESS:
      return action.admissionAccountAddSuccess
    default:
      return state
  }
}

const admissionAccountAdded = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_ADD_SUCCESS:
      return action.admission
    default:
      return state
  }
}
const admissionAccountDeleteError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_DELETE_ERROR:
      return action.admissionAccountDeleteError
    default:
      return state
  }
}

const admissionAccountDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_DELETE_LOADING:
      return action.admissionAccountDeleteLoading
    default:
      return state
  }
}

const admissionAccountDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_DELETE_SUCCESS:
      return action.admissionAccountDeleteSuccess
    default:
      return state
  }
}

const admissionAccountDeleted = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_ACCOUNT_DELETE_SUCCESS:
      return action.admission
    default:
      return state
  }
}

const admissionReducer = combineReducers({
  admissionAccountListError,
  admissionAccountListLoading,
  admissionAccountListSuccess,
  admissionAccounts,

  admissionAccountAddError,
  admissionAccountAddLoading,
  admissionAccountAddSuccess,
  admissionAccountAdded,

  admissionAccountDeleteError,
  admissionAccountDeleteLoading,
  admissionAccountDeleteSuccess,
  admissionAccountDeleted,
})

export default admissionReducer
