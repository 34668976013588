// URL for UI
export const MESSAGE_LIST_URL = 'messages'
export const MESSAGE_STUDENT_LIST_URL = 'student_messages'

export const MESSAGE_LIST_ERROR = 'MESSAGE_LIST_ERROR'
export const MESSAGE_LIST_LOADING = 'MESSAGE_LIST_LOADING'
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS'

export const MESSAGE_ADD_URL = 'message'
export const MESSAGE_ADD_ERROR = 'MESSAGE_ADD_ERROR'
export const MESSAGE_ADD_LOADING = 'MESSAGE_ADD_LOADING'
export const MESSAGE_ADD_SUCCESS = 'MESSAGE_ADD_SUCCESS'

export const MESSAGE_VIEW_URL = 'message'
export const MESSAGE_VIEW_ERROR = 'MESSAGE_VIEW_ERROR'
export const MESSAGE_VIEW_LOADING = 'MESSAGE_VIEW_LOADING'
export const MESSAGE_VIEW_SUCCESS = 'MESSAGE_VIEW_SUCCESS'

export const MESSAGE_DELETE_URL = 'message/delete'
export const MESSAGE_DELETE_ERROR = 'MESSAGE_DELETE_ERROR'
export const MESSAGE_DELETE_LOADING = 'MESSAGE_DELETE_LOADING'
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS'

export const MESSAGE_IMAGE_ADD_URL = 'message/image'
export const MESSAGE_IMAGE_ADD_ERROR = 'MESSAGE_IMAGE_ADD_ERROR'
export const MESSAGE_IMAGE_ADD_LOADING = 'MESSAGE_IMAGE_ADD_LOADING'
export const MESSAGE_IMAGE_ADD_SUCCESS = 'MESSAGE_IMAGE_ADD_SUCCESS'

// URL for UI
export const MESSAGE_URL = {
    list: '/messages',
    view: '/message',
    edit: '/message/edit',
    url_param: '/:messageId'
}