// URL for UI
export const SUBJECT_LIST_URL = 'subjects'

export const SUBJECT_LIST_ERROR = 'SUBJECT_LIST_ERROR'
export const SUBJECT_LIST_LOADING = 'SUBJECT_LIST_LOADING'
export const SUBJECT_LIST_SUCCESS = 'SUBJECT_LIST_SUCCESS'

export const SUBJECT_ADD_URL = 'subject'
export const SUBJECT_ADD_ERROR = 'SUBJECT_ADD_ERROR'
export const SUBJECT_ADD_LOADING = 'SUBJECT_ADD_LOADING'
export const SUBJECT_ADD_SUCCESS = 'SUBJECT_ADD_SUCCESS'

export const SUBJECT_VIEW_URL = 'subject'
export const SUBJECT_VIEW_ERROR = 'SUBJECT_VIEW_ERROR'
export const SUBJECT_VIEW_LOADING = 'SUBJECT_VIEW_LOADING'
export const SUBJECT_VIEW_SUCCESS = 'SUBJECT_VIEW_SUCCESS'

export const SUBJECT_DELETE_URL = 'subject/delete'
export const SUBJECT_DELETE_ERROR = 'SUBJECT_DELETE_ERROR'
export const SUBJECT_DELETE_LOADING = 'SUBJECT_DELETE_LOADING'
export const SUBJECT_DELETE_SUCCESS = 'SUBJECT_DELETE_SUCCESS'

export const SUBJECT_ACTIVATE_URL = 'subject/activate'
export const SUBJECT_ACTIVATE_ERROR = 'SUBJECT_ACTIVATE_ERROR'
export const SUBJECT_ACTIVATE_LOADING = 'SUBJECT_ACTIVATE_LOADING'
export const SUBJECT_ACTIVATE_SUCCESS = 'SUBJECT_ACTIVATE_SUCCESS'

export const SUBJECT_BULK_URL = 'subject/bulk'
export const SUBJECT_BULK_ERROR = 'SUBJECT_BULK_ERROR'
export const SUBJECT_BULK_LOADING = 'SUBJECT_BULK_LOADING'
export const SUBJECT_BULK_SUCCESS = 'SUBJECT_BULK_SUCCESS'

export const SUBJECT_IMAGE_ADD_URL = 'subject/image'
export const SUBJECT_IMAGE_ADD_ERROR = 'SUBJECT_IMAGE_ADD_ERROR'
export const SUBJECT_IMAGE_ADD_LOADING = 'SUBJECT_IMAGE_ADD_LOADING'
export const SUBJECT_IMAGE_ADD_SUCCESS = 'SUBJECT_IMAGE_ADD_SUCCESS'

// URL for UI
export const SUBJECT_URL = {
    list: '/subjects',
    view: '/subject',
    edit: '/subject/edit',
    bulk: '/subject/bulk',
    url_param: '/:subjectId'
}