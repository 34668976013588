// URL for UI
export const ADMISSION_LIST_URL = 'admissions'
export const ADMISSION_LIST_ERROR = 'ADMISSION_LIST_ERROR'
export const ADMISSION_LIST_LOADING = 'ADMISSION_LIST_LOADING'
export const ADMISSION_LIST_SUCCESS = 'ADMISSION_LIST_SUCCESS'

// URL to get admission by teacher
export const ADMISSION_TEACHER_LIST_URL = 'teacher_admissions'
export const ADMISSION_TEACHER_LIST_ERROR = 'ADMISSION_TEACHER_LIST_ERROR'
export const ADMISSION_TEACHER_LIST_LOADING = 'ADMISSION_TEACHER_LIST_LOADING'
export const ADMISSION_TEACHER_LIST_SUCCESS = 'ADMISSION_TEACHER_LIST_SUCCESS'

export const ADMISSION_ADD_URL = 'admission'
export const ADMISSION_ADD_ERROR = 'ADMISSION_ADD_ERROR'
export const ADMISSION_ADD_LOADING = 'ADMISSION_ADD_LOADING'
export const ADMISSION_ADD_SUCCESS = 'ADMISSION_ADD_SUCCESS'

export const ADMISSION_VIEW_URL = 'admission'
export const ADMISSION_VIEW_ERROR = 'ADMISSION_VIEW_ERROR'
export const ADMISSION_VIEW_LOADING = 'ADMISSION_VIEW_LOADING'
export const ADMISSION_VIEW_SUCCESS = 'ADMISSION_VIEW_SUCCESS'

export const ADMISSION_DELETE_URL = 'admission/delete'
export const ADMISSION_DELETE_ERROR = 'ADMISSION_DELETE_ERROR'
export const ADMISSION_DELETE_LOADING = 'ADMISSION_DELETE_LOADING'
export const ADMISSION_DELETE_SUCCESS = 'ADMISSION_DELETE_SUCCESS'

export const ADMISSION_ACTIVATE_URL = 'admission/activate'
export const ADMISSION_ACTIVATE_ERROR = 'ADMISSION_ACTIVATE_ERROR'
export const ADMISSION_ACTIVATE_LOADING = 'ADMISSION_ACTIVATE_LOADING'
export const ADMISSION_ACTIVATE_SUCCESS = 'ADMISSION_ACTIVATE_SUCCESS'
// URL for UI
export const ADMISSION_URL = {
    list: '/admissions',
    view: '/admission',
    edit: '/admission/edit',
    url_param: '/:admissionId',
}