import { combineReducers } from 'redux'
import {
  PRODUCT_LIST_ERROR,
  PRODUCT_LIST_LOADING,
  PRODUCT_LIST_SUCCESS,

  PRODUCT_ADD_ERROR,
  PRODUCT_ADD_LOADING,
  PRODUCT_ADD_SUCCESS,

  PRODUCT_VIEW_ERROR,
  PRODUCT_VIEW_LOADING,
  PRODUCT_VIEW_SUCCESS,
  
  PRODUCT_DELETE_ERROR,
  PRODUCT_DELETE_LOADING,
  PRODUCT_DELETE_SUCCESS,

  PRODUCT_ACTIVATE_ERROR,
  PRODUCT_ACTIVATE_LOADING,
  PRODUCT_ACTIVATE_SUCCESS,
} from 'views/Product/constant'

const productListError = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_LIST_ERROR:
      return action.productListError
    default:
      return state
  }
}

const productListLoading = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_LIST_LOADING:
      return action.productListLoading
    default:
      return state
  }
}

const productListSuccess = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_LIST_SUCCESS:
      return action.productListSuccess
    default:
      return state
  }
}

const products = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_LIST_SUCCESS:
      return action.products
    default:
      return state
  }
}

const productAddError = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_ADD_ERROR:
      return action.productAddError
    default:
      return state
  }
}

const productAddLoading = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_ADD_LOADING:
      return action.productAddLoading
    default:
      return state
  }
}

const productAddSuccess = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_ADD_SUCCESS:
      return action.productAddSuccess
    default:
      return state
  }
}

const productAdded = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_ADD_SUCCESS:
      return action.product
    default:
      return state
  }
}

const productViewError = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_VIEW_ERROR:
      return action.productViewError
    default:
      return state
  }
}

const productViewLoading = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_VIEW_LOADING:
      return action.productViewLoading
    default:
      return state
  }
}

const productViewSuccess = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_VIEW_SUCCESS:
      return action.productViewSuccess
    default:
      return state
  }
}

const product = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_VIEW_SUCCESS:
      return action.product
    default:
      return state
  }
}

const productDeleteError = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_ERROR:
      return action.productDeleteError
    default:
      return state
  }
}

const productDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_LOADING:
      return action.productDeleteLoading
    default:
      return state
  }
}

const productDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_SUCCESS:
      return action.productDeleteSuccess
    default:
      return state
  }
}

const productDeleted = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_DELETE_SUCCESS:
      return action.product
    default:
      return state
  }
}

const productActivateError = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_ACTIVATE_ERROR:
      return action.productActivateError
    default:
      return state
  }
}

const productActivateLoading = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_ACTIVATE_LOADING:
      return action.productActivateLoading
    default:
      return state
  }
}

const productActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case PRODUCT_ACTIVATE_SUCCESS:
      return action.productActivateSuccess
    default:
      return state
  }
}

const productActivated = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_ACTIVATE_SUCCESS:
      return action.product
    default:
      return state
  }
}


const productReducer = combineReducers({
  productListError,
  productListLoading,
  productListSuccess,
  products,

  productAddError,
  productAddLoading,
  productAddSuccess,
  productAdded,

  productViewError,
  productViewLoading,
  productViewSuccess,
  product,

  productDeleteError,
  productDeleteLoading,
  productDeleteSuccess,
  productDeleted,

  productActivateError,
  productActivateLoading,
  productActivateSuccess,
  productActivated,
})

export default productReducer
