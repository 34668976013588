// URL for UI
export const YEAR_STUDENT_LIST_URL = 'year_students'

export const YEAR_STUDENT_LIST_ERROR = 'YEAR_STUDENT_LIST_ERROR'
export const YEAR_STUDENT_LIST_LOADING = 'YEAR_STUDENT_LIST_LOADING'
export const YEAR_STUDENT_LIST_SUCCESS = 'YEAR_STUDENT_LIST_SUCCESS'

export const YEAR_STUDENT_ADD_URL = 'year_student'
export const YEAR_STUDENT_ADD_ERROR = 'YEAR_STUDENT_ADD_ERROR'
export const YEAR_STUDENT_ADD_LOADING = 'YEAR_STUDENT_ADD_LOADING'
export const YEAR_STUDENT_ADD_SUCCESS = 'YEAR_STUDENT_ADD_SUCCESS'

export const YEAR_STUDENT_VIEW_URL = 'year_student'
export const YEAR_STUDENT_VIEW_ERROR = 'YEAR_STUDENT_VIEW_ERROR'
export const YEAR_STUDENT_VIEW_LOADING = 'YEAR_STUDENT_VIEW_LOADING'
export const YEAR_STUDENT_VIEW_SUCCESS = 'YEAR_STUDENT_VIEW_SUCCESS'

export const YEAR_STUDENT_DELETE_URL = 'year_student/delete'
export const YEAR_STUDENT_DELETE_ERROR = 'YEAR_STUDENT_DELETE_ERROR'
export const YEAR_STUDENT_DELETE_LOADING = 'YEAR_STUDENT_DELETE_LOADING'
export const YEAR_STUDENT_DELETE_SUCCESS = 'YEAR_STUDENT_DELETE_SUCCESS'
