// URL for UI
export const USER_LIST_URL = 'manage/users'

export const USER_LIST_ERROR = 'USER_LIST_ERROR'
export const USER_LIST_LOADING = 'USER_LIST_LOADING'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'

export const USER_ADD_URL = 'manage/user'
export const USER_ADD_ERROR = 'USER_ADD_ERROR'
export const USER_ADD_LOADING = 'USER_ADD_LOADING'
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS'

export const USER_VIEW_URL = 'manage/user'
export const USER_VIEW_ERROR = 'USER_VIEW_ERROR'
export const USER_VIEW_LOADING = 'USER_VIEW_LOADING'
export const USER_VIEW_SUCCESS = 'USER_VIEW_SUCCESS'

export const USER_DELETE_URL = 'manage/user/delete'
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR'
export const USER_DELETE_LOADING = 'USER_DELETE_LOADING'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'

// URL for UI
export const USER_URL = {
    list: '/users',
    view: '/user',
    edit: '/user/edit',
    url_param: '/:userId'
}