import { combineReducers } from 'redux'
import {
  PROFILE_CPW_ERROR,
  PROFILE_CPW_LOADING,
  PROFILE_CPW_SUCCESS,
} from './constant'

const profileCpwError = (state = false, action) => {
  switch (action.type) {
    case PROFILE_CPW_ERROR:
      return action.profileCpwError
    default:
      return state
  }
}

const profileCpwLoading = (state = false, action) => {
  switch (action.type) {
    case PROFILE_CPW_LOADING:
      return action.profileCpwLoading
    default:
      return state
  }
}

const profileCpwSuccess = (state = false, action) => {
  switch (action.type) {
    case PROFILE_CPW_SUCCESS:
      return action.profileCpwSuccess
    default:
      return state
  }
}

const profileCpw = (state = [], action) => {
  switch (action.type) {
    case PROFILE_CPW_SUCCESS:
      return action.profile
    default:
      return state
  }
}

const profileReducer = combineReducers({
  profileCpwError,
  profileCpwLoading,
  profileCpwSuccess,
  profileCpw,
})

export default profileReducer
