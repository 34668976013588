// URL for API
export const ACCOUNT_LIST_URL = 'accounts'
export const ACCOUNT_LIST_BY_PRODUCT_URL = 'accounts/product'
export const ACCOUNT_LIST_BY_STUDENT_URL = 'accounts/student'

export const ACCOUNT_LIST_ERROR = 'ACCOUNT_LIST_ERROR'
export const ACCOUNT_LIST_LOADING = 'ACCOUNT_LIST_LOADING'
export const ACCOUNT_LIST_SUCCESS = 'ACCOUNT_LIST_SUCCESS'

export const ACCOUNT_ADD_URL = 'account'
export const ACCOUNT_ADD_ERROR = 'ACCOUNT_ADD_ERROR'
export const ACCOUNT_ADD_LOADING = 'ACCOUNT_ADD_LOADING'
export const ACCOUNT_ADD_SUCCESS = 'ACCOUNT_ADD_SUCCESS'

export const ACCOUNT_VIEW_URL = 'account'
export const ACCOUNT_VIEW_ERROR = 'ACCOUNT_VIEW_ERROR'
export const ACCOUNT_VIEW_LOADING = 'ACCOUNT_VIEW_LOADING'
export const ACCOUNT_VIEW_SUCCESS = 'ACCOUNT_VIEW_SUCCESS'

export const ACCOUNT_DELETE_URL = 'account/delete'
export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR'
export const ACCOUNT_DELETE_LOADING = 'ACCOUNT_DELETE_LOADING'
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS'

export const ACCOUNT_BULK_URL = 'account/bulk'
export const ACCOUNT_BULK_ERROR = 'ACCOUNT_BULK_ERROR'
export const ACCOUNT_BULK_LOADING = 'ACCOUNT_BULK_LOADING'
export const ACCOUNT_BULK_SUCCESS = 'ACCOUNT_BULK_SUCCESS'

export const ACCOUNT_ADD_MULTI_URL = 'account/multi'
export const ACCOUNT_ADD_MULTI_ERROR = 'ACCOUNT_ADD_MULTI_ERROR'
export const ACCOUNT_ADD_MULTI_LOADING = 'ACCOUNT_ADD_MULTI_LOADING'
export const ACCOUNT_ADD_MULTI_SUCCESS = 'ACCOUNT_ADD_MULTI_SUCCESS'

export const ACCOUNT_TOGGLE_URL = 'account/toggle'
export const ACCOUNT_TOGGLE_ERROR = 'ACCOUNT_TOGGLE_ERROR'
export const ACCOUNT_TOGGLE_LOADING = 'ACCOUNT_TOGGLE_LOADING'
export const ACCOUNT_TOGGLE_SUCCESS = 'ACCOUNT_TOGGLE_SUCCESS'

// URL for UI
export const ACCOUNT_URL = {
    list: '/accounts',
    view: '/account',
    bulk: '/bulk',
    url_param: '/:accountId'
}