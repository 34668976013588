import { combineReducers } from 'redux'
import {
  COOP_LIST_ERROR,
  COOP_LIST_LOADING,
  COOP_LIST_SUCCESS,

  COOP_ADD_ERROR,
  COOP_ADD_LOADING,
  COOP_ADD_SUCCESS,

  COOP_VIEW_ERROR,
  COOP_VIEW_LOADING,
  COOP_VIEW_SUCCESS,
  
  COOP_DELETE_ERROR,
  COOP_DELETE_LOADING,
  COOP_DELETE_SUCCESS,
} from 'views/Coop/constant'

const coopListError = (state = false, action) => {
  switch (action.type) {
    case COOP_LIST_ERROR:
      return action.coopListError
    default:
      return state
  }
}

const coopListLoading = (state = false, action) => {
  switch (action.type) {
    case COOP_LIST_LOADING:
      return action.coopListLoading
    default:
      return state
  }
}

const coopListSuccess = (state = false, action) => {
  switch (action.type) {
    case COOP_LIST_SUCCESS:
      return action.coopListSuccess
    default:
      return state
  }
}

const coops = (state = [], action) => {
  switch (action.type) {
    case COOP_LIST_SUCCESS:
      return action.coops
    default:
      return state
  }
}

const coopAddError = (state = false, action) => {
  switch (action.type) {
    case COOP_ADD_ERROR:
      return action.coopAddError
    default:
      return state
  }
}

const coopAddLoading = (state = false, action) => {
  switch (action.type) {
    case COOP_ADD_LOADING:
      return action.coopAddLoading
    default:
      return state
  }
}

const coopAddSuccess = (state = false, action) => {
  switch (action.type) {
    case COOP_ADD_SUCCESS:
      return action.coopAddSuccess
    default:
      return state
  }
}

const coopAdded = (state = [], action) => {
  switch (action.type) {
    case COOP_ADD_SUCCESS:
      return action.coop
    default:
      return state
  }
}

const coopViewError = (state = false, action) => {
  switch (action.type) {
    case COOP_VIEW_ERROR:
      return action.coopViewError
    default:
      return state
  }
}

const coopViewLoading = (state = false, action) => {
  switch (action.type) {
    case COOP_VIEW_LOADING:
      return action.coopViewLoading
    default:
      return state
  }
}

const coopViewSuccess = (state = false, action) => {
  switch (action.type) {
    case COOP_VIEW_SUCCESS:
      return action.coopViewSuccess
    default:
      return state
  }
}

const coop = (state = [], action) => {
  switch (action.type) {
    case COOP_VIEW_SUCCESS:
      return action.coop
    default:
      return state
  }
}

const coopDeleteError = (state = false, action) => {
  switch (action.type) {
    case COOP_DELETE_ERROR:
      return action.coopDeleteError
    default:
      return state
  }
}

const coopDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case COOP_DELETE_LOADING:
      return action.coopDeleteLoading
    default:
      return state
  }
}

const coopDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case COOP_DELETE_SUCCESS:
      return action.coopDeleteSuccess
    default:
      return state
  }
}

const coopDeleted = (state = [], action) => {
  switch (action.type) {
    case COOP_DELETE_SUCCESS:
      return action.coop
    default:
      return state
  }
}


const coopReducer = combineReducers({
  coopListError,
  coopListLoading,
  coopListSuccess,
  coops,

  coopAddError,
  coopAddLoading,
  coopAddSuccess,
  coopAdded,

  coopViewError,
  coopViewLoading,
  coopViewSuccess,
  coop,

  coopDeleteError,
  coopDeleteLoading,
  coopDeleteSuccess,
  coopDeleted,
})

export default coopReducer
