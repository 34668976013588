import { combineReducers } from 'redux'
import {
  DASHBOARD_PAID_LIST_ERROR,
  DASHBOARD_PAID_LIST_LOADING,
  DASHBOARD_PAID_LIST_SUCCESS,
} from 'views/Dashboard/Paid/constant'

const dashboardPaidListError = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_PAID_LIST_ERROR:
      return action.dashboardPaidListError
    default:
      return state
  }
}

const dashboardPaidListLoading = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_PAID_LIST_LOADING:
      return action.dashboardPaidListLoading
    default:
      return state
  }
}

const dashboardPaidListSuccess = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_PAID_LIST_SUCCESS:
      return action.dashboardPaidListSuccess
    default:
      return state
  }
}

const dashboardPaids = (state = [], action) => {
  switch (action.type) {
    case DASHBOARD_PAID_LIST_SUCCESS:
      return action.dashboardPaids
    default:
      return state
  }
}


const dashboardPaidReducer = combineReducers({
  dashboardPaidListError,
  dashboardPaidListLoading,
  dashboardPaidListSuccess,
  dashboardPaids,
})

export default dashboardPaidReducer
