// URL for API
export const SCHOOL_STATISTIC_URL = 'statistic/user'
export const SCHOOL_LIST_URL = 'manage/schools'
export const SCHOOL_LIST_ERROR = 'SCHOOL_LIST_ERROR'
export const SCHOOL_LIST_LOADING = 'SCHOOL_LIST_LOADING'
export const SCHOOL_LIST_SUCCESS = 'SCHOOL_LIST_SUCCESS'

export const SCHOOL_ADD_URL = 'manage/school'
export const SCHOOL_ADD_ERROR = 'SCHOOL_ADD_ERROR'
export const SCHOOL_ADD_LOADING = 'SCHOOL_ADD_LOADING'
export const SCHOOL_ADD_SUCCESS = 'SCHOOL_ADD_SUCCESS'

export const SCHOOL_VIEW_URL = 'manage/school'
export const SCHOOL_VIEW_ERROR = 'SCHOOL_VIEW_ERROR'
export const SCHOOL_VIEW_LOADING = 'SCHOOL_VIEW_LOADING'
export const SCHOOL_VIEW_SUCCESS = 'SCHOOL_VIEW_SUCCESS'

export const SCHOOL_DELETE_URL = 'school/delete'
export const SCHOOL_DELETE_ERROR = 'SCHOOL_DELETE_ERROR'
export const SCHOOL_DELETE_LOADING = 'SCHOOL_DELETE_LOADING'
export const SCHOOL_DELETE_SUCCESS = 'SCHOOL_DELETE_SUCCESS'

export const SCHOOL_PROFILE_URL = 'school/profile'
export const SCHOOL_PROFILE_ERROR = 'SCHOOL_PROFILE_ERROR'
export const SCHOOL_PROFILE_LOADING = 'SCHOOL_PROFILE_LOADING'
export const SCHOOL_PROFILE_SUCCESS = 'SCHOOL_PROFILE_SUCCESS'

export const SCHOOL_EDIT_PROFILE_URL = 'school/profile'
export const SCHOOL_EDIT_PROFILE_ERROR = 'SCHOOL_EDIT_PROFILE_ERROR'
export const SCHOOL_EDIT_PROFILE_LOADING = 'SCHOOL_EDIT_PROFILE_LOADING'
export const SCHOOL_EDIT_PROFILE_SUCCESS = 'SCHOOL_EDIT_PROFILE_SUCCESS'

export const SCHOOL_LOGO_URL = 'school/logo'
export const SCHOOL_LOGO_ERROR = 'SCHOOL_LOGO_ERROR'
export const SCHOOL_LOGO_LOADING = 'SCHOOL_LOGO_LOADING'
export const SCHOOL_LOGO_SUCCESS = 'SCHOOL_LOGO_SUCCESS'

export const SCHOOL_CODE_URL = 'school/code'
export const SCHOOL_CODE_ERROR = 'SCHOOL_CODE_ERROR'
export const SCHOOL_CODE_LOADING = 'SCHOOL_CODE_LOADING'
export const SCHOOL_CODE_SUCCESS = 'SCHOOL_CODE_SUCCESS'

export const SCHOOL_REGISTER_URL = 'school/register'
export const SCHOOL_REGISTER_ERROR = 'SCHOOL_REGISTER_ERROR'
export const SCHOOL_REGISTER_LOADING = 'SCHOOL_REGISTER_LOADING'
export const SCHOOL_REGISTER_SUCCESS = 'SCHOOL_REGISTER_SUCCESS'

export const SCHOOL_ACTIVATE_URL = 'school/activate'
export const SCHOOL_ACTIVATE_ERROR = 'SCHOOL_ACTIVATE_ERROR'
export const SCHOOL_ACTIVATE_LOADING = 'SCHOOL_ACTIVATE_LOADING'
export const SCHOOL_ACTIVATE_SUCCESS = 'SCHOOL_ACTIVATE_SUCCESS'

// URL for UI
export const SCHOOL_URL = {
    list: '/schools',
    view: '/school',
    edit: '/school/edit',
    profile: '/school/profile',
    url_param: '/:schoolId',
}