import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import PrivateRoute from 'PrivateRoute'
import 'scss/App.scss';
import Pulse from 'components/Spinner';

const loading = () => <div className="animated fadeIn pt-3 text-center"><Pulse /></div>;

// Containers
const DefaultLayout = React.lazy(() => import('containers/DefaultLayout'));

// Auth
const LoginUser = React.lazy(() => import('views/Login/LoginUser'));
const LoginSchool = React.lazy(() => import('views/Login/LoginSchool'));
const LoginParent = React.lazy(() => import('views/Login/LoginParent'));
const LoginStudent = React.lazy(() => import('views/Login/LoginStudent'));
const RegisterSchool = React.lazy(() => import('views/Login/RegisterSchool'));
const RegisterSuccess = React.lazy(() => import('views/Login/RegisterSuccess'));

// Landing Page
const Landing = React.lazy(() => import('views/Login/Landing'));

// Errors
const Page404 = React.lazy(() => import('views/Error/Page404'));
const Page500 = React.lazy(() => import('views/Error/Page500'));

class App extends Component {
  render() {
    return (
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

          <Route
            exact
            path="/landing"
            name="SKASI"
            render={props => <Landing isAuthenticated={this.props.isAuthenticated} {...props} />} />

          <Route
            exact
            path="/auth/user"
            name="Login"
            render={props => <LoginUser isAuthenticated={this.props.isAuthenticated} {...props} />} />

          <Route
            exact
            path="/auth/login"
            name="Login School"
            render={props => <LoginSchool isAuthenticated={this.props.isAuthenticated} {...props} />} />

          <Route
            exact
            path="/auth/parent"
            name="Login Orang Tua"
            render={props => <LoginParent isAuthenticated={this.props.isAuthenticated} {...props} />} />

          <Route
            exact
            path="/auth/student"
            name="Login Siswa"
            render={props => <LoginStudent isAuthenticated={this.props.isAuthenticated} {...props} />} />

          <Route
            exact
            path="/school/register"
            name="Register Sekolah"
            render={props => <RegisterSchool isAuthenticated={this.props.isAuthenticated} {...props} />} />

          <Route
            exact
            path="/register/success"
            name="SKASI"
            render={props => <RegisterSuccess isAuthenticated={this.props.isAuthenticated} {...props} />} />

          <PrivateRoute
            path="/"
            name="Home"
            render={props => <DefaultLayout  {...props} />}
            isAuthenticated={this.props.isAuthenticated} />
        </Switch>
      </React.Suspense>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default withRouter(connect(mapStateToProps)(App))
