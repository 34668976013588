import { combineReducers } from 'redux'
import {
  CONF_LIST_ERROR,
  CONF_LIST_LOADING,
  CONF_LIST_SUCCESS,

  CONF_ADD_ERROR,
  CONF_ADD_LOADING,
  CONF_ADD_SUCCESS,

  CONF_VIEW_ERROR,
  CONF_VIEW_LOADING,
  CONF_VIEW_SUCCESS,
  
  CONF_DELETE_ERROR,
  CONF_DELETE_LOADING,
  CONF_DELETE_SUCCESS,
} from 'views/Conf/constant'

const confListError = (state = false, action) => {
  switch (action.type) {
    case CONF_LIST_ERROR:
      return action.confListError
    default:
      return state
  }
}

const confListLoading = (state = false, action) => {
  switch (action.type) {
    case CONF_LIST_LOADING:
      return action.confListLoading
    default:
      return state
  }
}

const confListSuccess = (state = false, action) => {
  switch (action.type) {
    case CONF_LIST_SUCCESS:
      return action.confListSuccess
    default:
      return state
  }
}

const confs = (state = [], action) => {
  switch (action.type) {
    case CONF_LIST_SUCCESS:
      return action.confs
    default:
      return state
  }
}

const confAddError = (state = false, action) => {
  switch (action.type) {
    case CONF_ADD_ERROR:
      return action.confAddError
    default:
      return state
  }
}

const confAddLoading = (state = false, action) => {
  switch (action.type) {
    case CONF_ADD_LOADING:
      return action.confAddLoading
    default:
      return state
  }
}

const confAddSuccess = (state = false, action) => {
  switch (action.type) {
    case CONF_ADD_SUCCESS:
      return action.confAddSuccess
    default:
      return state
  }
}

const confAdded = (state = [], action) => {
  switch (action.type) {
    case CONF_ADD_SUCCESS:
      return action.conf
    default:
      return state
  }
}

const confViewError = (state = false, action) => {
  switch (action.type) {
    case CONF_VIEW_ERROR:
      return action.confViewError
    default:
      return state
  }
}

const confViewLoading = (state = false, action) => {
  switch (action.type) {
    case CONF_VIEW_LOADING:
      return action.confViewLoading
    default:
      return state
  }
}

const confViewSuccess = (state = false, action) => {
  switch (action.type) {
    case CONF_VIEW_SUCCESS:
      return action.confViewSuccess
    default:
      return state
  }
}

const conf = (state = [], action) => {
  switch (action.type) {
    case CONF_VIEW_SUCCESS:
      return action.conf
    default:
      return state
  }
}

const confDeleteError = (state = false, action) => {
  switch (action.type) {
    case CONF_DELETE_ERROR:
      return action.confDeleteError
    default:
      return state
  }
}

const confDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case CONF_DELETE_LOADING:
      return action.confDeleteLoading
    default:
      return state
  }
}

const confDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case CONF_DELETE_SUCCESS:
      return action.confDeleteSuccess
    default:
      return state
  }
}

const confDeleted = (state = [], action) => {
  switch (action.type) {
    case CONF_DELETE_SUCCESS:
      return action.conf
    default:
      return state
  }
}


const confReducer = combineReducers({
  confListError,
  confListLoading,
  confListSuccess,
  confs,

  confAddError,
  confAddLoading,
  confAddSuccess,
  confAdded,

  confViewError,
  confViewLoading,
  confViewSuccess,
  conf,

  confDeleteError,
  confDeleteLoading,
  confDeleteSuccess,
  confDeleted,
})

export default confReducer
