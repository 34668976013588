// URL for API
export const STUDENT_LIST_URL = 'students'

export const STUDENT_LIST_ERROR = 'STUDENT_LIST_ERROR'
export const STUDENT_LIST_LOADING = 'STUDENT_LIST_LOADING'
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS'

export const STUDENT_ADD_URL = 'student'
export const STUDENT_ADD_ERROR = 'STUDENT_ADD_ERROR'
export const STUDENT_ADD_LOADING = 'STUDENT_ADD_LOADING'
export const STUDENT_ADD_SUCCESS = 'STUDENT_ADD_SUCCESS'

export const STUDENT_VIEW_URL = 'student/view'
export const STUDENT_VIEW_ERROR = 'STUDENT_VIEW_ERROR'
export const STUDENT_VIEW_LOADING = 'STUDENT_VIEW_LOADING'
export const STUDENT_VIEW_SUCCESS = 'STUDENT_VIEW_SUCCESS'

export const STUDENT_DELETE_URL = 'student/delete'
export const STUDENT_DELETE_ERROR = 'STUDENT_DELETE_ERROR'
export const STUDENT_DELETE_LOADING = 'STUDENT_DELETE_LOADING'
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS'

export const STUDENT_BULK_URL = 'student/bulk'
export const STUDENT_BULK_ERROR = 'STUDENT_BULK_ERROR'
export const STUDENT_BULK_LOADING = 'STUDENT_BULK_LOADING'
export const STUDENT_BULK_SUCCESS = 'STUDENT_BULK_SUCCESS'

export const STUDENT_ACTIVATE_URL = 'student/activate'
export const STUDENT_ACTIVATE_ERROR = 'STUDENT_ACTIVATE_ERROR'
export const STUDENT_ACTIVATE_LOADING = 'STUDENT_ACTIVATE_LOADING'
export const STUDENT_ACTIVATE_SUCCESS = 'STUDENT_ACTIVATE_SUCCESS'

export const STUDENT_RESET_URL = 'student_reset'
export const STUDENT_RESET_ERROR = 'STUDENT_RESET_ERROR'
export const STUDENT_RESET_LOADING = 'STUDENT_RESET_LOADING'
export const STUDENT_RESET_SUCCESS = 'STUDENT_RESET_SUCCESS'

export const STUDENT_EMAIL_TOKEN_URL = 'student/email/token'
export const STUDENT_EMAIL_TOKEN_ERROR = 'STUDENT_EMAIL_TOKEN_ERROR'
export const STUDENT_EMAIL_TOKEN_LOADING = 'STUDENT_EMAIL_TOKEN_LOADING'
export const STUDENT_EMAIL_TOKEN_SUCCESS = 'STUDENT_EMAIL_TOKEN_SUCCESS'

export const STUDENT_EMAIL_ACTIVATE_URL = 'student/email/activate'
export const STUDENT_EMAIL_ACTIVATE_ERROR = 'STUDENT_EMAIL_ACTIVATE_ERROR'
export const STUDENT_EMAIL_ACTIVATE_LOADING = 'STUDENT_EMAIL_ACTIVATE_LOADING'
export const STUDENT_EMAIL_ACTIVATE_SUCCESS = 'STUDENT_EMAIL_ACTIVATE_SUCCESS'

export const STUDENT_PHONE_TOKEN_URL = 'student/phone/token'
export const STUDENT_PHONE_TOKEN_ERROR = 'STUDENT_PHONE_TOKEN_ERROR'
export const STUDENT_PHONE_TOKEN_LOADING = 'STUDENT_PHONE_TOKEN_LOADING'
export const STUDENT_PHONE_TOKEN_SUCCESS = 'STUDENT_PHONE_TOKEN_SUCCESS'

export const STUDENT_PHONE_ACTIVATE_URL = 'student/phone/activate'
export const STUDENT_PHONE_ACTIVATE_ERROR = 'STUDENT_PHONE_ACTIVATE_ERROR'
export const STUDENT_PHONE_ACTIVATE_LOADING = 'STUDENT_PHONE_ACTIVATE_LOADING'
export const STUDENT_PHONE_ACTIVATE_SUCCESS = 'STUDENT_PHONE_ACTIVATE_SUCCESS'

export const STUDENT_IMAGE_URL = 'student/image'
export const STUDENT_IMAGE_ERROR = 'STUDENT_IMAGE_ERROR'
export const STUDENT_IMAGE_LOADING = 'STUDENT_IMAGE_LOADING'
export const STUDENT_IMAGE_SUCCESS = 'STUDENT_IMAGE_SUCCESS'

// URL for UI
export const STUDENT_URL = {
    list: '/students',
    view: '/student',
    edit: '/student/edit',
    bulk: '/student/bulk',
    url_param: '/:studentId'
}