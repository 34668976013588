// URL for UI
export const YEAR_LIST_URL = 'years'

export const YEAR_LIST_ERROR = 'YEAR_LIST_ERROR'
export const YEAR_LIST_LOADING = 'YEAR_LIST_LOADING'
export const YEAR_LIST_SUCCESS = 'YEAR_LIST_SUCCESS'

export const YEAR_ADD_URL = 'year'
export const YEAR_ADD_ERROR = 'YEAR_ADD_ERROR'
export const YEAR_ADD_LOADING = 'YEAR_ADD_LOADING'
export const YEAR_ADD_SUCCESS = 'YEAR_ADD_SUCCESS'

export const YEAR_VIEW_URL = 'year'
export const YEAR_VIEW_ERROR = 'YEAR_VIEW_ERROR'
export const YEAR_VIEW_LOADING = 'YEAR_VIEW_LOADING'
export const YEAR_VIEW_SUCCESS = 'YEAR_VIEW_SUCCESS'

export const YEAR_DELETE_URL = 'year/delete'
export const YEAR_DELETE_ERROR = 'YEAR_DELETE_ERROR'
export const YEAR_DELETE_LOADING = 'YEAR_DELETE_LOADING'
export const YEAR_DELETE_SUCCESS = 'YEAR_DELETE_SUCCESS'

export const YEAR_ACTIVATE_URL = 'year/activate'
export const YEAR_ACTIVATE_ERROR = 'YEAR_ACTIVATE_ERROR'
export const YEAR_ACTIVATE_LOADING = 'YEAR_ACTIVATE_LOADING'
export const YEAR_ACTIVATE_SUCCESS = 'YEAR_ACTIVATE_SUCCESS'


// URL for UI
export const YEAR_URL = {
    list: '/years',
    view: '/year',
    edit: '/year/edit',
    activate: '/year/activate',
    url_param: '/:yearId'
}