import { combineReducers } from 'redux'
import {
  ADMISSION_COOP_LIST_ERROR,
  ADMISSION_COOP_LIST_LOADING,
  ADMISSION_COOP_LIST_SUCCESS,

  ADMISSION_COOP_ADD_ERROR,
  ADMISSION_COOP_ADD_LOADING,
  ADMISSION_COOP_ADD_SUCCESS,

  ADMISSION_COOP_DELETE_ERROR,
  ADMISSION_COOP_DELETE_LOADING,
  ADMISSION_COOP_DELETE_SUCCESS,
} from 'views/AdmissionCoop/constant'
const admissionCoopListError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_LIST_ERROR:
      return action.admissionCoopListError
    default:
      return state
  }
}

const admissionCoopListLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_LIST_LOADING:
      return action.admissionCoopListLoading
    default:
      return state
  }
}

const admissionCoopListSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_LIST_SUCCESS:
      return action.admissionCoopListSuccess
    default:
      return state
  }
}

const admissionCoops = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_COOP_LIST_SUCCESS:
      return action.admissions
    default:
      return state
  }
}

const admissionCoopAddError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_ADD_ERROR:
      return action.admissionCoopAddError
    default:
      return state
  }
}

const admissionCoopAddLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_ADD_LOADING:
      return action.admissionCoopAddLoading
    default:
      return state
  }
}

const admissionCoopAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_ADD_SUCCESS:
      return action.admissionCoopAddSuccess
    default:
      return state
  }
}

const admissionCoopAdded = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_COOP_ADD_SUCCESS:
      return action.admission
    default:
      return state
  }
}
const admissionCoopDeleteError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_DELETE_ERROR:
      return action.admissionCoopDeleteError
    default:
      return state
  }
}

const admissionCoopDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_DELETE_LOADING:
      return action.admissionCoopDeleteLoading
    default:
      return state
  }
}

const admissionCoopDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_COOP_DELETE_SUCCESS:
      return action.admissionCoopDeleteSuccess
    default:
      return state
  }
}

const admissionCoopDeleted = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_COOP_DELETE_SUCCESS:
      return action.admission
    default:
      return state
  }
}

const admissionReducer = combineReducers({
  admissionCoopListError,
  admissionCoopListLoading,
  admissionCoopListSuccess,
  admissionCoops,

  admissionCoopAddError,
  admissionCoopAddLoading,
  admissionCoopAddSuccess,
  admissionCoopAdded,

  admissionCoopDeleteError,
  admissionCoopDeleteLoading,
  admissionCoopDeleteSuccess,
  admissionCoopDeleted,
})

export default admissionReducer
