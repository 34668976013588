import { combineReducers } from 'redux'
import {
  PARENT_LIST_ERROR,
  PARENT_LIST_LOADING,
  PARENT_LIST_SUCCESS,

  PARENT_CHECK_ERROR,
  PARENT_CHECK_LOADING,
  PARENT_CHECK_SUCCESS,

  PARENT_ADD_ERROR,
  PARENT_ADD_LOADING,
  PARENT_ADD_SUCCESS,

  PARENT_VIEW_ERROR,
  PARENT_VIEW_LOADING,
  PARENT_VIEW_SUCCESS,

  PARENT_DELETE_ERROR,
  PARENT_DELETE_LOADING,
  PARENT_DELETE_SUCCESS,

  PARENT_RESET_ERROR,
  PARENT_RESET_LOADING,
  PARENT_RESET_SUCCESS,

  PARENT_REGISTER_ERROR,
  PARENT_REGISTER_LOADING,
  PARENT_REGISTER_SUCCESS,

  PARENT_ACTIVATE_PHONE_ERROR,
  PARENT_ACTIVATE_PHONE_LOADING,
  PARENT_ACTIVATE_PHONE_SUCCESS,

  PARENT_ACTIVATE_EMAIL_ERROR,
  PARENT_ACTIVATE_EMAIL_LOADING,
  PARENT_ACTIVATE_EMAIL_SUCCESS,

  PARENT_EDIT_PROFILE_ERROR,
  PARENT_EDIT_PROFILE_LOADING,
  PARENT_EDIT_PROFILE_SUCCESS,

  PARENT_EMAIL_TOKEN_ERROR,
  PARENT_EMAIL_TOKEN_LOADING,
  PARENT_EMAIL_TOKEN_SUCCESS,

  PARENT_EMAIL_ACTIVATE_ERROR,
  PARENT_EMAIL_ACTIVATE_LOADING,
  PARENT_EMAIL_ACTIVATE_SUCCESS,

  PARENT_PHONE_TOKEN_ERROR,
  PARENT_PHONE_TOKEN_LOADING,
  PARENT_PHONE_TOKEN_SUCCESS,

  PARENT_PHONE_ACTIVATE_ERROR,
  PARENT_PHONE_ACTIVATE_LOADING,
  PARENT_PHONE_ACTIVATE_SUCCESS,

  PARENT_IMAGE_ERROR,
  PARENT_IMAGE_LOADING,
  PARENT_IMAGE_SUCCESS,
} from './constant'

const parentListError = (state = false, action) => {
  switch (action.type) {
    case PARENT_LIST_ERROR:
      return action.parentListError
    default:
      return state
  }
}

const parentListLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_LIST_LOADING:
      return action.parentListLoading
    default:
      return state
  }
}

const parentListSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_LIST_SUCCESS:
      return action.parentListSuccess
    default:
      return state
  }
}

const parents = (state = [], action) => {
  switch (action.type) {
    case PARENT_LIST_SUCCESS:
      return action.parents
    default:
      return state
  }
}

const parentCheckError = (state = false, action) => {
  switch (action.type) {
    case PARENT_CHECK_ERROR:
      return action.parentCheckError
    default:
      return state
  }
}

const parentCheckLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_CHECK_LOADING:
      return action.parentCheckLoading
    default:
      return state
  }
}

const parentCheckSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_CHECK_SUCCESS:
      return action.parentCheckSuccess
    default:
      return state
  }
}

const parentCheck = (state = [], action) => {
  switch (action.type) {
    case PARENT_CHECK_SUCCESS:
      return action.parents
    default:
      return state
  }
}

const parentAddError = (state = false, action) => {
  switch (action.type) {
    case PARENT_ADD_ERROR:
      return action.parentAddError
    default:
      return state
  }
}

const parentAddLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_ADD_LOADING:
      return action.parentAddLoading
    default:
      return state
  }
}

const parentAddSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_ADD_SUCCESS:
      return action.parentAddSuccess
    default:
      return state
  }
}

const parentAdded = (state = [], action) => {
  switch (action.type) {
    case PARENT_ADD_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentViewError = (state = false, action) => {
  switch (action.type) {
    case PARENT_VIEW_ERROR:
      return action.parentViewError
    default:
      return state
  }
}

const parentViewLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_VIEW_LOADING:
      return action.parentViewLoading
    default:
      return state
  }
}

const parentViewSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_VIEW_SUCCESS:
      return action.parentViewSuccess
    default:
      return state
  }
}

const parent = (state = [], action) => {
  switch (action.type) {
    case PARENT_VIEW_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentDeleteError = (state = false, action) => {
  switch (action.type) {
    case PARENT_DELETE_ERROR:
      return action.parentDeleteError
    default:
      return state
  }
}

const parentDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_DELETE_LOADING:
      return action.parentDeleteLoading
    default:
      return state
  }
}

const parentDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_DELETE_SUCCESS:
      return action.parentDeleteSuccess
    default:
      return state
  }
}

const parentDeleted = (state = [], action) => {
  switch (action.type) {
    case PARENT_DELETE_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentResetError = (state = false, action) => {
  switch (action.type) {
    case PARENT_RESET_ERROR:
      return action.parentResetError
    default:
      return state
  }
}

const parentResetLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_RESET_LOADING:
      return action.parentResetLoading
    default:
      return state
  }
}

const parentResetSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_RESET_SUCCESS:
      return action.parentResetSuccess
    default:
      return state
  }
}

const parentRegisterError = (state = false, action) => {
  switch (action.type) {
    case PARENT_REGISTER_ERROR:
      return action.parentRegisterError
    default:
      return state
  }
}

const parentRegisterLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_REGISTER_LOADING:
      return action.parentRegisterLoading
    default:
      return state
  }
}

const parentRegisterSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_REGISTER_SUCCESS:
      return action.parentRegisterSuccess
    default:
      return state
  }
}

const parentRegistered = (state = [], action) => {
  switch (action.type) {
    case PARENT_REGISTER_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentActivatePhoneError = (state = false, action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_PHONE_ERROR:
      return action.parentActivatePhoneError
    default:
      return state
  }
}

const parentActivatePhoneLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_PHONE_LOADING:
      return action.parentActivatePhoneLoading
    default:
      return state
  }
}

const parentActivatePhoneSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_PHONE_SUCCESS:
      return action.parentActivatePhoneSuccess
    default:
      return state
  }
}

const parentActivatePhoned = (state = [], action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_PHONE_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentActivateEmailError = (state = false, action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_EMAIL_ERROR:
      return action.parentActivateEmailError
    default:
      return state
  }
}

const parentActivateEmailLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_EMAIL_LOADING:
      return action.parentActivateEmailLoading
    default:
      return state
  }
}

const parentActivateEmailSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_EMAIL_SUCCESS:
      return action.parentActivateEmailSuccess
    default:
      return state
  }
}

const parentActivateEmailed = (state = [], action) => {
  switch (action.type) {
    case PARENT_ACTIVATE_EMAIL_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentEditProfileError = (state = false, action) => {
  switch (action.type) {
    case PARENT_EDIT_PROFILE_ERROR:
      return action.parentEditProfileError
    default:
      return state
  }
}

const parentEditProfileLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_EDIT_PROFILE_LOADING:
      return action.parentEditProfileLoading
    default:
      return state
  }
}

const parentEditProfileSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_EDIT_PROFILE_SUCCESS:
      return action.parentEditProfileSuccess
    default:
      return state
  }
}

const parentEditProfiled = (state = [], action) => {
  switch (action.type) {
    case PARENT_EDIT_PROFILE_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentEmailTokenError = (state = false, action) => {
  switch (action.type) {
    case PARENT_EMAIL_TOKEN_ERROR:
      return action.parentEmailTokenError
    default:
      return state
  }
}

const parentEmailTokenLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_EMAIL_TOKEN_LOADING:
      return action.parentEmailTokenLoading
    default:
      return state
  }
}

const parentEmailTokenSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_EMAIL_TOKEN_SUCCESS:
      return action.parentEmailTokenSuccess
    default:
      return state
  }
}

const parentEmailActivateError = (state = false, action) => {
  switch (action.type) {
    case PARENT_EMAIL_ACTIVATE_ERROR:
      return action.parentEmailActivateError
    default:
      return state
  }
}

const parentEmailActivateLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_EMAIL_ACTIVATE_LOADING:
      return action.parentEmailActivateLoading
    default:
      return state
  }
}

const parentEmailActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_EMAIL_ACTIVATE_SUCCESS:
      return action.parentEmailActivateSuccess
    default:
      return state
  }
}

const parentEmailActivated = (state = [], action) => {
  switch (action.type) {
    case PARENT_EMAIL_ACTIVATE_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentPhoneTokenError = (state = false, action) => {
  switch (action.type) {
    case PARENT_PHONE_TOKEN_ERROR:
      return action.parentPhoneTokenError
    default:
      return state
  }
}

const parentPhoneTokenLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_PHONE_TOKEN_LOADING:
      return action.parentPhoneTokenLoading
    default:
      return state
  }
}

const parentPhoneTokenSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_PHONE_TOKEN_SUCCESS:
      return action.parentPhoneTokenSuccess
    default:
      return state
  }
}

const parentPhoneActivateError = (state = false, action) => {
  switch (action.type) {
    case PARENT_PHONE_ACTIVATE_ERROR:
      return action.parentPhoneActivateError
    default:
      return state
  }
}

const parentPhoneActivateLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_PHONE_ACTIVATE_LOADING:
      return action.parentPhoneActivateLoading
    default:
      return state
  }
}

const parentPhoneActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_PHONE_ACTIVATE_SUCCESS:
      return action.parentPhoneActivateSuccess
    default:
      return state
  }
}

const parentPhoneActivated = (state = [], action) => {
  switch (action.type) {
    case PARENT_PHONE_ACTIVATE_SUCCESS:
      return action.parent
    default:
      return state
  }
}

const parentImageError = (state = false, action) => {
  switch (action.type) {
    case PARENT_IMAGE_ERROR:
      return action.parentImageError
    default:
      return state
  }
}

const parentImageLoading = (state = false, action) => {
  switch (action.type) {
    case PARENT_IMAGE_LOADING:
      return action.parentImageLoading
    default:
      return state
  }
}

const parentImageSuccess = (state = false, action) => {
  switch (action.type) {
    case PARENT_IMAGE_SUCCESS:
      return action.parentImageSuccess
    default:
      return state
  }
}

const parentImage = (state = [], action) => {
  switch (action.type) {
    case PARENT_IMAGE_SUCCESS:
      return action.parentImage
    default:
      return state
  }
}

const parentReducer = combineReducers({
  parentListError,
  parentListLoading,
  parentListSuccess,
  parents,

  parentCheckError,
  parentCheckLoading,
  parentCheckSuccess,
  parentCheck,

  parentAddError,
  parentAddLoading,
  parentAddSuccess,
  parentAdded,

  parentViewError,
  parentViewLoading,
  parentViewSuccess,
  parent,

  parentDeleteError,
  parentDeleteLoading,
  parentDeleteSuccess,
  parentDeleted,

  parentResetError,
  parentResetLoading,
  parentResetSuccess,

  parentRegisterError,
  parentRegisterLoading,
  parentRegisterSuccess,
  parentRegistered,

  parentActivatePhoneError,
  parentActivatePhoneLoading,
  parentActivatePhoneSuccess,
  parentActivatePhoned,

  parentActivateEmailError,
  parentActivateEmailLoading,
  parentActivateEmailSuccess,
  parentActivateEmailed,

  parentEditProfileError,
  parentEditProfileLoading,
  parentEditProfileSuccess,
  parentEditProfiled,

  parentEmailTokenError,
  parentEmailTokenLoading,
  parentEmailTokenSuccess,

  parentEmailActivateError,
  parentEmailActivateLoading,
  parentEmailActivateSuccess,
  parentEmailActivated,

  parentPhoneTokenError,
  parentPhoneTokenLoading,
  parentPhoneTokenSuccess,

  parentPhoneActivateError,
  parentPhoneActivateLoading,
  parentPhoneActivateSuccess,
  parentPhoneActivated,

  parentImage,
  parentImageError,
  parentImageLoading,
  parentImageSuccess,
})

export default parentReducer
