import { combineReducers } from 'redux'
import {
  YEAR_LIST_ERROR,
  YEAR_LIST_LOADING,
  YEAR_LIST_SUCCESS,

  YEAR_ADD_ERROR,
  YEAR_ADD_LOADING,
  YEAR_ADD_SUCCESS,

  YEAR_ACTIVATE_ERROR,
  YEAR_ACTIVATE_LOADING,
  YEAR_ACTIVATE_SUCCESS,

  YEAR_VIEW_ERROR,
  YEAR_VIEW_LOADING,
  YEAR_VIEW_SUCCESS,
  
  YEAR_DELETE_ERROR,
  YEAR_DELETE_LOADING,
  YEAR_DELETE_SUCCESS,
} from 'views/Year/constant'

const yearListError = (state = false, action) => {
  switch (action.type) {
    case YEAR_LIST_ERROR:
      return action.yearListError
    default:
      return state
  }
}

const yearListLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_LIST_LOADING:
      return action.yearListLoading
    default:
      return state
  }
}

const yearListSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_LIST_SUCCESS:
      return action.yearListSuccess
    default:
      return state
  }
}

const years = (state = [], action) => {
  switch (action.type) {
    case YEAR_LIST_SUCCESS:
      return action.years
    default:
      return state
  }
}

const yearAddError = (state = false, action) => {
  switch (action.type) {
    case YEAR_ADD_ERROR:
      return action.yearAddError
    default:
      return state
  }
}

const yearAddLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_ADD_LOADING:
      return action.yearAddLoading
    default:
      return state
  }
}

const yearAddSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_ADD_SUCCESS:
      return action.yearAddSuccess
    default:
      return state
  }
}

const yearAdded = (state = [], action) => {
  switch (action.type) {
    case YEAR_ADD_SUCCESS:
      return action.year
    default:
      return state
  }
}

const yearViewError = (state = false, action) => {
  switch (action.type) {
    case YEAR_VIEW_ERROR:
      return action.yearViewError
    default:
      return state
  }
}

const yearViewLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_VIEW_LOADING:
      return action.yearViewLoading
    default:
      return state
  }
}

const yearViewSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_VIEW_SUCCESS:
      return action.yearViewSuccess
    default:
      return state
  }
}

const year = (state = [], action) => {
  switch (action.type) {
    case YEAR_VIEW_SUCCESS:
      return action.year
    default:
      return state
  }
}

const yearDeleteError = (state = false, action) => {
  switch (action.type) {
    case YEAR_DELETE_ERROR:
      return action.yearDeleteError
    default:
      return state
  }
}

const yearDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_DELETE_LOADING:
      return action.yearDeleteLoading
    default:
      return state
  }
}

const yearDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_DELETE_SUCCESS:
      return action.yearDeleteSuccess
    default:
      return state
  }
}

const yearDeleted = (state = [], action) => {
  switch (action.type) {
    case YEAR_DELETE_SUCCESS:
      return action.year
    default:
      return state
  }
}

const yearActivateError = (state = false, action) => {
  switch (action.type) {
    case YEAR_ACTIVATE_ERROR:
      return action.yearActivateError
    default:
      return state
  }
}

const yearActivateLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_ACTIVATE_LOADING:
      return action.yearActivateLoading
    default:
      return state
  }
}

const yearActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_ACTIVATE_SUCCESS:
      return action.yearActivateSuccess
    default:
      return state
  }
}

const yearActivated = (state = [], action) => {
  switch (action.type) {
    case YEAR_ACTIVATE_SUCCESS:
      return action.year
    default:
      return state
  }
}


const yearReducer = combineReducers({
  yearListError,
  yearListLoading,
  yearListSuccess,
  years,

  yearAddError,
  yearAddLoading,
  yearAddSuccess,
  yearAdded,

  yearViewError,
  yearViewLoading,
  yearViewSuccess,
  year,

  yearDeleteError,
  yearDeleteLoading,
  yearDeleteSuccess,
  yearDeleted,

  yearActivateError,
  yearActivateLoading,
  yearActivateSuccess,
  yearActivated,
})

export default yearReducer
