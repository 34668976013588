// URL for UI
export const ACTIVITY_LIST_URL = 'activities'

export const ACTIVITY_LIST_ERROR = 'ACTIVITY_LIST_ERROR'
export const ACTIVITY_LIST_LOADING = 'ACTIVITY_LIST_LOADING'
export const ACTIVITY_LIST_SUCCESS = 'ACTIVITY_LIST_SUCCESS'

export const ACTIVITY_VIEW_URL = 'activity'
export const ACTIVITY_VIEW_ERROR = 'ACTIVITY_VIEW_ERROR'
export const ACTIVITY_VIEW_LOADING = 'ACTIVITY_VIEW_LOADING'
export const ACTIVITY_VIEW_SUCCESS = 'ACTIVITY_VIEW_SUCCESS'

// URL for UI
export const ACTIVITY_URL = {
    list: '/activities',
    view: '/activity',
    url_param: '/:activityId'
}