import { combineReducers } from 'redux'
import {
  STAFF_LIST_ERROR,
  STAFF_LIST_LOADING,
  STAFF_LIST_SUCCESS,

  STAFF_ADD_ERROR,
  STAFF_ADD_LOADING,
  STAFF_ADD_SUCCESS,

  STAFF_RESET_ERROR,
  STAFF_RESET_LOADING,
  STAFF_RESET_SUCCESS,

  STAFF_VIEW_ERROR,
  STAFF_VIEW_LOADING,
  STAFF_VIEW_SUCCESS,
  
  STAFF_DELETE_ERROR,
  STAFF_DELETE_LOADING,
  STAFF_DELETE_SUCCESS,

  STAFF_ACTIVATE_ERROR,
  STAFF_ACTIVATE_LOADING,
  STAFF_ACTIVATE_SUCCESS,

  STAFF_IMAGE_ERROR,
  STAFF_IMAGE_LOADING,
  STAFF_IMAGE_SUCCESS,
} from './constant'

const staffListError = (state = false, action) => {
  switch (action.type) {
    case STAFF_LIST_ERROR:
      return action.staffListError
    default:
      return state
  }
}

const staffListLoading = (state = false, action) => {
  switch (action.type) {
    case STAFF_LIST_LOADING:
      return action.staffListLoading
    default:
      return state
  }
}

const staffListSuccess = (state = false, action) => {
  switch (action.type) {
    case STAFF_LIST_SUCCESS:
      return action.staffListSuccess
    default:
      return state
  }
}

const staffs = (state = [], action) => {
  switch (action.type) {
    case STAFF_LIST_SUCCESS:
      return action.staffs
    default:
      return state
  }
}

const staffAddError = (state = false, action) => {
  switch (action.type) {
    case STAFF_ADD_ERROR:
      return action.staffAddError
    default:
      return state
  }
}

const staffAddLoading = (state = false, action) => {
  switch (action.type) {
    case STAFF_ADD_LOADING:
      return action.staffAddLoading
    default:
      return state
  }
}

const staffAddSuccess = (state = false, action) => {
  switch (action.type) {
    case STAFF_ADD_SUCCESS:
      return action.staffAddSuccess
    default:
      return state
  }
}

const staffAdded = (state = [], action) => {
  switch (action.type) {
    case STAFF_ADD_SUCCESS:
      return action.staff
    default:
      return state
  }
}

const staffResetError = (state = false, action) => {
  switch (action.type) {
    case STAFF_RESET_ERROR:
      return action.staffResetError
    default:
      return state
  }
}

const staffResetLoading = (state = false, action) => {
  switch (action.type) {
    case STAFF_RESET_LOADING:
      return action.staffResetLoading
    default:
      return state
  }
}

const staffResetSuccess = (state = false, action) => {
  switch (action.type) {
    case STAFF_RESET_SUCCESS:
      return action.staffResetSuccess
    default:
      return state
  }
}

const staffReseted = (state = [], action) => {
  switch (action.type) {
    case STAFF_RESET_SUCCESS:
      return action.staff
    default:
      return state
  }
}

const staffViewError = (state = false, action) => {
  switch (action.type) {
    case STAFF_VIEW_ERROR:
      return action.staffViewError
    default:
      return state
  }
}

const staffViewLoading = (state = false, action) => {
  switch (action.type) {
    case STAFF_VIEW_LOADING:
      return action.staffViewLoading
    default:
      return state
  }
}

const staffViewSuccess = (state = false, action) => {
  switch (action.type) {
    case STAFF_VIEW_SUCCESS:
      return action.staffViewSuccess
    default:
      return state
  }
}

const staff = (state = [], action) => {
  switch (action.type) {
    case STAFF_VIEW_SUCCESS:
      return action.staff
    default:
      return state
  }
}

const staffDeleteError = (state = false, action) => {
  switch (action.type) {
    case STAFF_DELETE_ERROR:
      return action.staffDeleteError
    default:
      return state
  }
}

const staffDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case STAFF_DELETE_LOADING:
      return action.staffDeleteLoading
    default:
      return state
  }
}

const staffDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case STAFF_DELETE_SUCCESS:
      return action.staffDeleteSuccess
    default:
      return state
  }
}

const staffDeleted = (state = [], action) => {
  switch (action.type) {
    case STAFF_DELETE_SUCCESS:
      return action.staff
    default:
      return state
  }
}

const staffActivateError = (state = false, action) => {
  switch (action.type) {
    case STAFF_ACTIVATE_ERROR:
      return action.staffActivateError
    default:
      return state
  }
}

const staffActivateLoading = (state = false, action) => {
  switch (action.type) {
    case STAFF_ACTIVATE_LOADING:
      return action.staffActivateLoading
    default:
      return state
  }
}

const staffActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case STAFF_ACTIVATE_SUCCESS:
      return action.staffActivateSuccess
    default:
      return state
  }
}

const staffActivated = (state = [], action) => {
  switch (action.type) {
    case STAFF_ACTIVATE_SUCCESS:
      return action.staff
    default:
      return state
  }
}

const staffImageError = (state = false, action) => {
  switch (action.type) {
    case STAFF_IMAGE_ERROR:
      return action.staffImageError
    default:
      return state
  }
}

const staffImageLoading = (state = false, action) => {
  switch (action.type) {
    case STAFF_IMAGE_LOADING:
      return action.staffImageLoading
    default:
      return state
  }
}

const staffImageSuccess = (state = false, action) => {
  switch (action.type) {
    case STAFF_IMAGE_SUCCESS:
      return action.staffImageSuccess
    default:
      return state
  }
}

const staffImage = (state = [], action) => {
  switch (action.type) {
    case STAFF_IMAGE_SUCCESS:
      return action.staffImage
    default:
      return state
  }
}


const staffReducer = combineReducers({
  staffListError,
  staffListLoading,
  staffListSuccess,
  staffs,

  staffAddError,
  staffAddLoading,
  staffAddSuccess,
  staffAdded,

  staffResetError,
  staffResetLoading,
  staffResetSuccess,
  staffReseted,

  staffViewError,
  staffViewLoading,
  staffViewSuccess,
  staff,

  staffDeleteError,
  staffDeleteLoading,
  staffDeleteSuccess,
  staffDeleted,

  staffActivateError,
  staffActivateLoading,
  staffActivateSuccess,
  staffActivated,

  staffImage,
  staffImageError,
  staffImageLoading,
  staffImageSuccess,
})

export default staffReducer
