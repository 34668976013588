// URL for UI
export const ASSIGNMENT_LIST_URL = 'materials'

export const ASSIGNMENT_LIST_ERROR = 'ASSIGNMENT_LIST_ERROR'
export const ASSIGNMENT_LIST_LOADING = 'ASSIGNMENT_LIST_LOADING'
export const ASSIGNMENT_LIST_SUCCESS = 'ASSIGNMENT_LIST_SUCCESS'

export const ASSIGNMENT_ADD_URL = 'material'
export const ASSIGNMENT_ADD_ERROR = 'ASSIGNMENT_ADD_ERROR'
export const ASSIGNMENT_ADD_LOADING = 'ASSIGNMENT_ADD_LOADING'
export const ASSIGNMENT_ADD_SUCCESS = 'ASSIGNMENT_ADD_SUCCESS'

export const ASSIGNMENT_VIEW_URL = 'material/view'
export const ASSIGNMENT_VIEW_ERROR = 'ASSIGNMENT_VIEW_ERROR'
export const ASSIGNMENT_VIEW_LOADING = 'ASSIGNMENT_VIEW_LOADING'
export const ASSIGNMENT_VIEW_SUCCESS = 'ASSIGNMENT_VIEW_SUCCESS'

export const ASSIGNMENT_DELETE_URL = 'material/delete'
export const ASSIGNMENT_DELETE_ERROR = 'ASSIGNMENT_DELETE_ERROR'
export const ASSIGNMENT_DELETE_LOADING = 'ASSIGNMENT_DELETE_LOADING'
export const ASSIGNMENT_DELETE_SUCCESS = 'ASSIGNMENT_DELETE_SUCCESS'

// Material Attachment constant
export const ASSIGNMENT_ATTACH_ADD_URL = 'material/put'
export const ASSIGNMENT_ATTACH_ADD_ERROR = 'ASSIGNMENT_ATTACH_ADD_ERROR'
export const ASSIGNMENT_ATTACH_ADD_LOADING = 'ASSIGNMENT_ATTACH_ADD_LOADING'
export const ASSIGNMENT_ATTACH_ADD_SUCCESS = 'ASSIGNMENT_ATTACH_ADD_SUCCESS'

export const ASSIGNMENT_ATTACH_GET_URL = 'material/get'

// URL for UI
export const ASSIGNMENT_URL = {
    list: '/assignments',
    view: '/assignment',
    edit: '/assignment/edit',
    url_param: '/:assignmentId'
}