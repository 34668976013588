import { combineReducers } from 'redux'
import {
  ACTIVITY_LIST_ERROR,
  ACTIVITY_LIST_LOADING,
  ACTIVITY_LIST_SUCCESS,

  ACTIVITY_VIEW_ERROR,
  ACTIVITY_VIEW_LOADING,
  ACTIVITY_VIEW_SUCCESS,
} from 'views/Activity/constant'

const activityListError = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_LIST_ERROR:
      return action.activityListError
    default:
      return state
  }
}

const activityListLoading = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_LIST_LOADING:
      return action.activityListLoading
    default:
      return state
  }
}

const activityListSuccess = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_LIST_SUCCESS:
      return action.activityListSuccess
    default:
      return state
  }
}

const activities = (state = [], action) => {
  switch (action.type) {
    case ACTIVITY_LIST_SUCCESS:
      return action.activities
    default:
      return state
  }
}

const activityViewError = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_VIEW_ERROR:
      return action.activityViewError
    default:
      return state
  }
}

const activityViewLoading = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_VIEW_LOADING:
      return action.activityViewLoading
    default:
      return state
  }
}

const activityViewSuccess = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_VIEW_SUCCESS:
      return action.activityViewSuccess
    default:
      return state
  }
}

const activity = (state = [], action) => {
  switch (action.type) {
    case ACTIVITY_VIEW_SUCCESS:
      return action.activity
    default:
      return state
  }
}

const activityReducer = combineReducers({
  activityListError,
  activityListLoading,
  activityListSuccess,
  activities,

  activityViewError,
  activityViewLoading,
  activityViewSuccess,
  activity,
})

export default activityReducer
