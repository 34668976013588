import { combineReducers } from 'redux'
import {
  ASSIGNMENT_LIST_ERROR,
  ASSIGNMENT_LIST_LOADING,
  ASSIGNMENT_LIST_SUCCESS,

  ASSIGNMENT_ADD_ERROR,
  ASSIGNMENT_ADD_LOADING,
  ASSIGNMENT_ADD_SUCCESS,

  ASSIGNMENT_ATTACH_ADD_ERROR,
  ASSIGNMENT_ATTACH_ADD_LOADING,
  ASSIGNMENT_ATTACH_ADD_SUCCESS,

  ASSIGNMENT_VIEW_ERROR,
  ASSIGNMENT_VIEW_LOADING,
  ASSIGNMENT_VIEW_SUCCESS,

  ASSIGNMENT_DELETE_ERROR,
  ASSIGNMENT_DELETE_LOADING,
  ASSIGNMENT_DELETE_SUCCESS,
} from 'views/Assignment/constant'

const assignmentListError = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_LIST_ERROR:
      return action.assignmentListError
    default:
      return state
  }
}

const assignmentListLoading = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_LIST_LOADING:
      return action.assignmentListLoading
    default:
      return state
  }
}

const assignmentListSuccess = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_LIST_SUCCESS:
      return action.assignmentListSuccess
    default:
      return state
  }
}

const assignments = (state = [], action) => {
  switch (action.type) {
    case ASSIGNMENT_LIST_SUCCESS:
      return action.assignments
    default:
      return state
  }
}

const assignmentAddError = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_ADD_ERROR:
      return action.assignmentAddError
    default:
      return state
  }
}

const assignmentAddLoading = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_ADD_LOADING:
      return action.assignmentAddLoading
    default:
      return state
  }
}

const assignmentAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_ADD_SUCCESS:
      return action.assignmentAddSuccess
    default:
      return state
  }
}

const assignmentAdded = (state = [], action) => {
  switch (action.type) {
    case ASSIGNMENT_ADD_SUCCESS:
      return action.assignment
    default:
      return state
  }
}

const assignmentAttachAddError = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_ATTACH_ADD_ERROR:
      return action.assignmentAttachAddError
    default:
      return state
  }
}

const assignmentAttachAddLoading = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_ATTACH_ADD_LOADING:
      return action.assignmentAttachAddLoading
    default:
      return state
  }
}

const assignmentAttachAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_ATTACH_ADD_SUCCESS:
      return action.assignmentAttachAddSuccess
    default:
      return state
  }
}

const assignmentAttachAdded = (state = [], action) => {
  switch (action.type) {
    case ASSIGNMENT_ATTACH_ADD_SUCCESS:
      return action.assignmentAdded
    default:
      return state
  }
}

const assignmentViewError = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_VIEW_ERROR:
      return action.assignmentViewError
    default:
      return state
  }
}

const assignmentViewLoading = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_VIEW_LOADING:
      return action.assignmentViewLoading
    default:
      return state
  }
}

const assignmentViewSuccess = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_VIEW_SUCCESS:
      return action.assignmentViewSuccess
    default:
      return state
  }
}

const assignment = (state = [], action) => {
  switch (action.type) {
    case ASSIGNMENT_VIEW_SUCCESS:
      return action.assignment
    default:
      return state
  }
}

const assignmentDeleteError = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_DELETE_ERROR:
      return action.assignmentDeleteError
    default:
      return state
  }
}

const assignmentDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_DELETE_LOADING:
      return action.assignmentDeleteLoading
    default:
      return state
  }
}

const assignmentDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case ASSIGNMENT_DELETE_SUCCESS:
      return action.assignmentDeleteSuccess
    default:
      return state
  }
}

const assignmentDeleted = (state = [], action) => {
  switch (action.type) {
    case ASSIGNMENT_DELETE_SUCCESS:
      return action.assignment
    default:
      return state
  }
}


const assignmentReducer = combineReducers({
  assignmentListError,
  assignmentListLoading,
  assignmentListSuccess,
  assignments,

  assignmentAddError,
  assignmentAddLoading,
  assignmentAddSuccess,
  assignmentAdded,

  assignmentAttachAddError,
  assignmentAttachAddLoading,
  assignmentAttachAddSuccess,
  assignmentAttachAdded,

  assignmentViewError,
  assignmentViewLoading,
  assignmentViewSuccess,
  assignment,

  assignmentDeleteError,
  assignmentDeleteLoading,
  assignmentDeleteSuccess,
  assignmentDeleted,
})

export default assignmentReducer
