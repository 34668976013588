import { combineReducers } from 'redux'
import {
  MESSAGE_LIST_ERROR,
  MESSAGE_LIST_LOADING,
  MESSAGE_LIST_SUCCESS,

  MESSAGE_ADD_ERROR,
  MESSAGE_ADD_LOADING,
  MESSAGE_ADD_SUCCESS,

  MESSAGE_VIEW_ERROR,
  MESSAGE_VIEW_LOADING,
  MESSAGE_VIEW_SUCCESS,
  
  MESSAGE_DELETE_ERROR,
  MESSAGE_DELETE_LOADING,
  MESSAGE_DELETE_SUCCESS,

  MESSAGE_IMAGE_ADD_ERROR,
  MESSAGE_IMAGE_ADD_LOADING,
  MESSAGE_IMAGE_ADD_SUCCESS,
} from 'views/Message/constant'

const messageListError = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_LIST_ERROR:
      return action.messageListError
    default:
      return state
  }
}

const messageListLoading = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_LIST_LOADING:
      return action.messageListLoading
    default:
      return state
  }
}

const messageListSuccess = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_LIST_SUCCESS:
      return action.messageListSuccess
    default:
      return state
  }
}

const messages = (state = [], action) => {
  switch (action.type) {
    case MESSAGE_LIST_SUCCESS:
      return action.messages
    default:
      return state
  }
}

const messageAddError = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_ADD_ERROR:
      return action.messageAddError
    default:
      return state
  }
}

const messageAddLoading = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_ADD_LOADING:
      return action.messageAddLoading
    default:
      return state
  }
}

const messageAddSuccess = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_ADD_SUCCESS:
      return action.messageAddSuccess
    default:
      return state
  }
}

const messageAdded = (state = [], action) => {
  switch (action.type) {
    case MESSAGE_ADD_SUCCESS:
      return action.message
    default:
      return state
  }
}

const messageViewError = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_VIEW_ERROR:
      return action.messageViewError
    default:
      return state
  }
}

const messageViewLoading = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_VIEW_LOADING:
      return action.messageViewLoading
    default:
      return state
  }
}

const messageViewSuccess = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_VIEW_SUCCESS:
      return action.messageViewSuccess
    default:
      return state
  }
}

const message = (state = [], action) => {
  switch (action.type) {
    case MESSAGE_VIEW_SUCCESS:
      return action.message
    default:
      return state
  }
}

const messageDeleteError = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_DELETE_ERROR:
      return action.messageDeleteError
    default:
      return state
  }
}

const messageDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_DELETE_LOADING:
      return action.messageDeleteLoading
    default:
      return state
  }
}

const messageDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_DELETE_SUCCESS:
      return action.messageDeleteSuccess
    default:
      return state
  }
}

const messageDeleted = (state = [], action) => {
  switch (action.type) {
    case MESSAGE_DELETE_SUCCESS:
      return action.message
    default:
      return state
  }
}

const messageImageAddError = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_IMAGE_ADD_ERROR:
      return action.messageImageAddError
    default:
      return state
  }
}

const messageImageAddLoading = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_IMAGE_ADD_LOADING:
      return action.messageImageAddLoading
    default:
      return state
  }
}

const messageImageAddSuccess = (state = false, action) => {
  switch (action.type) {
    case MESSAGE_IMAGE_ADD_SUCCESS:
      return action.messageImageAddSuccess
    default:
      return state
  }
}

const messageImageAdded = (state = [], action) => {
  switch (action.type) {
    case MESSAGE_IMAGE_ADD_SUCCESS:
      return action.materialAdded
    default:
      return state
  }
}

const messageReducer = combineReducers({
  messageListError,
  messageListLoading,
  messageListSuccess,
  messages,

  messageAddError,
  messageAddLoading,
  messageAddSuccess,
  messageAdded,

  messageViewError,
  messageViewLoading,
  messageViewSuccess,
  message,

  messageDeleteError,
  messageDeleteLoading,
  messageDeleteSuccess,
  messageDeleted,

  messageImageAddError,
  messageImageAddLoading,
  messageImageAddSuccess,
  messageImageAdded,
})

export default messageReducer
