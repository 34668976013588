import { combineReducers } from 'redux'
import {
  TEACHER_LIST_ERROR,
  TEACHER_LIST_LOADING,
  TEACHER_LIST_SUCCESS,

  TEACHER_ADD_ERROR,
  TEACHER_ADD_LOADING,
  TEACHER_ADD_SUCCESS,

  TEACHER_VIEW_ERROR,
  TEACHER_VIEW_LOADING,
  TEACHER_VIEW_SUCCESS,
  
  TEACHER_DELETE_ERROR,
  TEACHER_DELETE_LOADING,
  TEACHER_DELETE_SUCCESS,

  TEACHER_ACTIVATE_ERROR,
  TEACHER_ACTIVATE_LOADING,
  TEACHER_ACTIVATE_SUCCESS,

  TEACHER_EMAIL_TOKEN_ERROR,
  TEACHER_EMAIL_TOKEN_LOADING,
  TEACHER_EMAIL_TOKEN_SUCCESS,

  TEACHER_EMAIL_ACTIVATE_ERROR,
  TEACHER_EMAIL_ACTIVATE_LOADING,
  TEACHER_EMAIL_ACTIVATE_SUCCESS,

  TEACHER_PHONE_TOKEN_ERROR,
  TEACHER_PHONE_TOKEN_LOADING,
  TEACHER_PHONE_TOKEN_SUCCESS,

  TEACHER_PHONE_ACTIVATE_ERROR,
  TEACHER_PHONE_ACTIVATE_LOADING,
  TEACHER_PHONE_ACTIVATE_SUCCESS,

  TEACHER_RESET_ERROR,
  TEACHER_RESET_LOADING,
  TEACHER_RESET_SUCCESS,

  TEACHER_IMAGE_ERROR,
  TEACHER_IMAGE_LOADING,
  TEACHER_IMAGE_SUCCESS,

  TEACHER_BULK_ERROR,
  TEACHER_BULK_LOADING,
  TEACHER_BULK_SUCCESS,
} from 'views/Teacher/constant'

const teacherListError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_LIST_ERROR:
      return action.teacherListError
    default:
      return state
  }
}

const teacherListLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_LIST_LOADING:
      return action.teacherListLoading
    default:
      return state
  }
}

const teacherListSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_LIST_SUCCESS:
      return action.teacherListSuccess
    default:
      return state
  }
}

const teachers = (state = [], action) => {
  switch (action.type) {
    case TEACHER_LIST_SUCCESS:
      return action.teachers
    default:
      return state
  }
}

const teacherAddError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_ADD_ERROR:
      return action.teacherAddError
    default:
      return state
  }
}

const teacherAddLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_ADD_LOADING:
      return action.teacherAddLoading
    default:
      return state
  }
}

const teacherAddSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_ADD_SUCCESS:
      return action.teacherAddSuccess
    default:
      return state
  }
}

const teacherAdded = (state = [], action) => {
  switch (action.type) {
    case TEACHER_ADD_SUCCESS:
      return action.teacher
    default:
      return state
  }
}

const teacherViewError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_VIEW_ERROR:
      return action.teacherViewError
    default:
      return state
  }
}

const teacherViewLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_VIEW_LOADING:
      return action.teacherViewLoading
    default:
      return state
  }
}

const teacherViewSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_VIEW_SUCCESS:
      return action.teacherViewSuccess
    default:
      return state
  }
}

const teacher = (state = [], action) => {
  switch (action.type) {
    case TEACHER_VIEW_SUCCESS:
      return action.teacher
    default:
      return state
  }
}

const teacherDeleteError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_DELETE_ERROR:
      return action.teacherDeleteError
    default:
      return state
  }
}

const teacherDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_DELETE_LOADING:
      return action.teacherDeleteLoading
    default:
      return state
  }
}

const teacherDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_DELETE_SUCCESS:
      return action.teacherDeleteSuccess
    default:
      return state
  }
}

const teacherDeleted = (state = [], action) => {
  switch (action.type) {
    case TEACHER_DELETE_SUCCESS:
      return action.teacher
    default:
      return state
  }
}

const teacherActivateError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_ACTIVATE_ERROR:
      return action.teacherActivateError
    default:
      return state
  }
}

const teacherActivateLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_ACTIVATE_LOADING:
      return action.teacherActivateLoading
    default:
      return state
  }
}

const teacherActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_ACTIVATE_SUCCESS:
      return action.teacherActivateSuccess
    default:
      return state
  }
}

const teacherActivated = (state = [], action) => {
  switch (action.type) {
    case TEACHER_ACTIVATE_SUCCESS:
      return action.teacher
    default:
      return state
  }
}

const teacherEmailTokenError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_EMAIL_TOKEN_ERROR:
      return action.teacherEmailTokenError
    default:
      return state
  }
}

const teacherEmailTokenLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_EMAIL_TOKEN_LOADING:
      return action.teacherEmailTokenLoading
    default:
      return state
  }
}

const teacherEmailTokenSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_EMAIL_TOKEN_SUCCESS:
      return action.teacherEmailTokenSuccess
    default:
      return state
  }
}

const teacherEmailActivateError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_EMAIL_ACTIVATE_ERROR:
      return action.teacherEmailActivateError
    default:
      return state
  }
}

const teacherEmailActivateLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_EMAIL_ACTIVATE_LOADING:
      return action.teacherEmailActivateLoading
    default:
      return state
  }
}

const teacherEmailActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_EMAIL_ACTIVATE_SUCCESS:
      return action.teacherEmailActivateSuccess
    default:
      return state
  }
}

const teacherEmailActivated = (state = [], action) => {
  switch (action.type) {
    case TEACHER_EMAIL_ACTIVATE_SUCCESS:
      return action.teacher
    default:
      return state
  }
}

const teacherPhoneTokenError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_PHONE_TOKEN_ERROR:
      return action.teacherPhoneTokenError
    default:
      return state
  }
}

const teacherPhoneTokenLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_PHONE_TOKEN_LOADING:
      return action.teacherPhoneTokenLoading
    default:
      return state
  }
}

const teacherPhoneTokenSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_PHONE_TOKEN_SUCCESS:
      return action.teacherPhoneTokenSuccess
    default:
      return state
  }
}

const teacherPhoneActivateError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_PHONE_ACTIVATE_ERROR:
      return action.teacherPhoneActivateError
    default:
      return state
  }
}

const teacherPhoneActivateLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_PHONE_ACTIVATE_LOADING:
      return action.teacherPhoneActivateLoading
    default:
      return state
  }
}

const teacherPhoneActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_PHONE_ACTIVATE_SUCCESS:
      return action.teacherPhoneActivateSuccess
    default:
      return state
  }
}

const teacherPhoneActivated = (state = [], action) => {
  switch (action.type) {
    case TEACHER_PHONE_ACTIVATE_SUCCESS:
      return action.teacher
    default:
      return state
  }
}

const teacherResetError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_RESET_ERROR:
      return action.teacherResetError
    default:
      return state
  }
}

const teacherResetLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_RESET_LOADING:
      return action.teacherResetLoading
    default:
      return state
  }
}

const teacherResetSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_RESET_SUCCESS:
      return action.teacherResetSuccess
    default:
      return state
  }
}

const teacherImageError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_IMAGE_ERROR:
      return action.teacherImageError
    default:
      return state
  }
}

const teacherImageLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_IMAGE_LOADING:
      return action.teacherImageLoading
    default:
      return state
  }
}

const teacherImageSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_IMAGE_SUCCESS:
      return action.teacherImageSuccess
    default:
      return state
  }
}

const teacherImage = (state = [], action) => {
  switch (action.type) {
    case TEACHER_IMAGE_SUCCESS:
      return action.teacherImage
    default:
      return state
  }
}

const teacherBulkError = (state = false, action) => {
  switch (action.type) {
    case TEACHER_BULK_ERROR:
      return action.teacherBulkError
    default:
      return state
  }
}

const teacherBulkLoading = (state = false, action) => {
  switch (action.type) {
    case TEACHER_BULK_LOADING:
      return action.teacherBulkLoading
    default:
      return state
  }
}

const teacherBulkSuccess = (state = false, action) => {
  switch (action.type) {
    case TEACHER_BULK_SUCCESS:
      return action.teacherBulkSuccess
    default:
      return state
  }
}

const teacherBulked = (state = [], action) => {
  switch (action.type) {
    case TEACHER_BULK_SUCCESS:
      return action.teacher
    default:
      return state
  }
}

const teacherReducer = combineReducers({
  teacherListError,
  teacherListLoading,
  teacherListSuccess,
  teachers,

  teacherAddError,
  teacherAddLoading,
  teacherAddSuccess,
  teacherAdded,

  teacherViewError,
  teacherViewLoading,
  teacherViewSuccess,
  teacher,

  teacherDeleteError,
  teacherDeleteLoading,
  teacherDeleteSuccess,
  teacherDeleted,

  teacherActivateError,
  teacherActivateLoading,
  teacherActivateSuccess,
  teacherActivated,

  teacherEmailTokenError,
  teacherEmailTokenLoading,
  teacherEmailTokenSuccess,

  teacherEmailActivateError,
  teacherEmailActivateLoading,
  teacherEmailActivateSuccess,
  teacherEmailActivated,

  teacherPhoneTokenError,
  teacherPhoneTokenLoading,
  teacherPhoneTokenSuccess,

  teacherPhoneActivateError,
  teacherPhoneActivateLoading,
  teacherPhoneActivateSuccess,
  teacherPhoneActivated,

  teacherResetError,
  teacherResetLoading,
  teacherResetSuccess,

  teacherImage,
  teacherImageError,
  teacherImageLoading,
  teacherImageSuccess,

  teacherBulkError,
  teacherBulkLoading,
  teacherBulkSuccess,
  teacherBulked,
})

export default teacherReducer
