import { combineReducers } from 'redux'
import { TOKEN_KEY } from 'constant'
import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_LOADING,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_LOADING
} from './constant'

const authLoginError = (state = false, action) => {
  switch (action.type) {
    case AUTH_LOGIN_ERROR:
      return action.authLoginError
    default:
      return state
  }
}

const authLoginLoading = (state = false, action) => {
  switch (action.type) {
    case AUTH_LOGIN_LOADING:
      return action.authLoginLoading
    default:
      return state
  }
}

const authLoginSuccess = (state = false, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return action.authLoginSuccess
    default:
      return state
  }
}

const account = (state = [], action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return action.account
    default:
      return state
  }
}

const isAuthenticated = (state = false, action) => {
  state = localStorage.getItem(TOKEN_KEY) ? true : false

  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case AUTH_LOGOUT_SUCCESS:
      return action.isAuthenticated
    default:
      return state
  }
}

const authLogoutLoading = (state = false, action) => {
  switch (action.type) {
    case AUTH_LOGOUT_LOADING:
      return action.authLogoutLoading
    default:
      return state
  }
}

const authLogoutSuccess = (state = false, action) => {
  switch (action.type) {
    case AUTH_LOGOUT_SUCCESS:
      return action.authLogoutSuccess
    default:
      return state
  }
}

const authReducer = combineReducers({
  authLoginError,
  authLoginLoading,
  authLoginSuccess,
  account,
  isAuthenticated,
  authLogoutLoading,
  authLogoutSuccess
})

export default authReducer
