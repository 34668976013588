import { combineReducers } from 'redux'
import {
  SCHOOL_LIST_ERROR,
  SCHOOL_LIST_LOADING,
  SCHOOL_LIST_SUCCESS,

  SCHOOL_ADD_ERROR,
  SCHOOL_ADD_LOADING,
  SCHOOL_ADD_SUCCESS,

  SCHOOL_VIEW_ERROR,
  SCHOOL_VIEW_LOADING,
  SCHOOL_VIEW_SUCCESS,

  SCHOOL_DELETE_ERROR,
  SCHOOL_DELETE_LOADING,
  SCHOOL_DELETE_SUCCESS,

  SCHOOL_PROFILE_ERROR,
  SCHOOL_PROFILE_LOADING,
  SCHOOL_PROFILE_SUCCESS,

  SCHOOL_EDIT_PROFILE_ERROR,
  SCHOOL_EDIT_PROFILE_LOADING,
  SCHOOL_EDIT_PROFILE_SUCCESS,

  SCHOOL_LOGO_ERROR,
  SCHOOL_LOGO_LOADING,
  SCHOOL_LOGO_SUCCESS,

  SCHOOL_CODE_ERROR,
  SCHOOL_CODE_LOADING,
  SCHOOL_CODE_SUCCESS,

  SCHOOL_REGISTER_ERROR,
  SCHOOL_REGISTER_LOADING,
  SCHOOL_REGISTER_SUCCESS,

  SCHOOL_ACTIVATE_ERROR,
  SCHOOL_ACTIVATE_LOADING,
  SCHOOL_ACTIVATE_SUCCESS,

} from './constant'

const schoolListError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_LIST_ERROR:
      return action.schoolListError
    default:
      return state
  }
}

const schoolListLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_LIST_LOADING:
      return action.schoolListLoading
    default:
      return state
  }
}

const schoolListSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_LIST_SUCCESS:
      return action.schoolListSuccess
    default:
      return state
  }
}

const schools = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_LIST_SUCCESS:
      return action.schools
    default:
      return state
  }
}

const schoolAddError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ADD_ERROR:
      return action.schoolAddError
    default:
      return state
  }
}

const schoolAddLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ADD_LOADING:
      return action.schoolAddLoading
    default:
      return state
  }
}

const schoolAddSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ADD_SUCCESS:
      return action.schoolAddSuccess
    default:
      return state
  }
}

const schoolAdded = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_ADD_SUCCESS:
      return action.school
    default:
      return state
  }
}

const schoolViewError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_VIEW_ERROR:
      return action.schoolViewError
    default:
      return state
  }
}

const schoolViewLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_VIEW_LOADING:
      return action.schoolViewLoading
    default:
      return state
  }
}

const schoolViewSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_VIEW_SUCCESS:
      return action.schoolViewSuccess
    default:
      return state
  }
}

const school = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_VIEW_SUCCESS:
      return action.school
    default:
      return state
  }
}

const schoolDeleteError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_DELETE_ERROR:
      return action.schoolDeleteError
    default:
      return state
  }
}

const schoolDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_DELETE_LOADING:
      return action.schoolDeleteLoading
    default:
      return state
  }
}

const schoolDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_DELETE_SUCCESS:
      return action.schoolDeleteSuccess
    default:
      return state
  }
}

const schoolDeleted = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_DELETE_SUCCESS:
      return action.school
    default:
      return state
  }
}

const schoolProfileError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_PROFILE_ERROR:
      return action.schoolProfileError
    default:
      return state
  }
}

const schoolProfileLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_PROFILE_LOADING:
      return action.schoolProfileLoading
    default:
      return state
  }
}

const schoolProfileSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_PROFILE_SUCCESS:
      return action.schoolProfileSuccess
    default:
      return state
  }
}

const schoolProfile = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_PROFILE_SUCCESS:
      return action.profile
    default:
      return state
  }
}

const schoolEditProfileError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_EDIT_PROFILE_ERROR:
      return action.schoolEditProfileError
    default:
      return state
  }
}

const schoolEditProfileLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_EDIT_PROFILE_LOADING:
      return action.schoolEditProfileLoading
    default:
      return state
  }
}

const schoolEditProfileSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_EDIT_PROFILE_SUCCESS:
      return action.schoolEditProfileSuccess
    default:
      return state
  }
}

const schoolEditProfiled = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_EDIT_PROFILE_SUCCESS:
      return action.school
    default:
      return state
  }
}

const schoolLogoError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_LOGO_ERROR:
      return action.schoolLogoError
    default:
      return state
  }
}

const schoolLogoLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_LOGO_LOADING:
      return action.schoolLogoLoading
    default:
      return state
  }
}

const schoolLogoSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_LOGO_SUCCESS:
      return action.schoolLogoSuccess
    default:
      return state
  }
}

const schoolCodeError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_CODE_ERROR:
      return action.schoolCodeError
    default:
      return state
  }
}

const schoolCodeLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_CODE_LOADING:
      return action.schoolCodeLoading
    default:
      return state
  }
}

const schoolCodeSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_CODE_SUCCESS:
      return action.schoolCodeSuccess
    default:
      return state
  }
}


const schoolRegisterError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_REGISTER_ERROR:
      return action.schoolRegisterError
    default:
      return state
  }
}

const schoolRegisterLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_REGISTER_LOADING:
      return action.schoolRegisterLoading
    default:
      return state
  }
}

const schoolRegisterSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_REGISTER_SUCCESS:
      return action.schoolRegisterSuccess
    default:
      return state
  }
}

const schoolRegistered = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_REGISTER_SUCCESS:
      return action.school
    default:
      return state
  }
}

const schoolActivateError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACTIVATE_ERROR:
      return action.schoolActivateError
    default:
      return state
  }
}

const schoolActivateLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACTIVATE_LOADING:
      return action.schoolActivateLoading
    default:
      return state
  }
}

const schoolActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACTIVATE_SUCCESS:
      return action.schoolActivateSuccess
    default:
      return state
  }
}

const schoolActivated = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_ACTIVATE_SUCCESS:
      return action.school
    default:
      return state
  }
}

const schoolReducer = combineReducers({
  schoolListError,
  schoolListLoading,
  schoolListSuccess,
  schools,

  schoolAddError,
  schoolAddLoading,
  schoolAddSuccess,
  schoolAdded,

  schoolViewError,
  schoolViewLoading,
  schoolViewSuccess,
  school,

  schoolDeleteError,
  schoolDeleteLoading,
  schoolDeleteSuccess,
  schoolDeleted,

  schoolProfileError,
  schoolProfileLoading,
  schoolProfileSuccess,
  schoolProfile,

  schoolEditProfileError,
  schoolEditProfileLoading,
  schoolEditProfileSuccess,
  schoolEditProfiled,

  schoolLogoError,
  schoolLogoLoading,
  schoolLogoSuccess,

  schoolCodeError,
  schoolCodeLoading,
  schoolCodeSuccess,

  schoolRegisterError,
  schoolRegisterLoading,
  schoolRegisterSuccess,
  schoolRegistered,

  schoolActivateError,
  schoolActivateLoading,
  schoolActivateSuccess,
  schoolActivated,
})

export default schoolReducer
