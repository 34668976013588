// URL for UI
export const COOP_LIST_URL = 'coops'

export const COOP_LIST_ERROR = 'COOP_LIST_ERROR'
export const COOP_LIST_LOADING = 'COOP_LIST_LOADING'
export const COOP_LIST_SUCCESS = 'COOP_LIST_SUCCESS'

export const COOP_ADD_URL = 'manage/coop'
export const COOP_ADD_ERROR = 'COOP_ADD_ERROR'
export const COOP_ADD_LOADING = 'COOP_ADD_LOADING'
export const COOP_ADD_SUCCESS = 'COOP_ADD_SUCCESS'

export const COOP_VIEW_URL = 'coop'
export const COOP_VIEW_ERROR = 'COOP_VIEW_ERROR'
export const COOP_VIEW_LOADING = 'COOP_VIEW_LOADING'
export const COOP_VIEW_SUCCESS = 'COOP_VIEW_SUCCESS'

export const COOP_DELETE_URL = 'manage/coop/delete'
export const COOP_DELETE_ERROR = 'COOP_DELETE_ERROR'
export const COOP_DELETE_LOADING = 'COOP_DELETE_LOADING'
export const COOP_DELETE_SUCCESS = 'COOP_DELETE_SUCCESS'

// URL for UI
export const COOP_URL = {
    list: '/coops',
    view: '/coop',
    edit: '/coop/edit',
    url_param: '/:coopId'
}