import { combineReducers } from 'redux'
import {
  CANDIDATE_LIST_ERROR,
  CANDIDATE_LIST_LOADING,
  CANDIDATE_LIST_SUCCESS,

  CANDIDATE_ADD_ERROR,
  CANDIDATE_ADD_LOADING,
  CANDIDATE_ADD_SUCCESS,

  CANDIDATE_VIEW_ERROR,
  CANDIDATE_VIEW_LOADING,
  CANDIDATE_VIEW_SUCCESS,

  CANDIDATE_DELETE_ERROR,
  CANDIDATE_DELETE_LOADING,
  CANDIDATE_DELETE_SUCCESS,

  CANDIDATE_PAYMENT_ERROR,
  CANDIDATE_PAYMENT_LOADING,
  CANDIDATE_PAYMENT_SUCCESS,

  CANDIDATE_CONFIRM_ERROR,
  CANDIDATE_CONFIRM_LOADING,
  CANDIDATE_CONFIRM_SUCCESS,

  CANDIDATE_MANUAL_PAYMENT_ERROR,
  CANDIDATE_MANUAL_PAYMENT_LOADING,
  CANDIDATE_MANUAL_PAYMENT_SUCCESS,

  CANDIDATE_REJECT_ERROR,
  CANDIDATE_REJECT_LOADING,
  CANDIDATE_REJECT_SUCCESS,

  CANDIDATE_SCHEDULE_LIST_ERROR,
  CANDIDATE_SCHEDULE_LIST_LOADING,
  CANDIDATE_SCHEDULE_LIST_SUCCESS,
} from 'views/Candidate/constant'

const candidateListError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_LIST_ERROR:
      return action.candidateListError
    default:
      return state
  }
}

const candidateListLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_LIST_LOADING:
      return action.candidateListLoading
    default:
      return state
  }
}

const candidateListSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_LIST_SUCCESS:
      return action.candidateListSuccess
    default:
      return state
  }
}

const candidates = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_LIST_SUCCESS:
      return action.candidates
    default:
      return state
  }
}

const candidateAddError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_ADD_ERROR:
      return action.candidateAddError
    default:
      return state
  }
}

const candidateAddLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_ADD_LOADING:
      return action.candidateAddLoading
    default:
      return state
  }
}

const candidateAddSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_ADD_SUCCESS:
      return action.candidateAddSuccess
    default:
      return state
  }
}

const candidateAdded = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_ADD_SUCCESS:
      return action.candidate
    default:
      return state
  }
}

const candidateViewError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_VIEW_ERROR:
      return action.candidateViewError
    default:
      return state
  }
}

const candidateViewLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_VIEW_LOADING:
      return action.candidateViewLoading
    default:
      return state
  }
}

const candidateViewSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_VIEW_SUCCESS:
      return action.candidateViewSuccess
    default:
      return state
  }
}

const candidate = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_VIEW_SUCCESS:
      return action.candidate
    default:
      return state
  }
}

const candidateDeleteError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_DELETE_ERROR:
      return action.candidateDeleteError
    default:
      return state
  }
}

const candidateDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_DELETE_LOADING:
      return action.candidateDeleteLoading
    default:
      return state
  }
}

const candidateDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_DELETE_SUCCESS:
      return action.candidateDeleteSuccess
    default:
      return state
  }
}

const candidateDeleted = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_DELETE_SUCCESS:
      return action.candidate
    default:
      return state
  }
}

const candidatePaymentError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_PAYMENT_ERROR:
      return action.candidatePaymentError
    default:
      return state
  }
}

const candidatePaymentLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_PAYMENT_LOADING:
      return action.candidatePaymentLoading
    default:
      return state
  }
}

const candidatePaymentSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_PAYMENT_SUCCESS:
      return action.candidatePaymentSuccess
    default:
      return state
  }
}

const candidatePaymented = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_PAYMENT_SUCCESS:
      return action.candidate
    default:
      return state
  }
}

const candidateConfirmError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_CONFIRM_ERROR:
      return action.candidateConfirmError
    default:
      return state
  }
}

const candidateConfirmLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_CONFIRM_LOADING:
      return action.candidateConfirmLoading
    default:
      return state
  }
}

const candidateConfirmSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_CONFIRM_SUCCESS:
      return action.candidateConfirmSuccess
    default:
      return state
  }
}

const candidateConfirmed = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_CONFIRM_SUCCESS:
      return action.candidate
    default:
      return state
  }
}

const candidateManualPaymentError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_MANUAL_PAYMENT_ERROR:
      return action.candidateManualPaymentError
    default:
      return state
  }
}

const candidateManualPaymentLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_MANUAL_PAYMENT_LOADING:
      return action.candidateManualPaymentLoading
    default:
      return state
  }
}

const candidateManualPaymentSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_MANUAL_PAYMENT_SUCCESS:
      return action.candidateManualPaymentSuccess
    default:
      return state
  }
}

const candidateManualPaymented = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_MANUAL_PAYMENT_SUCCESS:
      return action.candidate
    default:
      return state
  }
}

const candidateRejectError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_REJECT_ERROR:
      return action.candidateRejectError
    default:
      return state
  }
}

const candidateRejectLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_REJECT_LOADING:
      return action.candidateRejectLoading
    default:
      return state
  }
}

const candidateRejectSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_REJECT_SUCCESS:
      return action.candidateRejectSuccess
    default:
      return state
  }
}

const candidateRejected = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_REJECT_SUCCESS:
      return action.candidate
    default:
      return state
  }
}

const candidateScheduleListError = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_SCHEDULE_LIST_ERROR:
      return action.candidateScheduleListError
    default:
      return state
  }
}

const candidateScheduleListLoading = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_SCHEDULE_LIST_LOADING:
      return action.candidateScheduleListLoading
    default:
      return state
  }
}

const candidateScheduleListSuccess = (state = false, action) => {
  switch (action.type) {
    case CANDIDATE_SCHEDULE_LIST_SUCCESS:
      return action.candidateScheduleListSuccess
    default:
      return state
  }
}

const candidateSchedules = (state = [], action) => {
  switch (action.type) {
    case CANDIDATE_SCHEDULE_LIST_SUCCESS:
      return action.candidateSchedules
    default:
      return state
  }
}
const candidateReducer = combineReducers({
  candidateListError,
  candidateListLoading,
  candidateListSuccess,
  candidates,

  candidateAddError,
  candidateAddLoading,
  candidateAddSuccess,
  candidateAdded,

  candidateViewError,
  candidateViewLoading,
  candidateViewSuccess,
  candidate,

  candidateDeleteError,
  candidateDeleteLoading,
  candidateDeleteSuccess,
  candidateDeleted,

  candidatePaymentError,
  candidatePaymentLoading,
  candidatePaymentSuccess,
  candidatePaymented,

  candidateConfirmError,
  candidateConfirmLoading,
  candidateConfirmSuccess,
  candidateConfirmed,

  candidateManualPaymentError,
  candidateManualPaymentLoading,
  candidateManualPaymentSuccess,
  candidateManualPaymented,

  candidateRejectError,
  candidateRejectLoading,
  candidateRejectSuccess,
  candidateRejected,

  candidateScheduleListError,
  candidateScheduleListLoading,
  candidateScheduleListSuccess,
  candidateSchedules,
})

export default candidateReducer
