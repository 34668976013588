import { combineReducers } from 'redux'
import {
  YEAR_SUBJECT_LIST_ERROR,
  YEAR_SUBJECT_LIST_LOADING,
  YEAR_SUBJECT_LIST_SUCCESS,

  YEAR_SUBJECT_ADD_ERROR,
  YEAR_SUBJECT_ADD_LOADING,
  YEAR_SUBJECT_ADD_SUCCESS,

  YEAR_SUBJECT_VIEW_ERROR,
  YEAR_SUBJECT_VIEW_LOADING,
  YEAR_SUBJECT_VIEW_SUCCESS,
  
  YEAR_SUBJECT_DELETE_ERROR,
  YEAR_SUBJECT_DELETE_LOADING,
  YEAR_SUBJECT_DELETE_SUCCESS,
} from 'views/YearSubject/constant'

const yearSubjectListError = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_LIST_ERROR:
      return action.yearSubjectListError
    default:
      return state
  }
}

const yearSubjectListLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_LIST_LOADING:
      return action.yearSubjectListLoading
    default:
      return state
  }
}

const yearSubjectListSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_LIST_SUCCESS:
      return action.yearSubjectListSuccess
    default:
      return state
  }
}

const yearSubjects = (state = [], action) => {
  switch (action.type) {
    case YEAR_SUBJECT_LIST_SUCCESS:
      return action.yearSubjects
    default:
      return state
  }
}

const yearSubjectAddError = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_ADD_ERROR:
      return action.yearSubjectAddError
    default:
      return state
  }
}

const yearSubjectAddLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_ADD_LOADING:
      return action.yearSubjectAddLoading
    default:
      return state
  }
}

const yearSubjectAddSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_ADD_SUCCESS:
      return action.yearSubjectAddSuccess
    default:
      return state
  }
}

const yearSubjectAdded = (state = [], action) => {
  switch (action.type) {
    case YEAR_SUBJECT_ADD_SUCCESS:
      return action.yearSubject
    default:
      return state
  }
}

const yearSubjectViewError = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_VIEW_ERROR:
      return action.yearSubjectViewError
    default:
      return state
  }
}

const yearSubjectViewLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_VIEW_LOADING:
      return action.yearSubjectViewLoading
    default:
      return state
  }
}

const yearSubjectViewSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_VIEW_SUCCESS:
      return action.yearSubjectViewSuccess
    default:
      return state
  }
}

const yearSubject = (state = [], action) => {
  switch (action.type) {
    case YEAR_SUBJECT_VIEW_SUCCESS:
      return action.yearSubject
    default:
      return state
  }
}

const yearSubjectDeleteError = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_DELETE_ERROR:
      return action.yearSubjectDeleteError
    default:
      return state
  }
}

const yearSubjectDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_DELETE_LOADING:
      return action.yearSubjectDeleteLoading
    default:
      return state
  }
}

const yearSubjectDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_SUBJECT_DELETE_SUCCESS:
      return action.yearSubjectDeleteSuccess
    default:
      return state
  }
}

const yearSubjectDeleted = (state = [], action) => {
  switch (action.type) {
    case YEAR_SUBJECT_DELETE_SUCCESS:
      return action.yearSubject
    default:
      return state
  }
}


const yearSubjectReducer = combineReducers({
  yearSubjectListError,
  yearSubjectListLoading,
  yearSubjectListSuccess,
  yearSubjects,

  yearSubjectAddError,
  yearSubjectAddLoading,
  yearSubjectAddSuccess,
  yearSubjectAdded,

  yearSubjectViewError,
  yearSubjectViewLoading,
  yearSubjectViewSuccess,
  yearSubject,

  yearSubjectDeleteError,
  yearSubjectDeleteLoading,
  yearSubjectDeleteSuccess,
  yearSubjectDeleted,
})

export default yearSubjectReducer
