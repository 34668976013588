import { combineReducers } from 'redux'
import {
  GRADE_LIST_ERROR,
  GRADE_LIST_LOADING,
  GRADE_LIST_SUCCESS,

  GRADE_TEACHER_LIST_ERROR,
  GRADE_TEACHER_LIST_LOADING,
  GRADE_TEACHER_LIST_SUCCESS,

  GRADE_ADD_ERROR,
  GRADE_ADD_LOADING,
  GRADE_ADD_SUCCESS,

  GRADE_VIEW_ERROR,
  GRADE_VIEW_LOADING,
  GRADE_VIEW_SUCCESS,
  
  GRADE_DELETE_ERROR,
  GRADE_DELETE_LOADING,
  GRADE_DELETE_SUCCESS,

  GRADE_ACTIVATE_ERROR,
  GRADE_ACTIVATE_LOADING,
  GRADE_ACTIVATE_SUCCESS,
} from 'views/Grade/constant'

const gradeListError = (state = false, action) => {
  switch (action.type) {
    case GRADE_LIST_ERROR:
      return action.gradeListError
    default:
      return state
  }
}

const gradeListLoading = (state = false, action) => {
  switch (action.type) {
    case GRADE_LIST_LOADING:
      return action.gradeListLoading
    default:
      return state
  }
}

const gradeListSuccess = (state = false, action) => {
  switch (action.type) {
    case GRADE_LIST_SUCCESS:
      return action.gradeListSuccess
    default:
      return state
  }
}

const grades = (state = [], action) => {
  switch (action.type) {
    case GRADE_LIST_SUCCESS:
      return action.grades
    default:
      return state
  }
}

const gradeTeacherListError = (state = false, action) => {
  switch (action.type) {
    case GRADE_TEACHER_LIST_ERROR:
      return action.gradeTeacherListError
    default:
      return state
  }
}

const gradeTeacherListLoading = (state = false, action) => {
  switch (action.type) {
    case GRADE_TEACHER_LIST_LOADING:
      return action.gradeTeacherListLoading
    default:
      return state
  }
}

const gradeTeacherListSuccess = (state = false, action) => {
  switch (action.type) {
    case GRADE_TEACHER_LIST_SUCCESS:
      return action.gradeTeacherListSuccess
    default:
      return state
  }
}

const teachergrades = (state = [], action) => {
  switch (action.type) {
    case GRADE_TEACHER_LIST_SUCCESS:
      return action.grades
    default:
      return state
  }
}

const gradeAddError = (state = false, action) => {
  switch (action.type) {
    case GRADE_ADD_ERROR:
      return action.gradeAddError
    default:
      return state
  }
}

const gradeAddLoading = (state = false, action) => {
  switch (action.type) {
    case GRADE_ADD_LOADING:
      return action.gradeAddLoading
    default:
      return state
  }
}

const gradeAddSuccess = (state = false, action) => {
  switch (action.type) {
    case GRADE_ADD_SUCCESS:
      return action.gradeAddSuccess
    default:
      return state
  }
}

const gradeAdded = (state = [], action) => {
  switch (action.type) {
    case GRADE_ADD_SUCCESS:
      return action.grade
    default:
      return state
  }
}

const gradeViewError = (state = false, action) => {
  switch (action.type) {
    case GRADE_VIEW_ERROR:
      return action.gradeViewError
    default:
      return state
  }
}

const gradeViewLoading = (state = false, action) => {
  switch (action.type) {
    case GRADE_VIEW_LOADING:
      return action.gradeViewLoading
    default:
      return state
  }
}

const gradeViewSuccess = (state = false, action) => {
  switch (action.type) {
    case GRADE_VIEW_SUCCESS:
      return action.gradeViewSuccess
    default:
      return state
  }
}

const grade = (state = [], action) => {
  switch (action.type) {
    case GRADE_VIEW_SUCCESS:
      return action.grade
    default:
      return state
  }
}

const gradeDeleteError = (state = false, action) => {
  switch (action.type) {
    case GRADE_DELETE_ERROR:
      return action.gradeDeleteError
    default:
      return state
  }
}

const gradeDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case GRADE_DELETE_LOADING:
      return action.gradeDeleteLoading
    default:
      return state
  }
}

const gradeDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case GRADE_DELETE_SUCCESS:
      return action.gradeDeleteSuccess
    default:
      return state
  }
}

const gradeDeleted = (state = [], action) => {
  switch (action.type) {
    case GRADE_DELETE_SUCCESS:
      return action.grade
    default:
      return state
  }
}

const gradeActivateError = (state = false, action) => {
  switch (action.type) {
    case GRADE_ACTIVATE_ERROR:
      return action.gradeActivateError
    default:
      return state
  }
}

const gradeActivateLoading = (state = false, action) => {
  switch (action.type) {
    case GRADE_ACTIVATE_LOADING:
      return action.gradeActivateLoading
    default:
      return state
  }
}

const gradeActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case GRADE_ACTIVATE_SUCCESS:
      return action.gradeActivateSuccess
    default:
      return state
  }
}

const gradeActivated = (state = [], action) => {
  switch (action.type) {
    case GRADE_ACTIVATE_SUCCESS:
      return action.grade
    default:
      return state
  }
}


const gradeReducer = combineReducers({
  gradeListError,
  gradeListLoading,
  gradeListSuccess,
  grades,

  gradeTeacherListError,
  gradeTeacherListLoading,
  gradeTeacherListSuccess,
  teachergrades,

  gradeAddError,
  gradeAddLoading,
  gradeAddSuccess,
  gradeAdded,

  gradeViewError,
  gradeViewLoading,
  gradeViewSuccess,
  grade,

  gradeDeleteError,
  gradeDeleteLoading,
  gradeDeleteSuccess,
  gradeDeleted,

  gradeActivateError,
  gradeActivateLoading,
  gradeActivateSuccess,
  gradeActivated,
})

export default gradeReducer
