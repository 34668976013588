// URL for UI
export const TEACHER_LIST_URL = 'teachers'

export const TEACHER_LIST_ERROR = 'TEACHER_LIST_ERROR'
export const TEACHER_LIST_LOADING = 'TEACHER_LIST_LOADING'
export const TEACHER_LIST_SUCCESS = 'TEACHER_LIST_SUCCESS'

export const TEACHER_ADD_URL = 'teacher'
export const TEACHER_ADD_ERROR = 'TEACHER_ADD_ERROR'
export const TEACHER_ADD_LOADING = 'TEACHER_ADD_LOADING'
export const TEACHER_ADD_SUCCESS = 'TEACHER_ADD_SUCCESS'

export const TEACHER_VIEW_URL = 'teacher/view'
export const TEACHER_VIEW_ERROR = 'TEACHER_VIEW_ERROR'
export const TEACHER_VIEW_LOADING = 'TEACHER_VIEW_LOADING'
export const TEACHER_VIEW_SUCCESS = 'TEACHER_VIEW_SUCCESS'

export const TEACHER_DELETE_URL = 'teacher/delete'
export const TEACHER_DELETE_ERROR = 'TEACHER_DELETE_ERROR'
export const TEACHER_DELETE_LOADING = 'TEACHER_DELETE_LOADING'
export const TEACHER_DELETE_SUCCESS = 'TEACHER_DELETE_SUCCESS'

export const TEACHER_ACTIVATE_URL = 'teacher/activate'
export const TEACHER_ACTIVATE_ERROR = 'TEACHER_ACTIVATE_ERROR'
export const TEACHER_ACTIVATE_LOADING = 'TEACHER_ACTIVATE_LOADING'
export const TEACHER_ACTIVATE_SUCCESS = 'TEACHER_ACTIVATE_SUCCESS'

export const TEACHER_EMAIL_TOKEN_URL = 'teacher/email/token'
export const TEACHER_EMAIL_TOKEN_ERROR = 'TEACHER_EMAIL_TOKEN_ERROR'
export const TEACHER_EMAIL_TOKEN_LOADING = 'TEACHER_EMAIL_TOKEN_LOADING'
export const TEACHER_EMAIL_TOKEN_SUCCESS = 'TEACHER_EMAIL_TOKEN_SUCCESS'

export const TEACHER_EMAIL_ACTIVATE_URL = 'teacher/email/activate'
export const TEACHER_EMAIL_ACTIVATE_ERROR = 'TEACHER_EMAIL_ACTIVATE_ERROR'
export const TEACHER_EMAIL_ACTIVATE_LOADING = 'TEACHER_EMAIL_ACTIVATE_LOADING'
export const TEACHER_EMAIL_ACTIVATE_SUCCESS = 'TEACHER_EMAIL_ACTIVATE_SUCCESS'

export const TEACHER_PHONE_TOKEN_URL = 'teacher/phone/token'
export const TEACHER_PHONE_TOKEN_ERROR = 'TEACHER_PHONE_TOKEN_ERROR'
export const TEACHER_PHONE_TOKEN_LOADING = 'TEACHER_PHONE_TOKEN_LOADING'
export const TEACHER_PHONE_TOKEN_SUCCESS = 'TEACHER_PHONE_TOKEN_SUCCESS'

export const TEACHER_PHONE_ACTIVATE_URL = 'teacher/phone/activate'
export const TEACHER_PHONE_ACTIVATE_ERROR = 'TEACHER_PHONE_ACTIVATE_ERROR'
export const TEACHER_PHONE_ACTIVATE_LOADING = 'TEACHER_PHONE_ACTIVATE_LOADING'
export const TEACHER_PHONE_ACTIVATE_SUCCESS = 'TEACHER_PHONE_ACTIVATE_SUCCESS'

export const TEACHER_RESET_URL = 'teacher_reset'
export const TEACHER_RESET_ERROR = 'TEACHER_RESET_ERROR'
export const TEACHER_RESET_LOADING = 'TEACHER_RESET_LOADING'
export const TEACHER_RESET_SUCCESS = 'TEACHER_RESET_SUCCESS'

export const TEACHER_IMAGE_URL = 'teacher/image'
export const TEACHER_IMAGE_ERROR = 'TEACHER_IMAGE_ERROR'
export const TEACHER_IMAGE_LOADING = 'TEACHER_IMAGE_LOADING'
export const TEACHER_IMAGE_SUCCESS = 'TEACHER_IMAGE_SUCCESS'

export const TEACHER_BULK_URL = 'teacher/bulk'
export const TEACHER_BULK_ERROR = 'TEACHER_BULK_ERROR'
export const TEACHER_BULK_LOADING = 'TEACHER_BULK_LOADING'
export const TEACHER_BULK_SUCCESS = 'TEACHER_BULK_SUCCESS'

// URL for UI
export const TEACHER_URL = {
    list: '/teachers',
    view: '/teacher',
    edit: '/teacher/edit',
    bulk: '/teacher/bulk',
    url_param: '/:teacherId'
}