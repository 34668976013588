// URL for UI
export const SELECTION_LIST_URL = 'selections'
export const SELECTION_LIST_ERROR = 'SELECTION_LIST_ERROR'
export const SELECTION_LIST_LOADING = 'SELECTION_LIST_LOADING'
export const SELECTION_LIST_SUCCESS = 'SELECTION_LIST_SUCCESS'

export const SELECTION_ADD_URL = 'selection'
export const SELECTION_ADD_ERROR = 'SELECTION_ADD_ERROR'
export const SELECTION_ADD_LOADING = 'SELECTION_ADD_LOADING'
export const SELECTION_ADD_SUCCESS = 'SELECTION_ADD_SUCCESS'

export const SELECTION_VIEW_URL = 'selection'
export const SELECTION_VIEW_ERROR = 'SELECTION_VIEW_ERROR'
export const SELECTION_VIEW_LOADING = 'SELECTION_VIEW_LOADING'
export const SELECTION_VIEW_SUCCESS = 'SELECTION_VIEW_SUCCESS'

export const SELECTION_DELETE_URL = 'selection/delete'
export const SELECTION_DELETE_ERROR = 'SELECTION_DELETE_ERROR'
export const SELECTION_DELETE_LOADING = 'SELECTION_DELETE_LOADING'
export const SELECTION_DELETE_SUCCESS = 'SELECTION_DELETE_SUCCESS'

export const SELECTION_SCHEDULE_LIST_URL = 'selection_schedules'
export const SELECTION_SCHEDULE_LIST_ERROR = 'SELECTION_SCHEDULE_LIST_ERROR'
export const SELECTION_SCHEDULE_LIST_LOADING = 'SELECTION_SCHEDULE_LIST_LOADING'
export const SELECTION_SCHEDULE_LIST_SUCCESS = 'SELECTION_SCHEDULE_LIST_SUCCESS'

export const SELECTION_SCHEDULE_ADD_URL = 'selection_schedule'
export const SELECTION_SCHEDULE_ADD_ERROR = 'SELECTION_SCHEDULE_ADD_ERROR'
export const SELECTION_SCHEDULE_ADD_LOADING = 'SELECTION_SCHEDULE_ADD_LOADING'
export const SELECTION_SCHEDULE_ADD_SUCCESS = 'SELECTION_SCHEDULE_ADD_SUCCESS'

export const SELECTION_SCHEDULE_DELETE_URL = 'selection_schedule/delete'
export const SELECTION_SCHEDULE_DELETE_ERROR = 'SELECTION_SCHEDULE_DELETE_ERROR'
export const SELECTION_SCHEDULE_DELETE_LOADING = 'SELECTION_SCHEDULE_DELETE_LOADING'
export const SELECTION_SCHEDULE_DELETE_SUCCESS = 'SELECTION_SCHEDULE_DELETE_SUCCESS'

export const SELECTION_SCHEDULE_CANDIDATE_ADD_URL = 'schedule_candidate'
export const SELECTION_SCHEDULE_CANDIDATE_ADD_ERROR = 'SELECTION_SCHEDULE_CANDIDATE_ADD_ERROR'
export const SELECTION_SCHEDULE_CANDIDATE_ADD_LOADING = 'SELECTION_SCHEDULE_CANDIDATE_ADD_LOADING'
export const SELECTION_SCHEDULE_CANDIDATE_ADD_SUCCESS = 'SELECTION_SCHEDULE_CANDIDATE_ADD_SUCCESS'

export const SELECTION_SCHEDULE_CANDIDATE_DELETE_URL = 'schedule_candidate/delete'
export const SELECTION_SCHEDULE_CANDIDATE_DELETE_ERROR = 'SELECTION_SCHEDULE_CANDIDATE_DELETE_ERROR'
export const SELECTION_SCHEDULE_CANDIDATE_DELETE_LOADING = 'SELECTION_SCHEDULE_CANDIDATE_DELETE_LOADING'
export const SELECTION_SCHEDULE_CANDIDATE_DELETE_SUCCESS = 'SELECTION_SCHEDULE_CANDIDATE_DELETE_SUCCESS'

export const SELECTION_SCHEDULE_CANDIDATE_APPROVE_URL = 'schedule_candidate/approve'
export const SELECTION_SCHEDULE_CANDIDATE_APPROVE_ERROR = 'SELECTION_SCHEDULE_CANDIDATE_APPROVE_ERROR'
export const SELECTION_SCHEDULE_CANDIDATE_APPROVE_LOADING = 'SELECTION_SCHEDULE_CANDIDATE_APPROVE_LOADING'
export const SELECTION_SCHEDULE_CANDIDATE_APPROVE_SUCCESS = 'SELECTION_SCHEDULE_CANDIDATE_APPROVE_SUCCESS'

export const SELECTION_SCHEDULE_CANDIDATE_REJECT_URL = 'schedule_candidate/reject'
export const SELECTION_SCHEDULE_CANDIDATE_REJECT_ERROR = 'SELECTION_SCHEDULE_CANDIDATE_REJECT_ERROR'
export const SELECTION_SCHEDULE_CANDIDATE_REJECT_LOADING = 'SELECTION_SCHEDULE_CANDIDATE_REJECT_LOADING'
export const SELECTION_SCHEDULE_CANDIDATE_REJECT_SUCCESS = 'SELECTION_SCHEDULE_CANDIDATE_REJECT_SUCCESS'
// URL for UI
export const SELECTION_URL = {
    list: '/selections',
    view: '/selection',
    edit: '/selection/edit',
    url_param: '/:selectionId',
}