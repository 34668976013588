import { combineReducers } from 'redux'
import {
  YEAR_STUDENT_LIST_ERROR,
  YEAR_STUDENT_LIST_LOADING,
  YEAR_STUDENT_LIST_SUCCESS,

  YEAR_STUDENT_ADD_ERROR,
  YEAR_STUDENT_ADD_LOADING,
  YEAR_STUDENT_ADD_SUCCESS,

  YEAR_STUDENT_VIEW_ERROR,
  YEAR_STUDENT_VIEW_LOADING,
  YEAR_STUDENT_VIEW_SUCCESS,
  
  YEAR_STUDENT_DELETE_ERROR,
  YEAR_STUDENT_DELETE_LOADING,
  YEAR_STUDENT_DELETE_SUCCESS,
} from 'views/YearStudent/constant'

const yearStudentListError = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_LIST_ERROR:
      return action.yearStudentListError
    default:
      return state
  }
}

const yearStudentListLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_LIST_LOADING:
      return action.yearStudentListLoading
    default:
      return state
  }
}

const yearStudentListSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_LIST_SUCCESS:
      return action.yearStudentListSuccess
    default:
      return state
  }
}

const yearStudents = (state = [], action) => {
  switch (action.type) {
    case YEAR_STUDENT_LIST_SUCCESS:
      return action.yearStudents
    default:
      return state
  }
}

const yearStudentAddError = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_ADD_ERROR:
      return action.yearStudentAddError
    default:
      return state
  }
}

const yearStudentAddLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_ADD_LOADING:
      return action.yearStudentAddLoading
    default:
      return state
  }
}

const yearStudentAddSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_ADD_SUCCESS:
      return action.yearStudentAddSuccess
    default:
      return state
  }
}

const yearStudentAdded = (state = [], action) => {
  switch (action.type) {
    case YEAR_STUDENT_ADD_SUCCESS:
      return action.yearStudent
    default:
      return state
  }
}

const yearStudentViewError = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_VIEW_ERROR:
      return action.yearStudentViewError
    default:
      return state
  }
}

const yearStudentViewLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_VIEW_LOADING:
      return action.yearStudentViewLoading
    default:
      return state
  }
}

const yearStudentViewSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_VIEW_SUCCESS:
      return action.yearStudentViewSuccess
    default:
      return state
  }
}

const yearStudent = (state = [], action) => {
  switch (action.type) {
    case YEAR_STUDENT_VIEW_SUCCESS:
      return action.yearStudent
    default:
      return state
  }
}

const yearStudentDeleteError = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_DELETE_ERROR:
      return action.yearStudentDeleteError
    default:
      return state
  }
}

const yearStudentDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_DELETE_LOADING:
      return action.yearStudentDeleteLoading
    default:
      return state
  }
}

const yearStudentDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_STUDENT_DELETE_SUCCESS:
      return action.yearStudentDeleteSuccess
    default:
      return state
  }
}

const yearStudentDeleted = (state = [], action) => {
  switch (action.type) {
    case YEAR_STUDENT_DELETE_SUCCESS:
      return action.yearStudent
    default:
      return state
  }
}


const yearStudentReducer = combineReducers({
  yearStudentListError,
  yearStudentListLoading,
  yearStudentListSuccess,
  yearStudents,

  yearStudentAddError,
  yearStudentAddLoading,
  yearStudentAddSuccess,
  yearStudentAdded,

  yearStudentViewError,
  yearStudentViewLoading,
  yearStudentViewSuccess,
  yearStudent,

  yearStudentDeleteError,
  yearStudentDeleteLoading,
  yearStudentDeleteSuccess,
  yearStudentDeleted,
})

export default yearStudentReducer
