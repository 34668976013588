import { combineReducers } from 'redux'
import {
  STUDENT_LIST_ERROR,
  STUDENT_LIST_LOADING,
  STUDENT_LIST_SUCCESS,

  STUDENT_ADD_ERROR,
  STUDENT_ADD_LOADING,
  STUDENT_ADD_SUCCESS,

  STUDENT_VIEW_ERROR,
  STUDENT_VIEW_LOADING,
  STUDENT_VIEW_SUCCESS,

  STUDENT_DELETE_ERROR,
  STUDENT_DELETE_LOADING,
  STUDENT_DELETE_SUCCESS,

  STUDENT_BULK_ERROR,
  STUDENT_BULK_LOADING,
  STUDENT_BULK_SUCCESS,

  STUDENT_ACTIVATE_ERROR,
  STUDENT_ACTIVATE_LOADING,
  STUDENT_ACTIVATE_SUCCESS,

  STUDENT_RESET_ERROR,
  STUDENT_RESET_LOADING,
  STUDENT_RESET_SUCCESS,

  STUDENT_EMAIL_TOKEN_ERROR,
  STUDENT_EMAIL_TOKEN_LOADING,
  STUDENT_EMAIL_TOKEN_SUCCESS,

  STUDENT_EMAIL_ACTIVATE_ERROR,
  STUDENT_EMAIL_ACTIVATE_LOADING,
  STUDENT_EMAIL_ACTIVATE_SUCCESS,

  STUDENT_PHONE_TOKEN_ERROR,
  STUDENT_PHONE_TOKEN_LOADING,
  STUDENT_PHONE_TOKEN_SUCCESS,

  STUDENT_PHONE_ACTIVATE_ERROR,
  STUDENT_PHONE_ACTIVATE_LOADING,
  STUDENT_PHONE_ACTIVATE_SUCCESS,

  STUDENT_IMAGE_ERROR,
  STUDENT_IMAGE_LOADING,
  STUDENT_IMAGE_SUCCESS,
} from 'views/Student/constant'

const studentListError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_LIST_ERROR:
      return action.studentListError
    default:
      return state
  }
}

const studentListLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_LIST_LOADING:
      return action.studentListLoading
    default:
      return state
  }
}

const studentListSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_LIST_SUCCESS:
      return action.studentListSuccess
    default:
      return state
  }
}

const students = (state = [], action) => {
  switch (action.type) {
    case STUDENT_LIST_SUCCESS:
      return action.students
    default:
      return state
  }
}

const studentAddError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_ADD_ERROR:
      return action.studentAddError
    default:
      return state
  }
}

const studentAddLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_ADD_LOADING:
      return action.studentAddLoading
    default:
      return state
  }
}

const studentAddSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_ADD_SUCCESS:
      return action.studentAddSuccess
    default:
      return state
  }
}

const studentAdded = (state = [], action) => {
  switch (action.type) {
    case STUDENT_ADD_SUCCESS:
      return action.student
    default:
      return state
  }
}

const studentViewError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_VIEW_ERROR:
      return action.studentViewError
    default:
      return state
  }
}

const studentViewLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_VIEW_LOADING:
      return action.studentViewLoading
    default:
      return state
  }
}

const studentViewSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_VIEW_SUCCESS:
      return action.studentViewSuccess
    default:
      return state
  }
}

const student = (state = [], action) => {
  switch (action.type) {
    case STUDENT_VIEW_SUCCESS:
      return action.student
    default:
      return state
  }
}

const studentDeleteError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_DELETE_ERROR:
      return action.studentDeleteError
    default:
      return state
  }
}

const studentDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_DELETE_LOADING:
      return action.studentDeleteLoading
    default:
      return state
  }
}

const studentDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_DELETE_SUCCESS:
      return action.studentDeleteSuccess
    default:
      return state
  }
}

const studentDeleted = (state = [], action) => {
  switch (action.type) {
    case STUDENT_DELETE_SUCCESS:
      return action.student
    default:
      return state
  }
}

const studentBulkError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_BULK_ERROR:
      return action.studentBulkError
    default:
      return state
  }
}

const studentBulkLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_BULK_LOADING:
      return action.studentBulkLoading
    default:
      return state
  }
}

const studentBulkSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_BULK_SUCCESS:
      return action.studentBulkSuccess
    default:
      return state
  }
}

const studentBulked = (state = [], action) => {
  switch (action.type) {
    case STUDENT_BULK_SUCCESS:
      return action.student
    default:
      return state
  }
}

const studentActivateError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_ACTIVATE_ERROR:
      return action.studentActivateError
    default:
      return state
  }
}

const studentActivateLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_ACTIVATE_LOADING:
      return action.studentActivateLoading
    default:
      return state
  }
}

const studentActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_ACTIVATE_SUCCESS:
      return action.studentActivateSuccess
    default:
      return state
  }
}

const studentActivated = (state = [], action) => {
  switch (action.type) {
    case STUDENT_ACTIVATE_SUCCESS:
      return action.student
    default:
      return state
  }
}

const studentResetError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_RESET_ERROR:
      return action.studentResetError
    default:
      return state
  }
}

const studentResetLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_RESET_LOADING:
      return action.studentResetLoading
    default:
      return state
  }
}

const studentResetSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_RESET_SUCCESS:
      return action.studentResetSuccess
    default:
      return state
  }
}

const studentEmailTokenError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_EMAIL_TOKEN_ERROR:
      return action.studentEmailTokenError
    default:
      return state
  }
}

const studentEmailTokenLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_EMAIL_TOKEN_LOADING:
      return action.studentEmailTokenLoading
    default:
      return state
  }
}

const studentEmailTokenSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_EMAIL_TOKEN_SUCCESS:
      return action.studentEmailTokenSuccess
    default:
      return state
  }
}

const studentEmailActivateError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_EMAIL_ACTIVATE_ERROR:
      return action.studentEmailActivateError
    default:
      return state
  }
}

const studentEmailActivateLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_EMAIL_ACTIVATE_LOADING:
      return action.studentEmailActivateLoading
    default:
      return state
  }
}

const studentEmailActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_EMAIL_ACTIVATE_SUCCESS:
      return action.studentEmailActivateSuccess
    default:
      return state
  }
}

const studentEmailActivated = (state = [], action) => {
  switch (action.type) {
    case STUDENT_EMAIL_ACTIVATE_SUCCESS:
      return action.student
    default:
      return state
  }
}

const studentPhoneTokenError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_PHONE_TOKEN_ERROR:
      return action.studentPhoneTokenError
    default:
      return state
  }
}

const studentPhoneTokenLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_PHONE_TOKEN_LOADING:
      return action.studentPhoneTokenLoading
    default:
      return state
  }
}

const studentPhoneTokenSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_PHONE_TOKEN_SUCCESS:
      return action.studentPhoneTokenSuccess
    default:
      return state
  }
}

const studentPhoneActivateError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_PHONE_ACTIVATE_ERROR:
      return action.studentPhoneActivateError
    default:
      return state
  }
}

const studentPhoneActivateLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_PHONE_ACTIVATE_LOADING:
      return action.studentPhoneActivateLoading
    default:
      return state
  }
}

const studentPhoneActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_PHONE_ACTIVATE_SUCCESS:
      return action.studentPhoneActivateSuccess
    default:
      return state
  }
}

const studentPhoneActivated = (state = [], action) => {
  switch (action.type) {
    case STUDENT_PHONE_ACTIVATE_SUCCESS:
      return action.student
    default:
      return state
  }
}

const studentImageError = (state = false, action) => {
  switch (action.type) {
    case STUDENT_IMAGE_ERROR:
      return action.studentImageError
    default:
      return state
  }
}

const studentImageLoading = (state = false, action) => {
  switch (action.type) {
    case STUDENT_IMAGE_LOADING:
      return action.studentImageLoading
    default:
      return state
  }
}

const studentImageSuccess = (state = false, action) => {
  switch (action.type) {
    case STUDENT_IMAGE_SUCCESS:
      return action.studentImageSuccess
    default:
      return state
  }
}

const studentImage = (state = [], action) => {
  switch (action.type) {
    case STUDENT_IMAGE_SUCCESS:
      return action.studentImage
    default:
      return state
  }
}

const studentReducer = combineReducers({
  studentListError,
  studentListLoading,
  studentListSuccess,
  students,

  studentAddError,
  studentAddLoading,
  studentAddSuccess,
  studentAdded,

  studentViewError,
  studentViewLoading,
  studentViewSuccess,
  student,

  studentDeleteError,
  studentDeleteLoading,
  studentDeleteSuccess,
  studentDeleted,

  studentBulkError,
  studentBulkLoading,
  studentBulkSuccess,
  studentBulked,

  studentActivateError,
  studentActivateLoading,
  studentActivateSuccess,
  studentActivated,

  studentResetError,
  studentResetLoading,
  studentResetSuccess,

  studentEmailTokenError,
  studentEmailTokenLoading,
  studentEmailTokenSuccess,

  studentEmailActivateError,
  studentEmailActivateLoading,
  studentEmailActivateSuccess,
  studentEmailActivated,

  studentPhoneTokenError,
  studentPhoneTokenLoading,
  studentPhoneTokenSuccess,

  studentPhoneActivateError,
  studentPhoneActivateLoading,
  studentPhoneActivateSuccess,
  studentPhoneActivated,

  studentImage,
  studentImageError,
  studentImageLoading,
  studentImageSuccess,
})

export default studentReducer
