// URL for API
export const PROFILE_VIEW_URL = 'activity'
export const PROFILE_VIEW_ERROR = 'PROFILE_VIEW_ERROR'
export const PROFILE_VIEW_LOADING = 'PROFILE_VIEW_LOADING'
export const PROFILE_VIEW_SUCCESS = 'PROFILE_VIEW_SUCCESS'

export const PROFILE_CPW_URL = 'auth/change_password'
export const PROFILE_CPW_ERROR = 'PROFILE_CPW_ERROR'
export const PROFILE_CPW_LOADING = 'PROFILE_CPW_LOADING'
export const PROFILE_CPW_SUCCESS = 'PROFILE_CPW_SUCCESS'

// URL for UI
export const PROFILE_URL = {
    view: '/profile',
}