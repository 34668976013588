// URL for API
export const PARENT_LIST_URL = 'manage/parents'
export const PARENT_LIST_ERROR = 'PARENT_LIST_ERROR'
export const PARENT_LIST_LOADING = 'PARENT_LIST_LOADING'
export const PARENT_LIST_SUCCESS = 'PARENT_LIST_SUCCESS'

export const PARENT_CHECK_URL = 'parent/check'
export const PARENT_CHECK_ERROR = 'PARENT_CHECK_ERROR'
export const PARENT_CHECK_LOADING = 'PARENT_CHECK_LOADING'
export const PARENT_CHECK_SUCCESS = 'PARENT_CHECK_SUCCESS'

export const PARENT_ADD_URL = 'parent'
export const PARENT_ADD_ERROR = 'PARENT_ADD_ERROR'
export const PARENT_ADD_LOADING = 'PARENT_ADD_LOADING'
export const PARENT_ADD_SUCCESS = 'PARENT_ADD_SUCCESS'

export const PARENT_VIEW_URL = 'parent/view'
export const PARENT_VIEW_ERROR = 'PARENT_VIEW_ERROR'
export const PARENT_VIEW_LOADING = 'PARENT_VIEW_LOADING'
export const PARENT_VIEW_SUCCESS = 'PARENT_VIEW_SUCCESS'

export const PARENT_DELETE_URL = 'parent/delete'
export const PARENT_DELETE_ERROR = 'PARENT_DELETE_ERROR'
export const PARENT_DELETE_LOADING = 'PARENT_DELETE_LOADING'
export const PARENT_DELETE_SUCCESS = 'PARENT_DELETE_SUCCESS'

export const PARENT_RESET_URL = 'parent_reset'
export const PARENT_RESET_ERROR = 'PARENT_RESET_ERROR'
export const PARENT_RESET_LOADING = 'PARENT_RESET_LOADING'
export const PARENT_RESET_SUCCESS = 'PARENT_RESET_SUCCESS'

export const PARENT_REGISTER_URL = 'parent/register'
export const PARENT_REGISTER_ERROR = 'PARENT_REGISTER_ERROR'
export const PARENT_REGISTER_LOADING = 'PARENT_REGISTER_LOADING'
export const PARENT_REGISTER_SUCCESS = 'PARENT_REGISTER_SUCCESS'

export const PARENT_ACTIVATE_PHONE_URL = 'manage/parent/activate_phone'
export const PARENT_ACTIVATE_PHONE_ERROR = 'PARENT_ACTIVATE_PHONE_ERROR'
export const PARENT_ACTIVATE_PHONE_LOADING = 'PARENT_ACTIVATE_PHONE_LOADING'
export const PARENT_ACTIVATE_PHONE_SUCCESS = 'PARENT_ACTIVATE_PHONE_SUCCESS'

export const PARENT_ACTIVATE_EMAIL_URL = 'manage/parent/activate_email'
export const PARENT_ACTIVATE_EMAIL_ERROR = 'PARENT_ACTIVATE_EMAIL_ERROR'
export const PARENT_ACTIVATE_EMAIL_LOADING = 'PARENT_ACTIVATE_EMAIL_LOADING'
export const PARENT_ACTIVATE_EMAIL_SUCCESS = 'PARENT_ACTIVATE_EMAIL_SUCCESS'

export const PARENT_EDIT_PROFILE_URL = 'profile/parent'
export const PARENT_EDIT_PROFILE_ERROR = 'PARENT_EDIT_PROFILE_ERROR'
export const PARENT_EDIT_PROFILE_LOADING = 'PARENT_EDIT_PROFILE_LOADING'
export const PARENT_EDIT_PROFILE_SUCCESS = 'PARENT_EDIT_PROFILE_SUCCESS'

export const PARENT_EMAIL_TOKEN_URL = 'parent/email/token'
export const PARENT_EMAIL_TOKEN_ERROR = 'PARENT_EMAIL_TOKEN_ERROR'
export const PARENT_EMAIL_TOKEN_LOADING = 'PARENT_EMAIL_TOKEN_LOADING'
export const PARENT_EMAIL_TOKEN_SUCCESS = 'PARENT_EMAIL_TOKEN_SUCCESS'

export const PARENT_EMAIL_ACTIVATE_URL = 'parent/email/activate'
export const PARENT_EMAIL_ACTIVATE_ERROR = 'PARENT_EMAIL_ACTIVATE_ERROR'
export const PARENT_EMAIL_ACTIVATE_LOADING = 'PARENT_EMAIL_ACTIVATE_LOADING'
export const PARENT_EMAIL_ACTIVATE_SUCCESS = 'PARENT_EMAIL_ACTIVATE_SUCCESS'

export const PARENT_PHONE_TOKEN_URL = 'parent/phone/token'
export const PARENT_PHONE_TOKEN_ERROR = 'PARENT_PHONE_TOKEN_ERROR'
export const PARENT_PHONE_TOKEN_LOADING = 'PARENT_PHONE_TOKEN_LOADING'
export const PARENT_PHONE_TOKEN_SUCCESS = 'PARENT_PHONE_TOKEN_SUCCESS'

export const PARENT_PHONE_ACTIVATE_URL = 'parent/phone/activate'
export const PARENT_PHONE_ACTIVATE_ERROR = 'PARENT_PHONE_ACTIVATE_ERROR'
export const PARENT_PHONE_ACTIVATE_LOADING = 'PARENT_PHONE_ACTIVATE_LOADING'
export const PARENT_PHONE_ACTIVATE_SUCCESS = 'PARENT_PHONE_ACTIVATE_SUCCESS'

export const PARENT_IMAGE_URL = 'parent/image'
export const PARENT_IMAGE_ERROR = 'PARENT_IMAGE_ERROR'
export const PARENT_IMAGE_LOADING = 'PARENT_IMAGE_LOADING'
export const PARENT_IMAGE_SUCCESS = 'PARENT_IMAGE_SUCCESS'

// URL for UI
export const PARENT_URL = {
    list: '/parents',
    view: '/parent',
    edit: '/parent/edit',
    url_param: '/:parentId'
}