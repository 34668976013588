import { combineReducers } from 'redux'
import {
  SELECTION_LIST_ERROR,
  SELECTION_LIST_LOADING,
  SELECTION_LIST_SUCCESS,

  SELECTION_ADD_ERROR,
  SELECTION_ADD_LOADING,
  SELECTION_ADD_SUCCESS,

  SELECTION_VIEW_ERROR,
  SELECTION_VIEW_LOADING,
  SELECTION_VIEW_SUCCESS,
  
  SELECTION_DELETE_ERROR,
  SELECTION_DELETE_LOADING,
  SELECTION_DELETE_SUCCESS,

  SELECTION_SCHEDULE_LIST_ERROR,
  SELECTION_SCHEDULE_LIST_LOADING,
  SELECTION_SCHEDULE_LIST_SUCCESS,

  SELECTION_SCHEDULE_ADD_ERROR,
  SELECTION_SCHEDULE_ADD_LOADING,
  SELECTION_SCHEDULE_ADD_SUCCESS,
  
  SELECTION_SCHEDULE_DELETE_ERROR,
  SELECTION_SCHEDULE_DELETE_LOADING,
  SELECTION_SCHEDULE_DELETE_SUCCESS,

  SELECTION_SCHEDULE_CANDIDATE_ADD_ERROR,
  SELECTION_SCHEDULE_CANDIDATE_ADD_LOADING,
  SELECTION_SCHEDULE_CANDIDATE_ADD_SUCCESS,
  
  SELECTION_SCHEDULE_CANDIDATE_DELETE_ERROR,
  SELECTION_SCHEDULE_CANDIDATE_DELETE_LOADING,
  SELECTION_SCHEDULE_CANDIDATE_DELETE_SUCCESS,

  SELECTION_SCHEDULE_CANDIDATE_APPROVE_ERROR,
  SELECTION_SCHEDULE_CANDIDATE_APPROVE_LOADING,
  SELECTION_SCHEDULE_CANDIDATE_APPROVE_SUCCESS,

  SELECTION_SCHEDULE_CANDIDATE_REJECT_ERROR,
  SELECTION_SCHEDULE_CANDIDATE_REJECT_LOADING,
  SELECTION_SCHEDULE_CANDIDATE_REJECT_SUCCESS,
} from 'views/Selection/constant'

const selectionListError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_LIST_ERROR:
      return action.selectionListError
    default:
      return state
  }
}

const selectionListLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_LIST_LOADING:
      return action.selectionListLoading
    default:
      return state
  }
}

const selectionListSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_LIST_SUCCESS:
      return action.selectionListSuccess
    default:
      return state
  }
}

const selections = (state = [], action) => {
  switch (action.type) {
    case SELECTION_LIST_SUCCESS:
      return action.selections
    default:
      return state
  }
}

const selectionAddError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_ADD_ERROR:
      return action.selectionAddError
    default:
      return state
  }
}

const selectionAddLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_ADD_LOADING:
      return action.selectionAddLoading
    default:
      return state
  }
}

const selectionAddSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_ADD_SUCCESS:
      return action.selectionAddSuccess
    default:
      return state
  }
}

const selectionAdded = (state = [], action) => {
  switch (action.type) {
    case SELECTION_ADD_SUCCESS:
      return action.selection
    default:
      return state
  }
}

const selectionViewError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_VIEW_ERROR:
      return action.selectionViewError
    default:
      return state
  }
}

const selectionViewLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_VIEW_LOADING:
      return action.selectionViewLoading
    default:
      return state
  }
}

const selectionViewSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_VIEW_SUCCESS:
      return action.selectionViewSuccess
    default:
      return state
  }
}

const selection = (state = [], action) => {
  switch (action.type) {
    case SELECTION_VIEW_SUCCESS:
      return action.selection
    default:
      return state
  }
}

const selectionDeleteError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_DELETE_ERROR:
      return action.selectionDeleteError
    default:
      return state
  }
}

const selectionDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_DELETE_LOADING:
      return action.selectionDeleteLoading
    default:
      return state
  }
}

const selectionDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_DELETE_SUCCESS:
      return action.selectionDeleteSuccess
    default:
      return state
  }
}

const selectionDeleted = (state = [], action) => {
  switch (action.type) {
    case SELECTION_DELETE_SUCCESS:
      return action.selection
    default:
      return state
  }
}

const selectionScheduleListError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_LIST_ERROR:
      return action.selectionScheduleListError
    default:
      return state
  }
}

const selectionScheduleListLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_LIST_LOADING:
      return action.selectionScheduleListLoading
    default:
      return state
  }
}

const selectionScheduleListSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_LIST_SUCCESS:
      return action.selectionScheduleListSuccess
    default:
      return state
  }
}

const selectionSchedules = (state = [], action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_LIST_SUCCESS:
      return action.selectionSchedules
    default:
      return state
  }
}

const selectionScheduleAddError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_ADD_ERROR:
      return action.selectionScheduleAddError
    default:
      return state
  }
}

const selectionScheduleAddLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_ADD_LOADING:
      return action.selectionScheduleAddLoading
    default:
      return state
  }
}

const selectionScheduleAddSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_ADD_SUCCESS:
      return action.selectionScheduleAddSuccess
    default:
      return state
  }
}

const selectionScheduleAdded = (state = [], action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_ADD_SUCCESS:
      return action.selectionSchedule
    default:
      return state
  }
}

const selectionScheduleDeleteError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_DELETE_ERROR:
      return action.selectionScheduleDeleteError
    default:
      return state
  }
}

const selectionScheduleDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_DELETE_LOADING:
      return action.selectionScheduleDeleteLoading
    default:
      return state
  }
}

const selectionScheduleDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_DELETE_SUCCESS:
      return action.selectionScheduleDeleteSuccess
    default:
      return state
  }
}

const selectionScheduleDeleted = (state = [], action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_DELETE_SUCCESS:
      return action.selectionSchedule
    default:
      return state
  }
}

const selectionScheduleCandidateAddError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_ADD_ERROR:
      return action.selectionScheduleCandidateAddError
    default:
      return state
  }
}

const selectionScheduleCandidateAddLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_ADD_LOADING:
      return action.selectionScheduleCandidateAddLoading
    default:
      return state
  }
}

const selectionScheduleCandidateAddSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_ADD_SUCCESS:
      return action.selectionScheduleCandidateAddSuccess
    default:
      return state
  }
}

const selectionScheduleCandidateAdded = (state = [], action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_ADD_SUCCESS:
      return action.selectionScheduleCandidate
    default:
      return state
  }
}

const selectionScheduleCandidateDeleteError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_DELETE_ERROR:
      return action.selectionScheduleCandidateDeleteError
    default:
      return state
  }
}

const selectionScheduleCandidateDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_DELETE_LOADING:
      return action.selectionScheduleCandidateDeleteLoading
    default:
      return state
  }
}

const selectionScheduleCandidateDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_DELETE_SUCCESS:
      return action.selectionScheduleCandidateDeleteSuccess
    default:
      return state
  }
}

const selectionScheduleCandidateDeleted = (state = [], action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_DELETE_SUCCESS:
      return action.selectionScheduleCandidate
    default:
      return state
  }
}

const selectionScheduleCandidateApproveError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_APPROVE_ERROR:
      return action.selectionScheduleCandidateApproveError
    default:
      return state
  }
}

const selectionScheduleCandidateApproveLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_APPROVE_LOADING:
      return action.selectionScheduleCandidateApproveLoading
    default:
      return state
  }
}

const selectionScheduleCandidateApproveSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_APPROVE_SUCCESS:
      return action.selectionScheduleCandidateApproveSuccess
    default:
      return state
  }
}

const selectionScheduleCandidateApproved = (state = [], action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_APPROVE_SUCCESS:
      return action.selectionScheduleCandidate
    default:
      return state
  }
}

const selectionScheduleCandidateRejectError = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_REJECT_ERROR:
      return action.selectionScheduleCandidateRejectError
    default:
      return state
  }
}

const selectionScheduleCandidateRejectLoading = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_REJECT_LOADING:
      return action.selectionScheduleCandidateRejectLoading
    default:
      return state
  }
}

const selectionScheduleCandidateRejectSuccess = (state = false, action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_REJECT_SUCCESS:
      return action.selectionScheduleCandidateRejectSuccess
    default:
      return state
  }
}

const selectionScheduleCandidateRejected = (state = [], action) => {
  switch (action.type) {
    case SELECTION_SCHEDULE_CANDIDATE_REJECT_SUCCESS:
      return action.selectionScheduleCandidate
    default:
      return state
  }
}


const selectionReducer = combineReducers({
  selectionListError,
  selectionListLoading,
  selectionListSuccess,
  selections,

  selectionAddError,
  selectionAddLoading,
  selectionAddSuccess,
  selectionAdded,

  selectionViewError,
  selectionViewLoading,
  selectionViewSuccess,
  selection,

  selectionDeleteError,
  selectionDeleteLoading,
  selectionDeleteSuccess,
  selectionDeleted,

  selectionScheduleListError,
  selectionScheduleListLoading,
  selectionScheduleListSuccess,
  selectionSchedules,

  selectionScheduleAddError,
  selectionScheduleAddLoading,
  selectionScheduleAddSuccess,
  selectionScheduleAdded,

  selectionScheduleDeleteError,
  selectionScheduleDeleteLoading,
  selectionScheduleDeleteSuccess,
  selectionScheduleDeleted,

  selectionScheduleCandidateAddError,
  selectionScheduleCandidateAddLoading,
  selectionScheduleCandidateAddSuccess,
  selectionScheduleCandidateAdded,

  selectionScheduleCandidateDeleteError,
  selectionScheduleCandidateDeleteLoading,
  selectionScheduleCandidateDeleteSuccess,
  selectionScheduleCandidateDeleted,

  selectionScheduleCandidateApproveError,
  selectionScheduleCandidateApproveLoading,
  selectionScheduleCandidateApproveSuccess,
  selectionScheduleCandidateApproved,

  selectionScheduleCandidateRejectError,
  selectionScheduleCandidateRejectLoading,
  selectionScheduleCandidateRejectSuccess,
  selectionScheduleCandidateRejected,
})

export default selectionReducer
