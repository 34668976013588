// URL for UI
export const CONF_LIST_URL = 'manage/confs'

export const CONF_LIST_ERROR = 'CONF_LIST_ERROR'
export const CONF_LIST_LOADING = 'CONF_LIST_LOADING'
export const CONF_LIST_SUCCESS = 'CONF_LIST_SUCCESS'

export const CONF_ADD_URL = 'manage/conf'
export const CONF_ADD_ERROR = 'CONF_ADD_ERROR'
export const CONF_ADD_LOADING = 'CONF_ADD_LOADING'
export const CONF_ADD_SUCCESS = 'CONF_ADD_SUCCESS'

export const CONF_VIEW_URL = 'manage/conf'
export const CONF_VIEW_ERROR = 'CONF_VIEW_ERROR'
export const CONF_VIEW_LOADING = 'CONF_VIEW_LOADING'
export const CONF_VIEW_SUCCESS = 'CONF_VIEW_SUCCESS'

export const CONF_DELETE_URL = 'manage/conf/delete'
export const CONF_DELETE_ERROR = 'CONF_DELETE_ERROR'
export const CONF_DELETE_LOADING = 'CONF_DELETE_LOADING'
export const CONF_DELETE_SUCCESS = 'CONF_DELETE_SUCCESS'

// URL for UI
export const CONF_URL = {
    list: '/confs',
    view: '/conf',
    edit: '/conf/edit',
    url_param: '/:confId'
}