// URL for UI
export const ADMISSION_COOP_LIST_URL = 'admissionCoops'
export const ADMISSION_COOP_LIST_ERROR = 'ADMISSION_COOP_LIST_ERROR'
export const ADMISSION_COOP_LIST_LOADING = 'ADMISSION_COOP_LIST_LOADING'
export const ADMISSION_COOP_LIST_SUCCESS = 'ADMISSION_COOP_LIST_SUCCESS'

export const ADMISSION_COOP_ADD_URL = 'admissionCoop'
export const ADMISSION_COOP_ADD_ERROR = 'ADMISSION_COOP_ADD_ERROR'
export const ADMISSION_COOP_ADD_LOADING = 'ADMISSION_COOP_ADD_LOADING'
export const ADMISSION_COOP_ADD_SUCCESS = 'ADMISSION_COOP_ADD_SUCCESS'

export const ADMISSION_COOP_DELETE_URL = 'admissionCoop/delete'
export const ADMISSION_COOP_DELETE_ERROR = 'ADMISSION_COOP_DELETE_ERROR'
export const ADMISSION_COOP_DELETE_LOADING = 'ADMISSION_COOP_DELETE_LOADING'
export const ADMISSION_COOP_DELETE_SUCCESS = 'ADMISSION_COOP_DELETE_SUCCESS'