// URL for UI
export const GRADE_LIST_URL = 'grades'
export const GRADE_LIST_ERROR = 'GRADE_LIST_ERROR'
export const GRADE_LIST_LOADING = 'GRADE_LIST_LOADING'
export const GRADE_LIST_SUCCESS = 'GRADE_LIST_SUCCESS'

// URL to get grade by teacher
export const GRADE_TEACHER_LIST_URL = 'teacher_grades'
export const GRADE_TEACHER_LIST_ERROR = 'GRADE_TEACHER_LIST_ERROR'
export const GRADE_TEACHER_LIST_LOADING = 'GRADE_TEACHER_LIST_LOADING'
export const GRADE_TEACHER_LIST_SUCCESS = 'GRADE_TEACHER_LIST_SUCCESS'

export const GRADE_ADD_URL = 'grade'
export const GRADE_ADD_ERROR = 'GRADE_ADD_ERROR'
export const GRADE_ADD_LOADING = 'GRADE_ADD_LOADING'
export const GRADE_ADD_SUCCESS = 'GRADE_ADD_SUCCESS'

export const GRADE_VIEW_URL = 'grade'
export const GRADE_VIEW_ERROR = 'GRADE_VIEW_ERROR'
export const GRADE_VIEW_LOADING = 'GRADE_VIEW_LOADING'
export const GRADE_VIEW_SUCCESS = 'GRADE_VIEW_SUCCESS'

export const GRADE_DELETE_URL = 'grade/delete'
export const GRADE_DELETE_ERROR = 'GRADE_DELETE_ERROR'
export const GRADE_DELETE_LOADING = 'GRADE_DELETE_LOADING'
export const GRADE_DELETE_SUCCESS = 'GRADE_DELETE_SUCCESS'

export const GRADE_ACTIVATE_URL = 'grade/activate'
export const GRADE_ACTIVATE_ERROR = 'GRADE_ACTIVATE_ERROR'
export const GRADE_ACTIVATE_LOADING = 'GRADE_ACTIVATE_LOADING'
export const GRADE_ACTIVATE_SUCCESS = 'GRADE_ACTIVATE_SUCCESS'

// URL for UI
export const GRADE_URL = {
    list: '/grades',
    view: '/grade',
    edit: '/grade/edit',
    url_param: '/:gradeId',
}