// URL for UI
export const MATERIAL_LIST_URL = 'materials'

export const MATERIAL_LIST_ERROR = 'MATERIAL_LIST_ERROR'
export const MATERIAL_LIST_LOADING = 'MATERIAL_LIST_LOADING'
export const MATERIAL_LIST_SUCCESS = 'MATERIAL_LIST_SUCCESS'

export const MATERIAL_ADD_URL = 'material'
export const MATERIAL_ADD_ERROR = 'MATERIAL_ADD_ERROR'
export const MATERIAL_ADD_LOADING = 'MATERIAL_ADD_LOADING'
export const MATERIAL_ADD_SUCCESS = 'MATERIAL_ADD_SUCCESS'

// Material Attachment constant
export const MATERIAL_ATTACH_ADD_URL = 'material/put'
export const MATERIAL_ATTACH_ADD_ERROR = 'MATERIAL_ATTACH_ADD_ERROR'
export const MATERIAL_ATTACH_ADD_LOADING = 'MATERIAL_ATTACH_ADD_LOADING'
export const MATERIAL_ATTACH_ADD_SUCCESS = 'MATERIAL_ATTACH_ADD_SUCCESS'

export const MATERIAL_ATTACH_GET_URL = 'material/get'

export const MATERIAL_VIEW_URL = 'material/view'
export const MATERIAL_VIEW_ERROR = 'MATERIAL_VIEW_ERROR'
export const MATERIAL_VIEW_LOADING = 'MATERIAL_VIEW_LOADING'
export const MATERIAL_VIEW_SUCCESS = 'MATERIAL_VIEW_SUCCESS'

export const MATERIAL_DELETE_URL = 'material/delete'
export const MATERIAL_DELETE_ERROR = 'MATERIAL_DELETE_ERROR'
export const MATERIAL_DELETE_LOADING = 'MATERIAL_DELETE_LOADING'
export const MATERIAL_DELETE_SUCCESS = 'MATERIAL_DELETE_SUCCESS'

export const MATERIAL_IMAGE_ADD_URL = 'material/image'
export const MATERIAL_IMAGE_ADD_ERROR = 'MATERIAL_IMAGE_ADD_ERROR'
export const MATERIAL_IMAGE_ADD_LOADING = 'MATERIAL_IMAGE_ADD_LOADING'
export const MATERIAL_IMAGE_ADD_SUCCESS = 'MATERIAL_IMAGE_ADD_SUCCESS'

// URL for UI
export const MATERIAL_URL = {
    list: '/materials',
    view: '/material',
    edit: '/material/edit',
    url_param: '/:materialId'
}