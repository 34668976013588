import { combineReducers } from 'redux'
import authReducer from 'views/Login/reducers'
import schoolReducer from 'views/School/reducer'
import staffReducer from 'views/Staff/reducer'
import teacherReducer from 'views/Teacher/reducer'
import subjectReducer from 'views/Subject/reducer'
import yearReducer from 'views/Year/reducer'
import studentReducer from 'views/Student/reducer'
import gradeReducer from 'views/Grade/reducer'
import productReducer from 'views/Product/reducer'
import activityReducer from 'views/Activity/reducer'
import accountReducer from 'views/Account/reducer'
import invoiceReducer from 'views/Invoice/reducer'
import billReducer from 'views/Bill/reducer'
import dashboardActivityReducer from 'views/Dashboard/Activity/reducer'
import dashboardPaidReducer from 'views/Dashboard/Paid/reducer'
import dashboardLogReducer from 'views/Dashboard/Log/reducer'
import parentReducer from 'views/Parent/reducer'
import regionReducer from 'views/Region/reducer'
import coopReducer from 'views/Coop/reducer'
import confReducer from 'views/Conf/reducer'
import schoolAccountReducer from 'views/SchoolAccount/reducer'
import userReducer from 'views/User/reducer'
import yearGradeReducer from 'views/YearGrade/reducer'
import yearSubjectReducer from 'views/YearSubject/reducer'
import yearStudentReducer from 'views/YearStudent/reducer'
import yearTeacherReducer from 'views/YearTeacher/reducer'
import messageReducer from 'views/Message/reducer'
import materialReducer from 'views/Material/reducer'
import assignmentReducer from 'views/Assignment/reducer'
import profileReducer from 'views/Profile/reducer'
import responseReducer from 'views/Response/reducer'
import admissionReducer from 'views/Admission/reducer'
import admissionAccountReducer from 'views/AdmissionAccount/reducer'
import admissionCoopReducer from 'views/AdmissionCoop/reducer'
import candidateReducer from 'views/Candidate/reducer'
import batchReducer from 'views/Batch/reducer'
import selectionReducer from 'views/Selection/reducer'
import bankAccountReducer from 'views/BankAccount/reducer'
import attendanceReducer from 'views/Attendance/reducer'
import quizReducer from 'views/Quiz/reducer'

const reducers = combineReducers({
  auth:authReducer,
  schools:schoolReducer,
  staffs:staffReducer,
  teachers:teacherReducer,
  subjects:subjectReducer,
  years:yearReducer,
  students:studentReducer,
  grades:gradeReducer,
  products:productReducer,
  activities:activityReducer,
  accounts:accountReducer,
  invoices:invoiceReducer,
  bills:billReducer,
  dashboardActivities:dashboardActivityReducer,
  dashboardPaids:dashboardPaidReducer,
  dashboardLogs:dashboardLogReducer,
  parents:parentReducer,
  regions:regionReducer,
  coops:coopReducer,
  confs:confReducer,
  schoolAccounts:schoolAccountReducer,
  users:userReducer,
  yearGrades:yearGradeReducer,
  yearSubjects:yearSubjectReducer,
  yearStudents:yearStudentReducer,
  yearTeachers:yearTeacherReducer,
  messages:messageReducer,
  materials:materialReducer,
  assignments:assignmentReducer,
  profiles:profileReducer,
  responses:responseReducer,
  admissions:admissionReducer,
  admissionAccounts:admissionAccountReducer,
  admissionCoops:admissionCoopReducer,
  candidates:candidateReducer,
  batches:batchReducer,
  selections:selectionReducer,
  bankAccounts:bankAccountReducer,
  attendances:attendanceReducer,
  quizs:quizReducer,
})

export default reducers
