import { combineReducers } from 'redux'
import {
  ADMISSION_LIST_ERROR,
  ADMISSION_LIST_LOADING,
  ADMISSION_LIST_SUCCESS,

  ADMISSION_TEACHER_LIST_ERROR,
  ADMISSION_TEACHER_LIST_LOADING,
  ADMISSION_TEACHER_LIST_SUCCESS,

  ADMISSION_ADD_ERROR,
  ADMISSION_ADD_LOADING,
  ADMISSION_ADD_SUCCESS,

  ADMISSION_VIEW_ERROR,
  ADMISSION_VIEW_LOADING,
  ADMISSION_VIEW_SUCCESS,
  
  ADMISSION_DELETE_ERROR,
  ADMISSION_DELETE_LOADING,
  ADMISSION_DELETE_SUCCESS,

  ADMISSION_ACTIVATE_ERROR,
  ADMISSION_ACTIVATE_LOADING,
  ADMISSION_ACTIVATE_SUCCESS,
} from 'views/Admission/constant'

const admissionListError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_LIST_ERROR:
      return action.admissionListError
    default:
      return state
  }
}

const admissionListLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_LIST_LOADING:
      return action.admissionListLoading
    default:
      return state
  }
}

const admissionListSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_LIST_SUCCESS:
      return action.admissionListSuccess
    default:
      return state
  }
}

const admissions = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_LIST_SUCCESS:
      return action.admissions
    default:
      return state
  }
}

const admissionTeacherListError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_TEACHER_LIST_ERROR:
      return action.admissionTeacherListError
    default:
      return state
  }
}

const admissionTeacherListLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_TEACHER_LIST_LOADING:
      return action.admissionTeacherListLoading
    default:
      return state
  }
}

const admissionTeacherListSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_TEACHER_LIST_SUCCESS:
      return action.admissionTeacherListSuccess
    default:
      return state
  }
}

const teacheradmissions = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_TEACHER_LIST_SUCCESS:
      return action.admissions
    default:
      return state
  }
}

const admissionAddError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ADD_ERROR:
      return action.admissionAddError
    default:
      return state
  }
}

const admissionAddLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ADD_LOADING:
      return action.admissionAddLoading
    default:
      return state
  }
}

const admissionAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ADD_SUCCESS:
      return action.admissionAddSuccess
    default:
      return state
  }
}

const admissionAdded = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_ADD_SUCCESS:
      return action.admission
    default:
      return state
  }
}

const admissionViewError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_VIEW_ERROR:
      return action.admissionViewError
    default:
      return state
  }
}

const admissionViewLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_VIEW_LOADING:
      return action.admissionViewLoading
    default:
      return state
  }
}

const admissionViewSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_VIEW_SUCCESS:
      return action.admissionViewSuccess
    default:
      return state
  }
}

const admission = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_VIEW_SUCCESS:
      return action.admission
    default:
      return state
  }
}

const admissionDeleteError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_DELETE_ERROR:
      return action.admissionDeleteError
    default:
      return state
  }
}

const admissionDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_DELETE_LOADING:
      return action.admissionDeleteLoading
    default:
      return state
  }
}

const admissionDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_DELETE_SUCCESS:
      return action.admissionDeleteSuccess
    default:
      return state
  }
}

const admissionDeleted = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_DELETE_SUCCESS:
      return action.admission
    default:
      return state
  }
}

const admissionActivateError = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACTIVATE_ERROR:
      return action.admissionActivateError
    default:
      return state
  }
}

const admissionActivateLoading = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACTIVATE_LOADING:
      return action.admissionActivateLoading
    default:
      return state
  }
}

const admissionActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case ADMISSION_ACTIVATE_SUCCESS:
      return action.admissionActivateSuccess
    default:
      return state
  }
}

const admissionActivated = (state = [], action) => {
  switch (action.type) {
    case ADMISSION_ACTIVATE_SUCCESS:
      return action.admission
    default:
      return state
  }
}

const admissionReducer = combineReducers({
  admissionListError,
  admissionListLoading,
  admissionListSuccess,
  admissions,

  admissionTeacherListError,
  admissionTeacherListLoading,
  admissionTeacherListSuccess,
  teacheradmissions,

  admissionAddError,
  admissionAddLoading,
  admissionAddSuccess,
  admissionAdded,

  admissionViewError,
  admissionViewLoading,
  admissionViewSuccess,
  admission,

  admissionDeleteError,
  admissionDeleteLoading,
  admissionDeleteSuccess,
  admissionDeleted,

  admissionActivateError,
  admissionActivateLoading,
  admissionActivateSuccess,
  admissionActivated,
})

export default admissionReducer
