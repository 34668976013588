import { combineReducers } from 'redux'
import {
  QUIZ_LIST_ERROR,
  QUIZ_LIST_LOADING,
  QUIZ_LIST_SUCCESS,

  QUIZ_ADD_ERROR,
  QUIZ_ADD_LOADING,
  QUIZ_ADD_SUCCESS,

  QUIZ_VIEW_ERROR,
  QUIZ_VIEW_LOADING,
  QUIZ_VIEW_SUCCESS,

  QUIZ_STUDENT_VIEW_ERROR,
  QUIZ_STUDENT_VIEW_LOADING,
  QUIZ_STUDENT_VIEW_SUCCESS,

  QUIZ_DELETE_ERROR,
  QUIZ_DELETE_LOADING,
  QUIZ_DELETE_SUCCESS,

  QUIZ_QUESTION_LIST_ERROR,
  QUIZ_QUESTION_LIST_LOADING,
  QUIZ_QUESTION_LIST_SUCCESS,

  QUIZ_QUESTION_ADD_ERROR,
  QUIZ_QUESTION_ADD_LOADING,
  QUIZ_QUESTION_ADD_SUCCESS,

  QUIZ_ANSWER_ADD_ERROR,
  QUIZ_ANSWER_ADD_LOADING,
  QUIZ_ANSWER_ADD_SUCCESS,

  QUIZ_STUDENT_ANSWER_LIST_ERROR,
  QUIZ_STUDENT_ANSWER_LIST_LOADING,
  QUIZ_STUDENT_ANSWER_LIST_SUCCESS,

  QUIZ_CORRECTION_ADD_ERROR,
  QUIZ_CORRECTION_ADD_LOADING,
  QUIZ_CORRECTION_ADD_SUCCESS,
} from 'views/Quiz/constant'

const quizListError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_LIST_ERROR:
      return action.quizListError
    default:
      return state
  }
}

const quizListLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_LIST_LOADING:
      return action.quizListLoading
    default:
      return state
  }
}

const quizListSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_LIST_SUCCESS:
      return action.quizListSuccess
    default:
      return state
  }
}

const quizs = (state = [], action) => {
  switch (action.type) {
    case QUIZ_LIST_SUCCESS:
      return action.quizs
    default:
      return state
  }
}

const quizAddError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_ADD_ERROR:
      return action.quizAddError
    default:
      return state
  }
}

const quizAddLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_ADD_LOADING:
      return action.quizAddLoading
    default:
      return state
  }
}

const quizAddSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_ADD_SUCCESS:
      return action.quizAddSuccess
    default:
      return state
  }
}

const quizAdded = (state = [], action) => {
  switch (action.type) {
    case QUIZ_ADD_SUCCESS:
      return action.quiz
    default:
      return state
  }
}

const quizViewError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_VIEW_ERROR:
      return action.quizViewError
    default:
      return state
  }
}

const quizViewLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_VIEW_LOADING:
      return action.quizViewLoading
    default:
      return state
  }
}

const quizViewSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_VIEW_SUCCESS:
      return action.quizViewSuccess
    default:
      return state
  }
}

const quiz = (state = [], action) => {
  switch (action.type) {
    case QUIZ_VIEW_SUCCESS:
      return action.quiz
    default:
      return state
  }
}

const quizStudentViewError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_STUDENT_VIEW_ERROR:
      return action.quizStudentViewError
    default:
      return state
  }
}

const quizStudentViewLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_STUDENT_VIEW_LOADING:
      return action.quizStudentViewLoading
    default:
      return state
  }
}

const quizStudentViewSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_STUDENT_VIEW_SUCCESS:
      return action.quizStudentViewSuccess
    default:
      return state
  }
}

const quizStudent = (state = [], action) => {
  switch (action.type) {
    case QUIZ_STUDENT_VIEW_SUCCESS:
      return action.quiz
    default:
      return state
  }
}

const quizDeleteError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_DELETE_ERROR:
      return action.quizDeleteError
    default:
      return state
  }
}

const quizDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_DELETE_LOADING:
      return action.quizDeleteLoading
    default:
      return state
  }
}

const quizDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_DELETE_SUCCESS:
      return action.quizDeleteSuccess
    default:
      return state
  }
}

const quizDeleted = (state = [], action) => {
  switch (action.type) {
    case QUIZ_DELETE_SUCCESS:
      return action.quiz
    default:
      return state
  }
}

const quizQuestionListError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_QUESTION_LIST_ERROR:
      return action.quizQuestionListError
    default:
      return state
  }
}

const quizQuestionListLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_QUESTION_LIST_LOADING:
      return action.quizQuestionListLoading
    default:
      return state
  }
}

const quizQuestionListSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_QUESTION_LIST_SUCCESS:
      return action.quizQuestionListSuccess
    default:
      return state
  }
}

const quizQuestions = (state = [], action) => {
  switch (action.type) {
    case QUIZ_QUESTION_LIST_SUCCESS:
      return action.quizQuestions
    default:
      return state
  }
}

const quizQuestionAddError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_QUESTION_ADD_ERROR:
      return action.quizQuestionAddError
    default:
      return state
  }
}

const quizQuestionAddLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_QUESTION_ADD_LOADING:
      return action.quizQuestionAddLoading
    default:
      return state
  }
}

const quizQuestionAddSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_QUESTION_ADD_SUCCESS:
      return action.quizQuestionAddSuccess
    default:
      return state
  }
}

const quizQuestionAdded = (state = [], action) => {
  switch (action.type) {
    case QUIZ_QUESTION_ADD_SUCCESS:
      return action.quizQuestion
    default:
      return state
  }
}

const studentAnswerAddError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_ANSWER_ADD_ERROR:
      return action.studentAnswerAddError
    default:
      return state
  }
}

const studentAnswerAddLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_ANSWER_ADD_LOADING:
      return action.studentAnswerAddLoading
    default:
      return state
  }
}

const studentAnswerAddSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_ANSWER_ADD_SUCCESS:
      return action.studentAnswerAddSuccess
    default:
      return state
  }
}

const studentAnswerAdded = (state = [], action) => {
  switch (action.type) {
    case QUIZ_ANSWER_ADD_SUCCESS:
      return action.studentAnswer
    default:
      return state
  }
}

const studentAnswerListError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_STUDENT_ANSWER_LIST_ERROR:
      return action.studentAnswerListError
    default:
      return state
  }
}

const studentAnswerListLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_STUDENT_ANSWER_LIST_LOADING:
      return action.studentAnswerListLoading
    default:
      return state
  }
}

const studentAnswerListSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_STUDENT_ANSWER_LIST_SUCCESS:
      return action.studentAnswerListSuccess
    default:
      return state
  }
}

const studentAnswers = (state = [], action) => {
  switch (action.type) {
    case QUIZ_STUDENT_ANSWER_LIST_SUCCESS:
      return action.studentAnswers
    default:
      return state
  }
}

const quizCorrectionAddError = (state = false, action) => {
  switch (action.type) {
    case QUIZ_CORRECTION_ADD_ERROR:
      return action.quizCorrectionAddError
    default:
      return state
  }
}

const quizCorrectionAddLoading = (state = false, action) => {
  switch (action.type) {
    case QUIZ_CORRECTION_ADD_LOADING:
      return action.quizCorrectionAddLoading
    default:
      return state
  }
}

const quizCorrectionAddSuccess = (state = false, action) => {
  switch (action.type) {
    case QUIZ_CORRECTION_ADD_SUCCESS:
      return action.quizCorrectionAddSuccess
    default:
      return state
  }
}

const quizCorrectionAdded = (state = [], action) => {
  switch (action.type) {
    case QUIZ_CORRECTION_ADD_SUCCESS:
      return action.quizCorrection
    default:
      return state
  }
}
const quizReducer = combineReducers({
  quizListError,
  quizListLoading,
  quizListSuccess,
  quizs,

  quizAddError,
  quizAddLoading,
  quizAddSuccess,
  quizAdded,

  quizViewError,
  quizViewLoading,
  quizViewSuccess,
  quiz,

  quizStudentViewError,
  quizStudentViewLoading,
  quizStudentViewSuccess,
  quizStudent,

  quizDeleteError,
  quizDeleteLoading,
  quizDeleteSuccess,
  quizDeleted,

  quizQuestionListError,
  quizQuestionListLoading,
  quizQuestionListSuccess,
  quizQuestions,

  quizQuestionAddError,
  quizQuestionAddLoading,
  quizQuestionAddSuccess,
  quizQuestionAdded,

  studentAnswerAddError,
  studentAnswerAddLoading,
  studentAnswerAddSuccess,
  studentAnswerAdded,

  studentAnswerListError,
  studentAnswerListLoading,
  studentAnswerListSuccess,
  studentAnswers,

  quizCorrectionAddError,
  quizCorrectionAddLoading,
  quizCorrectionAddSuccess,
  quizCorrectionAdded,
})

export default quizReducer
