import { combineReducers } from 'redux'
import {
  DASHBOARD_LOG_LIST_ERROR,
  DASHBOARD_LOG_LIST_LOADING,
  DASHBOARD_LOG_LIST_SUCCESS,
} from 'views/Dashboard/Log/constant'

const dashboardLogListError = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_LOG_LIST_ERROR:
      return action.dashboardLogListError
    default:
      return state
  }
}

const dashboardLogListLoading = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_LOG_LIST_LOADING:
      return action.dashboardLogListLoading
    default:
      return state
  }
}

const dashboardLogListSuccess = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_LOG_LIST_SUCCESS:
      return action.dashboardLogListSuccess
    default:
      return state
  }
}

const dashboardLogs = (state = [], action) => {
  switch (action.type) {
    case DASHBOARD_LOG_LIST_SUCCESS:
      return action.dashboardLogs
    default:
      return state
  }
}


const dashboardLogReducer = combineReducers({
  dashboardLogListError,
  dashboardLogListLoading,
  dashboardLogListSuccess,
  dashboardLogs,
})

export default dashboardLogReducer
