// URL for API
export const CANDIDATE_LIST_URL = 'candidates'
export const CANDIDATE_LIST_ERROR = 'CANDIDATE_LIST_ERROR'
export const CANDIDATE_LIST_LOADING = 'CANDIDATE_LIST_LOADING'
export const CANDIDATE_LIST_SUCCESS = 'CANDIDATE_LIST_SUCCESS'

export const CANDIDATE_ADD_URL = 'candidate'
export const CANDIDATE_ADD_ERROR = 'CANDIDATE_ADD_ERROR'
export const CANDIDATE_ADD_LOADING = 'CANDIDATE_ADD_LOADING'
export const CANDIDATE_ADD_SUCCESS = 'CANDIDATE_ADD_SUCCESS'

export const CANDIDATE_VIEW_URL = 'candidate'
export const CANDIDATE_VIEW_ERROR = 'CANDIDATE_VIEW_ERROR'
export const CANDIDATE_VIEW_LOADING = 'CANDIDATE_VIEW_LOADING'
export const CANDIDATE_VIEW_SUCCESS = 'CANDIDATE_VIEW_SUCCESS'

export const CANDIDATE_DELETE_URL = 'candidate/delete'
export const CANDIDATE_DELETE_ERROR = 'CANDIDATE_DELETE_ERROR'
export const CANDIDATE_DELETE_LOADING = 'CANDIDATE_DELETE_LOADING'
export const CANDIDATE_DELETE_SUCCESS = 'CANDIDATE_DELETE_SUCCESS'

export const CANDIDATE_PAYMENT_URL = 'candidate/payment'
export const CANDIDATE_PAYMENT_ERROR = 'CANDIDATE_PAYMENT_ERROR'
export const CANDIDATE_PAYMENT_LOADING = 'CANDIDATE_PAYMENT_LOADING'
export const CANDIDATE_PAYMENT_SUCCESS = 'CANDIDATE_PAYMENT_SUCCESS'

export const CANDIDATE_CONFIRM_URL = 'candidate/confirm'
export const CANDIDATE_CONFIRM_ERROR = 'CANDIDATE_CONFIRM_ERROR'
export const CANDIDATE_CONFIRM_LOADING = 'CANDIDATE_CONFIRM_LOADING'
export const CANDIDATE_CONFIRM_SUCCESS = 'CANDIDATE_CONFIRM_SUCCESS'

export const CANDIDATE_MANUAL_PAYMENT_URL = 'candidate/manual_payment'
export const CANDIDATE_MANUAL_PAYMENT_ERROR = 'CANDIDATE_MANUAL_PAYMENT_ERROR'
export const CANDIDATE_MANUAL_PAYMENT_LOADING = 'CANDIDATE_MANUAL_PAYMENT_LOADING'
export const CANDIDATE_MANUAL_PAYMENT_SUCCESS = 'CANDIDATE_MANUAL_PAYMENT_SUCCESS'

export const CANDIDATE_REJECT_URL = 'candidate/reject'
export const CANDIDATE_REJECT_ERROR = 'CANDIDATE_REJECT_ERROR'
export const CANDIDATE_REJECT_LOADING = 'CANDIDATE_REJECT_LOADING'
export const CANDIDATE_REJECT_SUCCESS = 'CANDIDATE_REJECT_SUCCESS'

export const CANDIDATE_SCHEDULE_LIST_URL = 'candidate_schedule'
export const CANDIDATE_SCHEDULE_LIST_ERROR = 'CANDIDATE_SCHEDULE_LIST_ERROR'
export const CANDIDATE_SCHEDULE_LIST_LOADING = 'CANDIDATE_SCHEDULE_LIST_LOADING'
export const CANDIDATE_SCHEDULE_LIST_SUCCESS = 'CANDIDATE_SCHEDULE_LIST_SUCCESS'

// URL for UI
export const CANDIDATE_URL = {
    list: '/candidates',
    view: '/candidate',
    edit: '/candidate/edit',
    url_param: '/:candidateId'
}