// URL for UI
export const ADMISSION_ACCOUNT_LIST_URL = 'admissionAccounts'
export const ADMISSION_ACCOUNT_LIST_ERROR = 'ADMISSION_ACCOUNT_LIST_ERROR'
export const ADMISSION_ACCOUNT_LIST_LOADING = 'ADMISSION_ACCOUNT_LIST_LOADING'
export const ADMISSION_ACCOUNT_LIST_SUCCESS = 'ADMISSION_ACCOUNT_LIST_SUCCESS'

export const ADMISSION_ACCOUNT_ADD_URL = 'admissionAccount'
export const ADMISSION_ACCOUNT_ADD_ERROR = 'ADMISSION_ACCOUNT_ADD_ERROR'
export const ADMISSION_ACCOUNT_ADD_LOADING = 'ADMISSION_ACCOUNT_ADD_LOADING'
export const ADMISSION_ACCOUNT_ADD_SUCCESS = 'ADMISSION_ACCOUNT_ADD_SUCCESS'

export const ADMISSION_ACCOUNT_DELETE_URL = 'admissionAccount/delete'
export const ADMISSION_ACCOUNT_DELETE_ERROR = 'ADMISSION_ACCOUNT_DELETE_ERROR'
export const ADMISSION_ACCOUNT_DELETE_LOADING = 'ADMISSION_ACCOUNT_DELETE_LOADING'
export const ADMISSION_ACCOUNT_DELETE_SUCCESS = 'ADMISSION_ACCOUNT_DELETE_SUCCESS'