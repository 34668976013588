// URL for UI
export const RESPONSE_LIST_URL = 'responses'

export const RESPONSE_LIST_ERROR = 'RESPONSE_LIST_ERROR'
export const RESPONSE_LIST_LOADING = 'RESPONSE_LIST_LOADING'
export const RESPONSE_LIST_SUCCESS = 'RESPONSE_LIST_SUCCESS'

export const RESPONSE_ADD_URL = 'response'
export const RESPONSE_ADD_ERROR = 'RESPONSE_ADD_ERROR'
export const RESPONSE_ADD_LOADING = 'RESPONSE_ADD_LOADING'
export const RESPONSE_ADD_SUCCESS = 'RESPONSE_ADD_SUCCESS'

export const RESPONSE_VIEW_URL = 'response/view'
export const RESPONSE_VIEW_ERROR = 'RESPONSE_VIEW_ERROR'
export const RESPONSE_VIEW_LOADING = 'RESPONSE_VIEW_LOADING'
export const RESPONSE_VIEW_SUCCESS = 'RESPONSE_VIEW_SUCCESS'

export const RESPONSE_DELETE_URL = 'response/delete'
export const RESPONSE_DELETE_ERROR = 'RESPONSE_DELETE_ERROR'
export const RESPONSE_DELETE_LOADING = 'RESPONSE_DELETE_LOADING'
export const RESPONSE_DELETE_SUCCESS = 'RESPONSE_DELETE_SUCCESS'

// Material Attachment constant
export const RESPONSE_ATTACH_ADD_URL = 'response/put'
export const RESPONSE_ATTACH_ADD_ERROR = 'RESPONSE_ATTACH_ADD_ERROR'
export const RESPONSE_ATTACH_ADD_LOADING = 'RESPONSE_ATTACH_ADD_LOADING'
export const RESPONSE_ATTACH_ADD_SUCCESS = 'RESPONSE_ATTACH_ADD_SUCCESS'

export const RESPONSE_GRADING_URL = 'response/grading'
export const RESPONSE_GRADING_ERROR = 'RESPONSE_GRADING_ERROR'
export const RESPONSE_GRADING_LOADING = 'RESPONSE_GRADING_LOADING'
export const RESPONSE_GRADING_SUCCESS = 'RESPONSE_GRADING_SUCCESS'

export const RESPONSE_ATTACH_GET_URL = 'response/get'

// URL for UI
export const RESPONSE_URL = {
    list: '/responses',
    view: '/response',
    edit: '/response/edit',
    url_param: '/:responseId'
}