// URL for UI
export const ATTENDANCE_LIST_URL = 'attendances'
export const ATTENDANCE_LIST_ERROR = 'ATTENDANCE_LIST_ERROR'
export const ATTENDANCE_LIST_LOADING = 'ATTENDANCE_LIST_LOADING'
export const ATTENDANCE_LIST_SUCCESS = 'ATTENDANCE_LIST_SUCCESS'

export const ATTENDANCE_ADD_URL = 'attendance'
export const ATTENDANCE_ADD_ERROR = 'ATTENDANCE_ADD_ERROR'
export const ATTENDANCE_ADD_LOADING = 'ATTENDANCE_ADD_LOADING'
export const ATTENDANCE_ADD_SUCCESS = 'ATTENDANCE_ADD_SUCCESS'

export const ATTENDANCE_VIEW_URL = 'attendance'
export const ATTENDANCE_VIEW_ERROR = 'ATTENDANCE_VIEW_ERROR'
export const ATTENDANCE_VIEW_LOADING = 'ATTENDANCE_VIEW_LOADING'
export const ATTENDANCE_VIEW_SUCCESS = 'ATTENDANCE_VIEW_SUCCESS'

export const ATTENDANCE_DELETE_URL = 'attendance/delete'
export const ATTENDANCE_DELETE_ERROR = 'ATTENDANCE_DELETE_ERROR'
export const ATTENDANCE_DELETE_LOADING = 'ATTENDANCE_DELETE_LOADING'
export const ATTENDANCE_DELETE_SUCCESS = 'ATTENDANCE_DELETE_SUCCESS'

export const ATTENDANCE_STUDENT_LIST_URL = 'attendance_students'
export const ATTENDANCE_STUDENT_LIST_ERROR = 'ATTENDANCE_STUDENT_LIST_ERROR'
export const ATTENDANCE_STUDENT_LIST_LOADING = 'ATTENDANCE_STUDENT_LIST_LOADING'
export const ATTENDANCE_STUDENT_LIST_SUCCESS = 'ATTENDANCE_STUDENT_LIST_SUCCESS'

export const ATTENDANCE_STUDENT_PRESENCE_URL = 'attendance_students'
export const ATTENDANCE_STUDENT_PRESENCE_ERROR = 'ATTENDANCE_STUDENT_PRESENCE_ERROR'
export const ATTENDANCE_STUDENT_PRESENCE_LOADING = 'ATTENDANCE_STUDENT_PRESENCE_LOADING'
export const ATTENDANCE_STUDENT_PRESENCE_SUCCESS = 'ATTENDANCE_STUDENT_PRESENCE_SUCCESS'

export const ATTENDANCE_RECAP_LIST_URL = 'attendance_recap'
export const ATTENDANCE_RECAP_LIST_ERROR = 'ATTENDANCE_RECAP_LIST_ERROR'
export const ATTENDANCE_RECAP_LIST_LOADING = 'ATTENDANCE_RECAP_LIST_LOADING'
export const ATTENDANCE_RECAP_LIST_SUCCESS = 'ATTENDANCE_RECAP_LIST_SUCCESS'

export const ATTENDANCE_STUDENT_ADD_URL = 'attendance_students'
export const ATTENDANCE_STUDENT_ADD_ERROR = 'ATTENDANCE_STUDENT_ADD_ERROR'
export const ATTENDANCE_STUDENT_ADD_LOADING = 'ATTENDANCE_STUDENT_ADD_LOADING'
export const ATTENDANCE_STUDENT_ADD_SUCCESS = 'ATTENDANCE_STUDENT_ADD_SUCCESS'
// URL for UI
export const ATTENDANCE_URL = {
    recap: '/attendance_recap',
    list: '/attendances',
    view: '/attendance',
    edit: '/attendance/edit',
    url_param: '/:attendanceId',
}