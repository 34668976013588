import { combineReducers } from 'redux'
import {
  USER_LIST_ERROR,
  USER_LIST_LOADING,
  USER_LIST_SUCCESS,

  USER_ADD_ERROR,
  USER_ADD_LOADING,
  USER_ADD_SUCCESS,

  USER_VIEW_ERROR,
  USER_VIEW_LOADING,
  USER_VIEW_SUCCESS,
  
  USER_DELETE_ERROR,
  USER_DELETE_LOADING,
  USER_DELETE_SUCCESS,
} from 'views/User/constant'

const userListError = (state = false, action) => {
  switch (action.type) {
    case USER_LIST_ERROR:
      return action.userListError
    default:
      return state
  }
}

const userListLoading = (state = false, action) => {
  switch (action.type) {
    case USER_LIST_LOADING:
      return action.userListLoading
    default:
      return state
  }
}

const userListSuccess = (state = false, action) => {
  switch (action.type) {
    case USER_LIST_SUCCESS:
      return action.userListSuccess
    default:
      return state
  }
}

const users = (state = [], action) => {
  switch (action.type) {
    case USER_LIST_SUCCESS:
      return action.users
    default:
      return state
  }
}

const userAddError = (state = false, action) => {
  switch (action.type) {
    case USER_ADD_ERROR:
      return action.userAddError
    default:
      return state
  }
}

const userAddLoading = (state = false, action) => {
  switch (action.type) {
    case USER_ADD_LOADING:
      return action.userAddLoading
    default:
      return state
  }
}

const userAddSuccess = (state = false, action) => {
  switch (action.type) {
    case USER_ADD_SUCCESS:
      return action.userAddSuccess
    default:
      return state
  }
}

const userAdded = (state = [], action) => {
  switch (action.type) {
    case USER_ADD_SUCCESS:
      return action.user
    default:
      return state
  }
}

const userViewError = (state = false, action) => {
  switch (action.type) {
    case USER_VIEW_ERROR:
      return action.userViewError
    default:
      return state
  }
}

const userViewLoading = (state = false, action) => {
  switch (action.type) {
    case USER_VIEW_LOADING:
      return action.userViewLoading
    default:
      return state
  }
}

const userViewSuccess = (state = false, action) => {
  switch (action.type) {
    case USER_VIEW_SUCCESS:
      return action.userViewSuccess
    default:
      return state
  }
}

const user = (state = [], action) => {
  switch (action.type) {
    case USER_VIEW_SUCCESS:
      return action.user
    default:
      return state
  }
}

const userDeleteError = (state = false, action) => {
  switch (action.type) {
    case USER_DELETE_ERROR:
      return action.userDeleteError
    default:
      return state
  }
}

const userDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case USER_DELETE_LOADING:
      return action.userDeleteLoading
    default:
      return state
  }
}

const userDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case USER_DELETE_SUCCESS:
      return action.userDeleteSuccess
    default:
      return state
  }
}

const userDeleted = (state = [], action) => {
  switch (action.type) {
    case USER_DELETE_SUCCESS:
      return action.user
    default:
      return state
  }
}


const userReducer = combineReducers({
  userListError,
  userListLoading,
  userListSuccess,
  users,

  userAddError,
  userAddLoading,
  userAddSuccess,
  userAdded,

  userViewError,
  userViewLoading,
  userViewSuccess,
  user,

  userDeleteError,
  userDeleteLoading,
  userDeleteSuccess,
  userDeleted,
})

export default userReducer
