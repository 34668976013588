import React from 'react'
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router'
import { USERTYPE_KEY, userType } from 'constant';


class PrivateRoute extends React.Component {
  render() {
    if (!this.props.isAuthenticated) {
      let utype = localStorage.getItem(USERTYPE_KEY) || null
      switch (utype) {
        case userType.user:
          return (
            <Redirect to="/auth/user" />
          )

        case userType.staff:
          return (
            <Redirect to="/auth/login" />
          )

        case userType.teacher:
          return (
            <Redirect to="/auth/login" />
          )

        case userType.parent:
          return (
            <Redirect to="/auth/parent" />
          )

        case userType.student:
          return (
            <Redirect to="/auth/student" />
          )

        default:
          return (
            <Redirect to="/landing" />
          )
      }
    } else {
      return (
        <Route {...this.props} />
      )
    }
  }
}

export default PrivateRoute