import { combineReducers } from 'redux'
import {
  ACCOUNT_LIST_ERROR,
  ACCOUNT_LIST_LOADING,
  ACCOUNT_LIST_SUCCESS,

  ACCOUNT_ADD_ERROR,
  ACCOUNT_ADD_LOADING,
  ACCOUNT_ADD_SUCCESS,

  ACCOUNT_VIEW_ERROR,
  ACCOUNT_VIEW_LOADING,
  ACCOUNT_VIEW_SUCCESS,

  ACCOUNT_DELETE_ERROR,
  ACCOUNT_DELETE_LOADING,
  ACCOUNT_DELETE_SUCCESS,

  ACCOUNT_BULK_ERROR,
  ACCOUNT_BULK_LOADING,
  ACCOUNT_BULK_SUCCESS,

  ACCOUNT_ADD_MULTI_ERROR,
  ACCOUNT_ADD_MULTI_LOADING,
  ACCOUNT_ADD_MULTI_SUCCESS,

  ACCOUNT_TOGGLE_ERROR,
  ACCOUNT_TOGGLE_LOADING,
  ACCOUNT_TOGGLE_SUCCESS,
} from 'views/Account/constant'

const accountListError = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_ERROR:
      return action.accountListError
    default:
      return state
  }
}

const accountListLoading = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_LOADING:
      return action.accountListLoading
    default:
      return state
  }
}

const accountListSuccess = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_SUCCESS:
      return action.accountListSuccess
    default:
      return state
  }
}

const accounts = (state = [], action) => {
  switch (action.type) {
    case ACCOUNT_LIST_SUCCESS:
      return action.accounts
    default:
      return state
  }
}

const accountAddError = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_ADD_ERROR:
      return action.accountAddError
    default:
      return state
  }
}

const accountAddLoading = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_ADD_LOADING:
      return action.accountAddLoading
    default:
      return state
  }
}

const accountAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_ADD_SUCCESS:
      return action.accountAddSuccess
    default:
      return state
  }
}

const accountAdded = (state = [], action) => {
  switch (action.type) {
    case ACCOUNT_ADD_SUCCESS:
      return action.account
    default:
      return state
  }
}

const accountViewError = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_VIEW_ERROR:
      return action.accountViewError
    default:
      return state
  }
}

const accountViewLoading = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_VIEW_LOADING:
      return action.accountViewLoading
    default:
      return state
  }
}

const accountViewSuccess = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_VIEW_SUCCESS:
      return action.accountViewSuccess
    default:
      return state
  }
}

const account = (state = [], action) => {
  switch (action.type) {
    case ACCOUNT_VIEW_SUCCESS:
      return action.account
    default:
      return state
  }
}

const accountDeleteError = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_DELETE_ERROR:
      return action.accountDeleteError
    default:
      return state
  }
}

const accountDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_DELETE_LOADING:
      return action.accountDeleteLoading
    default:
      return state
  }
}

const accountDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_DELETE_SUCCESS:
      return action.accountDeleteSuccess
    default:
      return state
  }
}

const accountDeleted = (state = [], action) => {
  switch (action.type) {
    case ACCOUNT_DELETE_SUCCESS:
      return action.account
    default:
      return state
  }
}

const accountBulkError = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_BULK_ERROR:
      return action.accountBulkError
    default:
      return state
  }
}

const accountBulkLoading = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_BULK_LOADING:
      return action.accountBulkLoading
    default:
      return state
  }
}

const accountBulkSuccess = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_BULK_SUCCESS:
      return action.accountBulkSuccess
    default:
      return state
  }
}

const accountBulked = (state = [], action) => {
  switch (action.type) {
    case ACCOUNT_BULK_SUCCESS:
      return action.account
    default:
      return state
  }
}

const accountAddMultiError = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_ADD_MULTI_ERROR:
      return action.accountAddMultiError
    default:
      return state
  }
}

const accountAddMultiLoading = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_ADD_MULTI_LOADING:
      return action.accountAddMultiLoading
    default:
      return state
  }
}

const accountAddMultiSuccess = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_ADD_MULTI_SUCCESS:
      return action.accountAddMultiSuccess
    default:
      return state
  }
}

const accountToggleError = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_TOGGLE_ERROR:
      return action.accountToggleError
    default:
      return state
  }
}

const accountToggleLoading = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_TOGGLE_LOADING:
      return action.accountToggleLoading
    default:
      return state
  }
}

const accountToggleSuccess = (state = false, action) => {
  switch (action.type) {
    case ACCOUNT_TOGGLE_SUCCESS:
      return action.accountToggleSuccess
    default:
      return state
  }
}


const accountReducer = combineReducers({
  accountListError,
  accountListLoading,
  accountListSuccess,
  accounts,

  accountAddError,
  accountAddLoading,
  accountAddSuccess,
  accountAdded,

  accountViewError,
  accountViewLoading,
  accountViewSuccess,
  account,

  accountDeleteError,
  accountDeleteLoading,
  accountDeleteSuccess,
  accountDeleted,

  accountBulkError,
  accountBulkLoading,
  accountBulkSuccess,
  accountBulked,
  
  accountAddMultiError,
  accountAddMultiLoading,
  accountAddMultiSuccess,

  accountToggleError,
  accountToggleLoading,
  accountToggleSuccess,
})

export default accountReducer
