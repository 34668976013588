import { combineReducers } from 'redux'
import {
  MATERIAL_LIST_ERROR,
  MATERIAL_LIST_LOADING,
  MATERIAL_LIST_SUCCESS,

  MATERIAL_ADD_ERROR,
  MATERIAL_ADD_LOADING,
  MATERIAL_ADD_SUCCESS,

  MATERIAL_ATTACH_ADD_ERROR,
  MATERIAL_ATTACH_ADD_LOADING,
  MATERIAL_ATTACH_ADD_SUCCESS,

  MATERIAL_VIEW_ERROR,
  MATERIAL_VIEW_LOADING,
  MATERIAL_VIEW_SUCCESS,

  MATERIAL_DELETE_ERROR,
  MATERIAL_DELETE_LOADING,
  MATERIAL_DELETE_SUCCESS,

  MATERIAL_IMAGE_ADD_ERROR,
  MATERIAL_IMAGE_ADD_LOADING,
  MATERIAL_IMAGE_ADD_SUCCESS,
} from 'views/Material/constant'

const materialListError = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_LIST_ERROR:
      return action.materialListError
    default:
      return state
  }
}

const materialListLoading = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_LIST_LOADING:
      return action.materialListLoading
    default:
      return state
  }
}

const materialListSuccess = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_LIST_SUCCESS:
      return action.materialListSuccess
    default:
      return state
  }
}

const materials = (state = [], action) => {
  switch (action.type) {
    case MATERIAL_LIST_SUCCESS:
      return action.materials
    default:
      return state
  }
}

const materialAddError = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_ADD_ERROR:
      return action.materialAddError
    default:
      return state
  }
}

const materialAddLoading = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_ADD_LOADING:
      return action.materialAddLoading
    default:
      return state
  }
}

const materialAddSuccess = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_ADD_SUCCESS:
      return action.materialAddSuccess
    default:
      return state
  }
}

const materialAdded = (state = [], action) => {
  switch (action.type) {
    case MATERIAL_ADD_SUCCESS:
      return action.material
    default:
      return state
  }
}

const materialAttachAddError = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_ATTACH_ADD_ERROR:
      return action.materialAttachAddError
    default:
      return state
  }
}

const materialAttachAddLoading = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_ATTACH_ADD_LOADING:
      return action.materialAttachAddLoading
    default:
      return state
  }
}

const materialAttachAddSuccess = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_ATTACH_ADD_SUCCESS:
      return action.materialAttachAddSuccess
    default:
      return state
  }
}

const materialAttachAdded = (state = [], action) => {
  switch (action.type) {
    case MATERIAL_ATTACH_ADD_SUCCESS:
      return action.materialAdded
    default:
      return state
  }
}

const materialViewError = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_VIEW_ERROR:
      return action.materialViewError
    default:
      return state
  }
}

const materialViewLoading = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_VIEW_LOADING:
      return action.materialViewLoading
    default:
      return state
  }
}

const materialViewSuccess = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_VIEW_SUCCESS:
      return action.materialViewSuccess
    default:
      return state
  }
}

const material = (state = [], action) => {
  switch (action.type) {
    case MATERIAL_VIEW_SUCCESS:
      return action.material
    default:
      return state
  }
}

const materialDeleteError = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_DELETE_ERROR:
      return action.materialDeleteError
    default:
      return state
  }
}

const materialDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_DELETE_LOADING:
      return action.materialDeleteLoading
    default:
      return state
  }
}

const materialDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_DELETE_SUCCESS:
      return action.materialDeleteSuccess
    default:
      return state
  }
}

const materialDeleted = (state = [], action) => {
  switch (action.type) {
    case MATERIAL_DELETE_SUCCESS:
      return action.material
    default:
      return state
  }
}

const materialImageAddError = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_IMAGE_ADD_ERROR:
      return action.materialImageAddError
    default:
      return state
  }
}

const materialImageAddLoading = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_IMAGE_ADD_LOADING:
      return action.materialImageAddLoading
    default:
      return state
  }
}

const materialImageAddSuccess = (state = false, action) => {
  switch (action.type) {
    case MATERIAL_IMAGE_ADD_SUCCESS:
      return action.materialImageAddSuccess
    default:
      return state
  }
}

const materialImageAdded = (state = [], action) => {
  switch (action.type) {
    case MATERIAL_IMAGE_ADD_SUCCESS:
      return action.materialAdded
    default:
      return state
  }
}

const materialReducer = combineReducers({
  materialListError,
  materialListLoading,
  materialListSuccess,
  materials,

  materialAddError,
  materialAddLoading,
  materialAddSuccess,
  materialAdded,

  materialAttachAddError,
  materialAttachAddLoading,
  materialAttachAddSuccess,
  materialAttachAdded,

  materialViewError,
  materialViewLoading,
  materialViewSuccess,
  material,

  materialDeleteError,
  materialDeleteLoading,
  materialDeleteSuccess,
  materialDeleted,

  materialImageAddError,
  materialImageAddLoading,
  materialImageAddSuccess,
  materialImageAdded,
})

export default materialReducer
