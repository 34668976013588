import { combineReducers } from 'redux'
import {
  BILL_LIST_ERROR,
  BILL_LIST_LOADING,
  BILL_LIST_SUCCESS,

  BILL_ADD_ERROR,
  BILL_ADD_LOADING,
  BILL_ADD_SUCCESS,

  BILL_VIEW_ERROR,
  BILL_VIEW_LOADING,
  BILL_VIEW_SUCCESS,

  BILL_DELETE_ERROR,
  BILL_DELETE_LOADING,
  BILL_DELETE_SUCCESS,

  BILL_RESEND_ERROR,
  BILL_RESEND_LOADING,
  BILL_RESEND_SUCCESS,

  BILL_RECHECK_ERROR,
  BILL_RECHECK_LOADING,
  BILL_RECHECK_SUCCESS,

  BILL_CONFIRM_ERROR,
  BILL_CONFIRM_LOADING,
  BILL_CONFIRM_SUCCESS,
} from 'views/Bill/constant'

const billListError = (state = false, action) => {
  switch (action.type) {
    case BILL_LIST_ERROR:
      return action.billListError
    default:
      return state
  }
}

const billListLoading = (state = false, action) => {
  switch (action.type) {
    case BILL_LIST_LOADING:
      return action.billListLoading
    default:
      return state
  }
}

const billListSuccess = (state = false, action) => {
  switch (action.type) {
    case BILL_LIST_SUCCESS:
      return action.billListSuccess
    default:
      return state
  }
}

const bills = (state = [], action) => {
  switch (action.type) {
    case BILL_LIST_SUCCESS:
      return action.bills
    default:
      return state
  }
}

const billAddError = (state = false, action) => {
  switch (action.type) {
    case BILL_ADD_ERROR:
      return action.billAddError
    default:
      return state
  }
}

const billAddLoading = (state = false, action) => {
  switch (action.type) {
    case BILL_ADD_LOADING:
      return action.billAddLoading
    default:
      return state
  }
}

const billAddSuccess = (state = false, action) => {
  switch (action.type) {
    case BILL_ADD_SUCCESS:
      return action.billAddSuccess
    default:
      return state
  }
}

const billAdded = (state = [], action) => {
  switch (action.type) {
    case BILL_ADD_SUCCESS:
      return action.bill
    default:
      return state
  }
}

const billViewError = (state = false, action) => {
  switch (action.type) {
    case BILL_VIEW_ERROR:
      return action.billViewError
    default:
      return state
  }
}

const billViewLoading = (state = false, action) => {
  switch (action.type) {
    case BILL_VIEW_LOADING:
      return action.billViewLoading
    default:
      return state
  }
}

const billViewSuccess = (state = false, action) => {
  switch (action.type) {
    case BILL_VIEW_SUCCESS:
      return action.billViewSuccess
    default:
      return state
  }
}

const bill = (state = [], action) => {
  switch (action.type) {
    case BILL_VIEW_SUCCESS:
      return action.bill
    default:
      return state
  }
}

const billDeleteError = (state = false, action) => {
  switch (action.type) {
    case BILL_DELETE_ERROR:
      return action.billDeleteError
    default:
      return state
  }
}

const billDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case BILL_DELETE_LOADING:
      return action.billDeleteLoading
    default:
      return state
  }
}

const billDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case BILL_DELETE_SUCCESS:
      return action.billDeleteSuccess
    default:
      return state
  }
}

const billDeleted = (state = [], action) => {
  switch (action.type) {
    case BILL_DELETE_SUCCESS:
      return action.bill
    default:
      return state
  }
}

const billResendError = (state = false, action) => {
  switch (action.type) {
    case BILL_RESEND_ERROR:
      return action.billResendError
    default:
      return state
  }
}

const billResendLoading = (state = false, action) => {
  switch (action.type) {
    case BILL_RESEND_LOADING:
      return action.billResendLoading
    default:
      return state
  }
}

const billResendSuccess = (state = false, action) => {
  switch (action.type) {
    case BILL_RESEND_SUCCESS:
      return action.billResendSuccess
    default:
      return state
  }
}

const billRecheckError = (state = false, action) => {
  switch (action.type) {
    case BILL_RECHECK_ERROR:
      return action.billRecheckError
    default:
      return state
  }
}

const billRecheckLoading = (state = false, action) => {
  switch (action.type) {
    case BILL_RECHECK_LOADING:
      return action.billRecheckLoading
    default:
      return state
  }
}

const billRecheckSuccess = (state = false, action) => {
  switch (action.type) {
    case BILL_RECHECK_SUCCESS:
      return action.billRecheckSuccess
    default:
      return state
  }
}

const billConfirmError = (state = false, action) => {
  switch (action.type) {
    case BILL_CONFIRM_ERROR:
      return action.billConfirmError
    default:
      return state
  }
}

const billConfirmLoading = (state = false, action) => {
  switch (action.type) {
    case BILL_CONFIRM_LOADING:
      return action.billConfirmLoading
    default:
      return state
  }
}

const billConfirmSuccess = (state = false, action) => {
  switch (action.type) {
    case BILL_CONFIRM_SUCCESS:
      return action.billConfirmSuccess
    default:
      return state
  }
}


const billReducer = combineReducers({
  billListError,
  billListLoading,
  billListSuccess,
  bills,

  billAddError,
  billAddLoading,
  billAddSuccess,
  billAdded,

  billViewError,
  billViewLoading,
  billViewSuccess,
  bill,

  billDeleteError,
  billDeleteLoading,
  billDeleteSuccess,
  billDeleted,

  billResendError,
  billResendLoading,
  billResendSuccess,

  billRecheckError,
  billRecheckLoading,
  billRecheckSuccess,

  billConfirmError,
  billConfirmLoading,
  billConfirmSuccess,

})

export default billReducer
