import { combineReducers } from 'redux'
import {
  INVOICE_LIST_ERROR,
  INVOICE_LIST_LOADING,
  INVOICE_LIST_SUCCESS,

  INVOICE_ADD_ERROR,
  INVOICE_ADD_LOADING,
  INVOICE_ADD_SUCCESS,

  INVOICE_UPDATE_ERROR,
  INVOICE_UPDATE_LOADING,
  INVOICE_UPDATE_SUCCESS,

  INVOICE_VIEW_ERROR,
  INVOICE_VIEW_LOADING,
  INVOICE_VIEW_SUCCESS,
  
  INVOICE_DELETE_ERROR,
  INVOICE_DELETE_LOADING,
  INVOICE_DELETE_SUCCESS,
} from 'views/Invoice/constant'

const invoiceListError = (state = false, action) => {
  switch (action.type) {
    case INVOICE_LIST_ERROR:
      return action.invoiceListError
    default:
      return state
  }
}

const invoiceListLoading = (state = false, action) => {
  switch (action.type) {
    case INVOICE_LIST_LOADING:
      return action.invoiceListLoading
    default:
      return state
  }
}

const invoiceListSuccess = (state = false, action) => {
  switch (action.type) {
    case INVOICE_LIST_SUCCESS:
      return action.invoiceListSuccess
    default:
      return state
  }
}

const invoices = (state = [], action) => {
  switch (action.type) {
    case INVOICE_LIST_SUCCESS:
      return action.invoices
    default:
      return state
  }
}

const invoiceAddError = (state = false, action) => {
  switch (action.type) {
    case INVOICE_ADD_ERROR:
      return action.invoiceAddError
    default:
      return state
  }
}

const invoiceAddLoading = (state = false, action) => {
  switch (action.type) {
    case INVOICE_ADD_LOADING:
      return action.invoiceAddLoading
    default:
      return state
  }
}

const invoiceAddSuccess = (state = false, action) => {
  switch (action.type) {
    case INVOICE_ADD_SUCCESS:
      return action.invoiceAddSuccess
    default:
      return state
  }
}

const invoiceAdded = (state = [], action) => {
  switch (action.type) {
    case INVOICE_ADD_SUCCESS:
      return action.invoice
    default:
      return state
  }
}

const invoiceUpdateError = (state = false, action) => {
  switch (action.type) {
    case INVOICE_UPDATE_ERROR:
      return action.invoiceUpdateError
    default:
      return state
  }
}

const invoiceUpdateLoading = (state = false, action) => {
  switch (action.type) {
    case INVOICE_UPDATE_LOADING:
      return action.invoiceUpdateLoading
    default:
      return state
  }
}

const invoiceUpdateSuccess = (state = false, action) => {
  switch (action.type) {
    case INVOICE_UPDATE_SUCCESS:
      return action.invoiceUpdateSuccess
    default:
      return state
  }
}

const invoiceUpdated = (state = [], action) => {
  switch (action.type) {
    case INVOICE_UPDATE_SUCCESS:
      return action.invoice
    default:
      return state
  }
}

const invoiceViewError = (state = false, action) => {
  switch (action.type) {
    case INVOICE_VIEW_ERROR:
      return action.invoiceViewError
    default:
      return state
  }
}

const invoiceViewLoading = (state = false, action) => {
  switch (action.type) {
    case INVOICE_VIEW_LOADING:
      return action.invoiceViewLoading
    default:
      return state
  }
}

const invoiceViewSuccess = (state = false, action) => {
  switch (action.type) {
    case INVOICE_VIEW_SUCCESS:
      return action.invoiceViewSuccess
    default:
      return state
  }
}

const invoice = (state = [], action) => {
  switch (action.type) {
    case INVOICE_VIEW_SUCCESS:
      return action.invoice
    default:
      return state
  }
}

const invoiceDeleteError = (state = false, action) => {
  switch (action.type) {
    case INVOICE_DELETE_ERROR:
      return action.invoiceDeleteError
    default:
      return state
  }
}

const invoiceDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case INVOICE_DELETE_LOADING:
      return action.invoiceDeleteLoading
    default:
      return state
  }
}

const invoiceDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case INVOICE_DELETE_SUCCESS:
      return action.invoiceDeleteSuccess
    default:
      return state
  }
}

const invoiceDeleted = (state = [], action) => {
  switch (action.type) {
    case INVOICE_DELETE_SUCCESS:
      return action.invoice
    default:
      return state
  }
}


const invoiceReducer = combineReducers({
  invoiceListError,
  invoiceListLoading,
  invoiceListSuccess,
  invoices,

  invoiceAddError,
  invoiceAddLoading,
  invoiceAddSuccess,
  invoiceAdded,

  invoiceUpdateError,
  invoiceUpdateLoading,
  invoiceUpdateSuccess,
  invoiceUpdated,

  invoiceViewError,
  invoiceViewLoading,
  invoiceViewSuccess,
  invoice,

  invoiceDeleteError,
  invoiceDeleteLoading,
  invoiceDeleteSuccess,
  invoiceDeleted,
})

export default invoiceReducer
