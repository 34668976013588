import { combineReducers } from 'redux'
import {
  SUBJECT_LIST_ERROR,
  SUBJECT_LIST_LOADING,
  SUBJECT_LIST_SUCCESS,

  SUBJECT_ADD_ERROR,
  SUBJECT_ADD_LOADING,
  SUBJECT_ADD_SUCCESS,

  SUBJECT_VIEW_ERROR,
  SUBJECT_VIEW_LOADING,
  SUBJECT_VIEW_SUCCESS,
  
  SUBJECT_DELETE_ERROR,
  SUBJECT_DELETE_LOADING,
  SUBJECT_DELETE_SUCCESS,

  SUBJECT_ACTIVATE_ERROR,
  SUBJECT_ACTIVATE_LOADING,
  SUBJECT_ACTIVATE_SUCCESS,

  SUBJECT_BULK_ERROR,
  SUBJECT_BULK_LOADING,
  SUBJECT_BULK_SUCCESS,

  SUBJECT_IMAGE_ADD_ERROR,
  SUBJECT_IMAGE_ADD_LOADING,
  SUBJECT_IMAGE_ADD_SUCCESS,
} from 'views/Subject/constant'

const subjectListError = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_LIST_ERROR:
      return action.subjectListError
    default:
      return state
  }
}

const subjectListLoading = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_LIST_LOADING:
      return action.subjectListLoading
    default:
      return state
  }
}

const subjectListSuccess = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_LIST_SUCCESS:
      return action.subjectListSuccess
    default:
      return state
  }
}

const subjects = (state = [], action) => {
  switch (action.type) {
    case SUBJECT_LIST_SUCCESS:
      return action.subjects
    default:
      return state
  }
}

const subjectAddError = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_ADD_ERROR:
      return action.subjectAddError
    default:
      return state
  }
}

const subjectAddLoading = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_ADD_LOADING:
      return action.subjectAddLoading
    default:
      return state
  }
}

const subjectAddSuccess = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_ADD_SUCCESS:
      return action.subjectAddSuccess
    default:
      return state
  }
}

const subjectAdded = (state = [], action) => {
  switch (action.type) {
    case SUBJECT_ADD_SUCCESS:
      return action.subject
    default:
      return state
  }
}

const subjectViewError = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_VIEW_ERROR:
      return action.subjectViewError
    default:
      return state
  }
}

const subjectViewLoading = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_VIEW_LOADING:
      return action.subjectViewLoading
    default:
      return state
  }
}

const subjectViewSuccess = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_VIEW_SUCCESS:
      return action.subjectViewSuccess
    default:
      return state
  }
}

const subject = (state = [], action) => {
  switch (action.type) {
    case SUBJECT_VIEW_SUCCESS:
      return action.subject
    default:
      return state
  }
}

const subjectDeleteError = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_DELETE_ERROR:
      return action.subjectDeleteError
    default:
      return state
  }
}

const subjectDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_DELETE_LOADING:
      return action.subjectDeleteLoading
    default:
      return state
  }
}

const subjectDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_DELETE_SUCCESS:
      return action.subjectDeleteSuccess
    default:
      return state
  }
}

const subjectDeleted = (state = [], action) => {
  switch (action.type) {
    case SUBJECT_DELETE_SUCCESS:
      return action.subject
    default:
      return state
  }
}

const subjectActivateError = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_ACTIVATE_ERROR:
      return action.subjectActivateError
    default:
      return state
  }
}

const subjectActivateLoading = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_ACTIVATE_LOADING:
      return action.subjectActivateLoading
    default:
      return state
  }
}

const subjectActivateSuccess = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_ACTIVATE_SUCCESS:
      return action.subjectActivateSuccess
    default:
      return state
  }
}

const subjectActivated = (state = [], action) => {
  switch (action.type) {
    case SUBJECT_ACTIVATE_SUCCESS:
      return action.subject
    default:
      return state
  }
}

const subjectBulkError = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_BULK_ERROR:
      return action.subjectBulkError
    default:
      return state
  }
}

const subjectBulkLoading = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_BULK_LOADING:
      return action.subjectBulkLoading
    default:
      return state
  }
}

const subjectBulkSuccess = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_BULK_SUCCESS:
      return action.subjectBulkSuccess
    default:
      return state
  }
}

const subjectBulked = (state = [], action) => {
  switch (action.type) {
    case SUBJECT_BULK_SUCCESS:
      return action.subject
    default:
      return state
  }
}

const subjectImageAddError = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_IMAGE_ADD_ERROR:
      return action.subjectImageAddError
    default:
      return state
  }
}

const subjectImageAddLoading = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_IMAGE_ADD_LOADING:
      return action.subjectImageAddLoading
    default:
      return state
  }
}

const subjectImageAddSuccess = (state = false, action) => {
  switch (action.type) {
    case SUBJECT_IMAGE_ADD_SUCCESS:
      return action.subjectImageAddSuccess
    default:
      return state
  }
}

const subjectImageAdded = (state = [], action) => {
  switch (action.type) {
    case SUBJECT_IMAGE_ADD_SUCCESS:
      return action.subjectAdded
    default:
      return state
  }
}

const subjectReducer = combineReducers({
  subjectListError,
  subjectListLoading,
  subjectListSuccess,
  subjects,

  subjectAddError,
  subjectAddLoading,
  subjectAddSuccess,
  subjectAdded,

  subjectViewError,
  subjectViewLoading,
  subjectViewSuccess,
  subject,

  subjectDeleteError,
  subjectDeleteLoading,
  subjectDeleteSuccess,
  subjectDeleted,

  subjectActivateError,
  subjectActivateLoading,
  subjectActivateSuccess,
  subjectActivated,

  subjectBulkError,
  subjectBulkLoading,
  subjectBulkSuccess,
  subjectBulked,

  subjectImageAddError,
  subjectImageAddLoading,
  subjectImageAddSuccess,
  subjectImageAdded,
})

export default subjectReducer
