// URL for UI
export const YEAR_GRADE_LIST_URL = 'year_grades'
export const CUR_YEAR_GRADE_LIST_URL = 'current_grade'
export const TEACHER_YEAR_GRADE_LIST_URL = 'teacher_grades'

export const YEAR_GRADE_LIST_ERROR = 'YEAR_GRADE_LIST_ERROR'
export const YEAR_GRADE_LIST_LOADING = 'YEAR_GRADE_LIST_LOADING'
export const YEAR_GRADE_LIST_SUCCESS = 'YEAR_GRADE_LIST_SUCCESS'

export const YEAR_GRADE_ADD_URL = 'year_grade'
export const YEAR_GRADE_ADD_ERROR = 'YEAR_GRADE_ADD_ERROR'
export const YEAR_GRADE_ADD_LOADING = 'YEAR_GRADE_ADD_LOADING'
export const YEAR_GRADE_ADD_SUCCESS = 'YEAR_GRADE_ADD_SUCCESS'

export const YEAR_GRADE_VIEW_URL = 'year_grade'
export const YEAR_GRADE_VIEW_ERROR = 'YEAR_GRADE_VIEW_ERROR'
export const YEAR_GRADE_VIEW_LOADING = 'YEAR_GRADE_VIEW_LOADING'
export const YEAR_GRADE_VIEW_SUCCESS = 'YEAR_GRADE_VIEW_SUCCESS'

export const YEAR_GRADE_DELETE_URL = 'year_grade/delete'
export const YEAR_GRADE_DELETE_ERROR = 'YEAR_GRADE_DELETE_ERROR'
export const YEAR_GRADE_DELETE_LOADING = 'YEAR_GRADE_DELETE_LOADING'
export const YEAR_GRADE_DELETE_SUCCESS = 'YEAR_GRADE_DELETE_SUCCESS'

// URL for UI
export const YEAR_GRADE_URL = {
    list: '/year_grades',
    view: '/year_grade',
    edit: '/year_grade/edit',
    url_param: '/:gradeId',
}