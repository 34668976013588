// URL for UI
export const QUIZ_LIST_URL = 'quizs'
export const QUIZ_STUDENT_LIST_URL = 'student_quizs'
export const QUIZ_YEARGRADE_LIST_URL = 'grade_quizs'

export const QUIZ_LIST_ERROR = 'QUIZ_LIST_ERROR'
export const QUIZ_LIST_LOADING = 'QUIZ_LIST_LOADING'
export const QUIZ_LIST_SUCCESS = 'QUIZ_LIST_SUCCESS'

export const QUIZ_ADD_URL = 'quiz'
export const QUIZ_ADD_ERROR = 'QUIZ_ADD_ERROR'
export const QUIZ_ADD_LOADING = 'QUIZ_ADD_LOADING'
export const QUIZ_ADD_SUCCESS = 'QUIZ_ADD_SUCCESS'

export const QUIZ_VIEW_URL = 'quiz/view'
export const QUIZ_VIEW_ERROR = 'QUIZ_VIEW_ERROR'
export const QUIZ_VIEW_LOADING = 'QUIZ_VIEW_LOADING'
export const QUIZ_VIEW_SUCCESS = 'QUIZ_VIEW_SUCCESS'

export const QUIZ_STUDENT_VIEW_URL = 'student_quiz/view'
export const QUIZ_STUDENT_VIEW_ERROR = 'QUIZ_STUDENT_VIEW_ERROR'
export const QUIZ_STUDENT_VIEW_LOADING = 'QUIZ_STUDENT_VIEW_LOADING'
export const QUIZ_STUDENT_VIEW_SUCCESS = 'QUIZ_STUDENT_VIEW_SUCCESS'

export const QUIZ_DELETE_URL = 'quiz/delete'
export const QUIZ_DELETE_ERROR = 'QUIZ_DELETE_ERROR'
export const QUIZ_DELETE_LOADING = 'QUIZ_DELETE_LOADING'
export const QUIZ_DELETE_SUCCESS = 'QUIZ_DELETE_SUCCESS'

export const QUIZ_QUESTION_LIST_URL = 'quiz_questions'
export const QUIZ_QUESTION_LIST_ERROR = 'QUIZ_QUESTION_LIST_ERROR'
export const QUIZ_QUESTION_LIST_LOADING = 'QUIZ_QUESTION_LIST_LOADING'
export const QUIZ_QUESTION_LIST_SUCCESS = 'QUIZ_QUESTION_LIST_SUCCESS'

export const QUIZ_QUESTION_ADD_URL = 'quiz_question'
export const QUIZ_QUESTION_ADD_ERROR = 'QUIZ_QUESTION_ADD_ERROR'
export const QUIZ_QUESTION_ADD_LOADING = 'QUIZ_QUESTION_ADD_LOADING'
export const QUIZ_QUESTION_ADD_SUCCESS = 'QUIZ_QUESTION_ADD_SUCCESS'

export const QUIZ_ANSWER_ADD_URL = 'student_answer'
export const QUIZ_ANSWER_ADD_ERROR = 'QUIZ_ANSWER_ADD_ERROR'
export const QUIZ_ANSWER_ADD_LOADING = 'QUIZ_ANSWER_ADD_LOADING'
export const QUIZ_ANSWER_ADD_SUCCESS = 'QUIZ_ANSWER_ADD_SUCCESS'

export const QUIZ_STUDENT_ANSWER_LIST_URL = 'student_answer'
export const QUIZ_STUDENT_ANSWER_LIST_ERROR = 'QUIZ_STUDENT_ANSWER_LIST_ERROR'
export const QUIZ_STUDENT_ANSWER_LIST_LOADING = 'QUIZ_STUDENT_ANSWER_LIST_LOADING'
export const QUIZ_STUDENT_ANSWER_LIST_SUCCESS = 'QUIZ_STUDENT_ANSWER_LIST_SUCCESS'

export const QUIZ_CORRECTION_ADD_URL = 'quiz_correction'
export const QUIZ_CORRECTION_ADD_ERROR = 'QUIZ_CORRECTION_ADD_ERROR'
export const QUIZ_CORRECTION_ADD_LOADING = 'QUIZ_CORRECTION_ADD_LOADING'
export const QUIZ_CORRECTION_ADD_SUCCESS = 'QUIZ_CORRECTION_ADD_SUCCESS'

// URL for UI
export const QUIZ_URL = {
    list: '/quizs',
    view: '/quiz',
    studentAnswer: '/quiz_answer',
    studentDetail: '/quiz_student',
    quizCorrection: '/quiz_correction',
    edit: '/quiz/edit',
    url_param: '/:quizId'
}