// URL for UI
export const PRODUCT_LIST_URL = 'products'

export const PRODUCT_LIST_ERROR = 'PRODUCT_LIST_ERROR'
export const PRODUCT_LIST_LOADING = 'PRODUCT_LIST_LOADING'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'

export const PRODUCT_ADD_URL = 'product'
export const PRODUCT_ADD_ERROR = 'PRODUCT_ADD_ERROR'
export const PRODUCT_ADD_LOADING = 'PRODUCT_ADD_LOADING'
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS'

export const PRODUCT_VIEW_URL = 'product'
export const PRODUCT_VIEW_ERROR = 'PRODUCT_VIEW_ERROR'
export const PRODUCT_VIEW_LOADING = 'PRODUCT_VIEW_LOADING'
export const PRODUCT_VIEW_SUCCESS = 'PRODUCT_VIEW_SUCCESS'

export const PRODUCT_DELETE_URL = 'product/delete'
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR'
export const PRODUCT_DELETE_LOADING = 'PRODUCT_DELETE_LOADING'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'

export const PRODUCT_ACTIVATE_URL = 'product/activate'
export const PRODUCT_ACTIVATE_ERROR = 'PRODUCT_ACTIVATE_ERROR'
export const PRODUCT_ACTIVATE_LOADING = 'PRODUCT_ACTIVATE_LOADING'
export const PRODUCT_ACTIVATE_SUCCESS = 'PRODUCT_ACTIVATE_SUCCESS'

// URL for UI
export const PRODUCT_URL = {
    list: '/products',
    view: '/product',
    url_param: '/:productId'
}