import { combineReducers } from 'redux'
import {
  DASHBOARD_ACTIVITY_LIST_ERROR,
  DASHBOARD_ACTIVITY_LIST_LOADING,
  DASHBOARD_ACTIVITY_LIST_SUCCESS,
} from 'views/Dashboard/Activity/constant'

const dashboardActivityListError = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_ACTIVITY_LIST_ERROR:
      return action.dashboardActivityListError
    default:
      return state
  }
}

const dashboardActivityListLoading = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_ACTIVITY_LIST_LOADING:
      return action.dashboardActivityListLoading
    default:
      return state
  }
}

const dashboardActivityListSuccess = (state = false, action) => {
  switch (action.type) {
    case DASHBOARD_ACTIVITY_LIST_SUCCESS:
      return action.dashboardActivityListSuccess
    default:
      return state
  }
}

const dashboardActivities = (state = [], action) => {
  switch (action.type) {
    case DASHBOARD_ACTIVITY_LIST_SUCCESS:
      return action.dashboardActivities
    default:
      return state
  }
}


const dashboardActivityReducer = combineReducers({
  dashboardActivityListError,
  dashboardActivityListLoading,
  dashboardActivityListSuccess,
  dashboardActivities,
})

export default dashboardActivityReducer
