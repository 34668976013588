import { combineReducers } from 'redux'
import {
  SCHOOL_ACCOUNT_LIST_ERROR,
  SCHOOL_ACCOUNT_LIST_LOADING,
  SCHOOL_ACCOUNT_LIST_SUCCESS,

  SCHOOL_ACCOUNT_ADD_ERROR,
  SCHOOL_ACCOUNT_ADD_LOADING,
  SCHOOL_ACCOUNT_ADD_SUCCESS,

  SCHOOL_ACCOUNT_VIEW_ERROR,
  SCHOOL_ACCOUNT_VIEW_LOADING,
  SCHOOL_ACCOUNT_VIEW_SUCCESS,
  
  SCHOOL_ACCOUNT_DELETE_ERROR,
  SCHOOL_ACCOUNT_DELETE_LOADING,
  SCHOOL_ACCOUNT_DELETE_SUCCESS,
} from 'views/SchoolAccount/constant'

const schoolAccountListError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_LIST_ERROR:
      return action.schoolAccountListError
    default:
      return state
  }
}

const schoolAccountListLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_LIST_LOADING:
      return action.schoolAccountListLoading
    default:
      return state
  }
}

const schoolAccountListSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_LIST_SUCCESS:
      return action.schoolAccountListSuccess
    default:
      return state
  }
}

const schoolAccounts = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_LIST_SUCCESS:
      return action.schoolAccounts
    default:
      return state
  }
}

const schoolAccountAddError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_ADD_ERROR:
      return action.schoolAccountAddError
    default:
      return state
  }
}

const schoolAccountAddLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_ADD_LOADING:
      return action.schoolAccountAddLoading
    default:
      return state
  }
}

const schoolAccountAddSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_ADD_SUCCESS:
      return action.schoolAccountAddSuccess
    default:
      return state
  }
}

const schoolAccountAdded = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_ADD_SUCCESS:
      return action.schoolAccount
    default:
      return state
  }
}

const schoolAccountViewError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_VIEW_ERROR:
      return action.schoolAccountViewError
    default:
      return state
  }
}

const schoolAccountViewLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_VIEW_LOADING:
      return action.schoolAccountViewLoading
    default:
      return state
  }
}

const schoolAccountViewSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_VIEW_SUCCESS:
      return action.schoolAccountViewSuccess
    default:
      return state
  }
}

const schoolAccount = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_VIEW_SUCCESS:
      return action.schoolAccount
    default:
      return state
  }
}

const schoolAccountDeleteError = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_DELETE_ERROR:
      return action.schoolAccountDeleteError
    default:
      return state
  }
}

const schoolAccountDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_DELETE_LOADING:
      return action.schoolAccountDeleteLoading
    default:
      return state
  }
}

const schoolAccountDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_DELETE_SUCCESS:
      return action.schoolAccountDeleteSuccess
    default:
      return state
  }
}

const schoolAccountDeleted = (state = [], action) => {
  switch (action.type) {
    case SCHOOL_ACCOUNT_DELETE_SUCCESS:
      return action.schoolAccount
    default:
      return state
  }
}


const schoolAccountReducer = combineReducers({
  schoolAccountListError,
  schoolAccountListLoading,
  schoolAccountListSuccess,
  schoolAccounts,

  schoolAccountAddError,
  schoolAccountAddLoading,
  schoolAccountAddSuccess,
  schoolAccountAdded,

  schoolAccountViewError,
  schoolAccountViewLoading,
  schoolAccountViewSuccess,
  schoolAccount,

  schoolAccountDeleteError,
  schoolAccountDeleteLoading,
  schoolAccountDeleteSuccess,
  schoolAccountDeleted,
})

export default schoolAccountReducer
