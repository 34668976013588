// URL for UI
export const BILL_LIST_URL = 'bills'

export const BILL_LIST_ERROR = 'BILL_LIST_ERROR'
export const BILL_LIST_LOADING = 'BILL_LIST_LOADING'
export const BILL_LIST_SUCCESS = 'BILL_LIST_SUCCESS'

export const BILL_ADD_URL = 'bill'
export const BILL_ADD_ERROR = 'BILL_ADD_ERROR'
export const BILL_ADD_LOADING = 'BILL_ADD_LOADING'
export const BILL_ADD_SUCCESS = 'BILL_ADD_SUCCESS'

export const BILL_VIEW_URL = 'bill'
export const BILL_VIEW_ERROR = 'BILL_VIEW_ERROR'
export const BILL_VIEW_LOADING = 'BILL_VIEW_LOADING'
export const BILL_VIEW_SUCCESS = 'BILL_VIEW_SUCCESS'

export const BILL_DELETE_URL = 'bill/delete'
export const BILL_DELETE_ERROR = 'BILL_DELETE_ERROR'
export const BILL_DELETE_LOADING = 'BILL_DELETE_LOADING'
export const BILL_DELETE_SUCCESS = 'BILL_DELETE_SUCCESS'

export const BILL_RESEND_URL = 'bill_resend'
export const BILL_RESEND_ERROR = 'BILL_RESEND_ERROR'
export const BILL_RESEND_LOADING = 'BILL_RESEND_LOADING'
export const BILL_RESEND_SUCCESS = 'BILL_RESEND_SUCCESS'

export const BILL_RECHECK_URL = 'callback'
export const BILL_RECHECK_ERROR = 'BILL_RECHECK_ERROR'
export const BILL_RECHECK_LOADING = 'BILL_RECHECK_LOADING'
export const BILL_RECHECK_SUCCESS = 'BILL_RECHECK_SUCCESS'

export const BILL_CONFIRM_URL = 'bill_confirm'
export const BILL_CONFIRM_ERROR = 'BILL_CONFIRM_ERROR'
export const BILL_CONFIRM_LOADING = 'BILL_CONFIRM_LOADING'
export const BILL_CONFIRM_SUCCESS = 'BILL_CONFIRM_SUCCESS'

// URL for UI
export const BILL_URL = {
    list: '/bills',
    view: '/bill',
    url_param: '/:billId'
}