// URL for UI
export const STAFF_LIST_URL = 'staffs'

export const STAFF_LIST_ERROR = 'STAFF_LIST_ERROR'
export const STAFF_LIST_LOADING = 'STAFF_LIST_LOADING'
export const STAFF_LIST_SUCCESS = 'STAFF_LIST_SUCCESS'

export const STAFF_ADD_URL = 'staff'
export const STAFF_ADD_ERROR = 'STAFF_ADD_ERROR'
export const STAFF_ADD_LOADING = 'STAFF_ADD_LOADING'
export const STAFF_ADD_SUCCESS = 'STAFF_ADD_SUCCESS'

export const STAFF_RESET_URL = 'staff/password/reset'
export const STAFF_RESET_ERROR = 'STAFF_RESET_ERROR'
export const STAFF_RESET_LOADING = 'STAFF_RESET_LOADING'
export const STAFF_RESET_SUCCESS = 'STAFF_RESET_SUCCESS'

export const STAFF_VIEW_URL = 'staff'
export const STAFF_VIEW_ERROR = 'STAFF_VIEW_ERROR'
export const STAFF_VIEW_LOADING = 'STAFF_VIEW_LOADING'
export const STAFF_VIEW_SUCCESS = 'STAFF_VIEW_SUCCESS'

export const STAFF_DELETE_URL = 'staff/delete'
export const STAFF_DELETE_ERROR = 'STAFF_DELETE_ERROR'
export const STAFF_DELETE_LOADING = 'STAFF_DELETE_LOADING'
export const STAFF_DELETE_SUCCESS = 'STAFF_DELETE_SUCCESS'

export const STAFF_ACTIVATE_URL = 'staff/activate'
export const STAFF_ACTIVATE_ERROR = 'STAFF_ACTIVATE_ERROR'
export const STAFF_ACTIVATE_LOADING = 'STAFF_ACTIVATE_LOADING'
export const STAFF_ACTIVATE_SUCCESS = 'STAFF_ACTIVATE_SUCCESS'

export const STAFF_IMAGE_URL = 'staff/image'
export const STAFF_IMAGE_ERROR = 'STAFF_IMAGE_ERROR'
export const STAFF_IMAGE_LOADING = 'STAFF_IMAGE_LOADING'
export const STAFF_IMAGE_SUCCESS = 'STAFF_IMAGE_SUCCESS'

// URL for UI
export const STAFF_URL = {
    list: '/staffs',
    view: '/staff',
    edit: '/staff/edit',
    url_param: '/:staffId'
}