// URL for UI
export const SCHOOL_ACCOUNT_LIST_URL = 'schoolAccounts'

export const SCHOOL_ACCOUNT_LIST_ERROR = 'SCHOOL_ACCOUNT_LIST_ERROR'
export const SCHOOL_ACCOUNT_LIST_LOADING = 'SCHOOL_ACCOUNT_LIST_LOADING'
export const SCHOOL_ACCOUNT_LIST_SUCCESS = 'SCHOOL_ACCOUNT_LIST_SUCCESS'

export const SCHOOL_ACCOUNT_ADD_URL = 'schoolAccount'
export const SCHOOL_ACCOUNT_ADD_ERROR = 'SCHOOL_ACCOUNT_ADD_ERROR'
export const SCHOOL_ACCOUNT_ADD_LOADING = 'SCHOOL_ACCOUNT_ADD_LOADING'
export const SCHOOL_ACCOUNT_ADD_SUCCESS = 'SCHOOL_ACCOUNT_ADD_SUCCESS'

export const SCHOOL_ACCOUNT_VIEW_URL = 'schoolAccount'
export const SCHOOL_ACCOUNT_VIEW_ERROR = 'SCHOOL_ACCOUNT_VIEW_ERROR'
export const SCHOOL_ACCOUNT_VIEW_LOADING = 'SCHOOL_ACCOUNT_VIEW_LOADING'
export const SCHOOL_ACCOUNT_VIEW_SUCCESS = 'SCHOOL_ACCOUNT_VIEW_SUCCESS'

export const SCHOOL_ACCOUNT_DELETE_URL = 'schoolAccount/delete'
export const SCHOOL_ACCOUNT_DELETE_ERROR = 'SCHOOL_ACCOUNT_DELETE_ERROR'
export const SCHOOL_ACCOUNT_DELETE_LOADING = 'SCHOOL_ACCOUNT_DELETE_LOADING'
export const SCHOOL_ACCOUNT_DELETE_SUCCESS = 'SCHOOL_ACCOUNT_DELETE_SUCCESS'

// URL for UI
export const SCHOOL_ACCOUNT_URL = {
    list: '/schoolAccounts',
    view: '/schoolAccount',
    edit: '/schoolAccount/edit',
    url_param: '/:schoolAccountId'
}