// URL for UI
export const PROVINCE_LIST_URL = 'provinces'
export const REGENCY_LIST_URL = 'regencies'
export const DISTRICT_LIST_URL = 'districts'

export const PROVINCE_LIST_ERROR = 'PROVINCE_LIST_ERROR'
export const PROVINCE_LIST_LOADING = 'PROVINCE_LIST_LOADING'
export const PROVINCE_LIST_SUCCESS = 'PROVINCE_LIST_SUCCESS'

export const REGENCY_LIST_ERROR = 'REGENCY_LIST_ERROR'
export const REGENCY_LIST_LOADING = 'REGENCY_LIST_LOADING'
export const REGENCY_LIST_SUCCESS = 'REGENCY_LIST_SUCCESS'

export const DISTRICT_LIST_ERROR = 'DISTRICT_LIST_ERROR'
export const DISTRICT_LIST_LOADING = 'DISTRICT_LIST_LOADING'
export const DISTRICT_LIST_SUCCESS = 'DISTRICT_LIST_SUCCESS'

// URL for UI
export const REGION_URL = {
    list: '/regions',
}