import { combineReducers } from 'redux'
import {
  YEAR_TEACHER_LIST_ERROR,
  YEAR_TEACHER_LIST_LOADING,
  YEAR_TEACHER_LIST_SUCCESS,

  YEAR_TEACHER_ADD_ERROR,
  YEAR_TEACHER_ADD_LOADING,
  YEAR_TEACHER_ADD_SUCCESS,

  YEAR_TEACHER_VIEW_ERROR,
  YEAR_TEACHER_VIEW_LOADING,
  YEAR_TEACHER_VIEW_SUCCESS,
  
  YEAR_TEACHER_DELETE_ERROR,
  YEAR_TEACHER_DELETE_LOADING,
  YEAR_TEACHER_DELETE_SUCCESS,
} from 'views/YearTeacher/constant'

const yearTeacherListError = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_LIST_ERROR:
      return action.yearTeacherListError
    default:
      return state
  }
}

const yearTeacherListLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_LIST_LOADING:
      return action.yearTeacherListLoading
    default:
      return state
  }
}

const yearTeacherListSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_LIST_SUCCESS:
      return action.yearTeacherListSuccess
    default:
      return state
  }
}

const yearTeachers = (state = [], action) => {
  switch (action.type) {
    case YEAR_TEACHER_LIST_SUCCESS:
      return action.yearTeachers
    default:
      return state
  }
}

const yearTeacherAddError = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_ADD_ERROR:
      return action.yearTeacherAddError
    default:
      return state
  }
}

const yearTeacherAddLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_ADD_LOADING:
      return action.yearTeacherAddLoading
    default:
      return state
  }
}

const yearTeacherAddSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_ADD_SUCCESS:
      return action.yearTeacherAddSuccess
    default:
      return state
  }
}

const yearTeacherAdded = (state = [], action) => {
  switch (action.type) {
    case YEAR_TEACHER_ADD_SUCCESS:
      return action.yearTeacher
    default:
      return state
  }
}

const yearTeacherViewError = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_VIEW_ERROR:
      return action.yearTeacherViewError
    default:
      return state
  }
}

const yearTeacherViewLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_VIEW_LOADING:
      return action.yearTeacherViewLoading
    default:
      return state
  }
}

const yearTeacherViewSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_VIEW_SUCCESS:
      return action.yearTeacherViewSuccess
    default:
      return state
  }
}

const yearTeacher = (state = [], action) => {
  switch (action.type) {
    case YEAR_TEACHER_VIEW_SUCCESS:
      return action.yearTeacher
    default:
      return state
  }
}

const yearTeacherDeleteError = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_DELETE_ERROR:
      return action.yearTeacherDeleteError
    default:
      return state
  }
}

const yearTeacherDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_DELETE_LOADING:
      return action.yearTeacherDeleteLoading
    default:
      return state
  }
}

const yearTeacherDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_TEACHER_DELETE_SUCCESS:
      return action.yearTeacherDeleteSuccess
    default:
      return state
  }
}

const yearTeacherDeleted = (state = [], action) => {
  switch (action.type) {
    case YEAR_TEACHER_DELETE_SUCCESS:
      return action.yearTeacher
    default:
      return state
  }
}


const yearTeacherReducer = combineReducers({
  yearTeacherListError,
  yearTeacherListLoading,
  yearTeacherListSuccess,
  yearTeachers,

  yearTeacherAddError,
  yearTeacherAddLoading,
  yearTeacherAddSuccess,
  yearTeacherAdded,

  yearTeacherViewError,
  yearTeacherViewLoading,
  yearTeacherViewSuccess,
  yearTeacher,

  yearTeacherDeleteError,
  yearTeacherDeleteLoading,
  yearTeacherDeleteSuccess,
  yearTeacherDeleted,
})

export default yearTeacherReducer
