// URL for UI
export const INVOICE_LIST_URL = 'invoices'

export const INVOICE_LIST_ERROR = 'INVOICE_LIST_ERROR'
export const INVOICE_LIST_LOADING = 'INVOICE_LIST_LOADING'
export const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS'

export const INVOICE_ADD_URL = 'invoice'
export const INVOICE_ADD_BY_ACCOUNT_URL = 'invoice/account'
export const INVOICE_ADD_ERROR = 'INVOICE_ADD_ERROR'
export const INVOICE_ADD_LOADING = 'INVOICE_ADD_LOADING'
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS'

export const INVOICE_UPDATE_URL = 'invoice/update'
export const INVOICE_UPDATE_ERROR = 'INVOICE_UPDATE_ERROR'
export const INVOICE_UPDATE_LOADING = 'INVOICE_UPDATE_LOADING'
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS'

export const INVOICE_VIEW_URL = 'invoice'
export const INVOICE_VIEW_ERROR = 'INVOICE_VIEW_ERROR'
export const INVOICE_VIEW_LOADING = 'INVOICE_VIEW_LOADING'
export const INVOICE_VIEW_SUCCESS = 'INVOICE_VIEW_SUCCESS'

export const INVOICE_DELETE_URL = 'invoice/delete'
export const INVOICE_DELETE_ERROR = 'INVOICE_DELETE_ERROR'
export const INVOICE_DELETE_LOADING = 'INVOICE_DELETE_LOADING'
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS'

// URL for UI
export const INVOICE_URL = {
    list: '/invoices',
    view: '/invoice',
    edit: '/invoice/edit',
    url_param: '/:invoiceId'
}