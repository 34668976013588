import { combineReducers } from 'redux'
import {
  YEAR_GRADE_LIST_ERROR,
  YEAR_GRADE_LIST_LOADING,
  YEAR_GRADE_LIST_SUCCESS,

  YEAR_GRADE_ADD_ERROR,
  YEAR_GRADE_ADD_LOADING,
  YEAR_GRADE_ADD_SUCCESS,

  YEAR_GRADE_VIEW_ERROR,
  YEAR_GRADE_VIEW_LOADING,
  YEAR_GRADE_VIEW_SUCCESS,
  
  YEAR_GRADE_DELETE_ERROR,
  YEAR_GRADE_DELETE_LOADING,
  YEAR_GRADE_DELETE_SUCCESS,
} from 'views/YearGrade/constant'

const yearGradeListError = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_LIST_ERROR:
      return action.yearGradeListError
    default:
      return state
  }
}

const yearGradeListLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_LIST_LOADING:
      return action.yearGradeListLoading
    default:
      return state
  }
}

const yearGradeListSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_LIST_SUCCESS:
      return action.yearGradeListSuccess
    default:
      return state
  }
}

const yearGrades = (state = [], action) => {
  switch (action.type) {
    case YEAR_GRADE_LIST_SUCCESS:
      return action.yearGrades
    default:
      return state
  }
}

const yearGradeAddError = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_ADD_ERROR:
      return action.yearGradeAddError
    default:
      return state
  }
}

const yearGradeAddLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_ADD_LOADING:
      return action.yearGradeAddLoading
    default:
      return state
  }
}

const yearGradeAddSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_ADD_SUCCESS:
      return action.yearGradeAddSuccess
    default:
      return state
  }
}

const yearGradeAdded = (state = [], action) => {
  switch (action.type) {
    case YEAR_GRADE_ADD_SUCCESS:
      return action.yearGrade
    default:
      return state
  }
}

const yearGradeViewError = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_VIEW_ERROR:
      return action.yearGradeViewError
    default:
      return state
  }
}

const yearGradeViewLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_VIEW_LOADING:
      return action.yearGradeViewLoading
    default:
      return state
  }
}

const yearGradeViewSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_VIEW_SUCCESS:
      return action.yearGradeViewSuccess
    default:
      return state
  }
}

const yearGrade = (state = [], action) => {
  switch (action.type) {
    case YEAR_GRADE_VIEW_SUCCESS:
      return action.yearGrade
    default:
      return state
  }
}

const yearGradeDeleteError = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_DELETE_ERROR:
      return action.yearGradeDeleteError
    default:
      return state
  }
}

const yearGradeDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_DELETE_LOADING:
      return action.yearGradeDeleteLoading
    default:
      return state
  }
}

const yearGradeDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case YEAR_GRADE_DELETE_SUCCESS:
      return action.yearGradeDeleteSuccess
    default:
      return state
  }
}

const yearGradeDeleted = (state = [], action) => {
  switch (action.type) {
    case YEAR_GRADE_DELETE_SUCCESS:
      return action.yearGrade
    default:
      return state
  }
}


const yearGradeReducer = combineReducers({
  yearGradeListError,
  yearGradeListLoading,
  yearGradeListSuccess,
  yearGrades,

  yearGradeAddError,
  yearGradeAddLoading,
  yearGradeAddSuccess,
  yearGradeAdded,

  yearGradeViewError,
  yearGradeViewLoading,
  yearGradeViewSuccess,
  yearGrade,

  yearGradeDeleteError,
  yearGradeDeleteLoading,
  yearGradeDeleteSuccess,
  yearGradeDeleted,
})

export default yearGradeReducer
