import { combineReducers } from 'redux'
import {
  PROVINCE_LIST_ERROR,
  PROVINCE_LIST_LOADING,
  PROVINCE_LIST_SUCCESS,

  REGENCY_LIST_ERROR,
  REGENCY_LIST_LOADING,
  REGENCY_LIST_SUCCESS,

  DISTRICT_LIST_ERROR,
  DISTRICT_LIST_LOADING,
  DISTRICT_LIST_SUCCESS,
} from 'views/Region/constant'

const provinceListError = (state = false, action) => {
  switch (action.type) {
    case PROVINCE_LIST_ERROR:
      return action.provinceListError
    default:
      return state
  }
}

const provinceListLoading = (state = false, action) => {
  switch (action.type) {
    case PROVINCE_LIST_LOADING:
      return action.provinceListLoading
    default:
      return state
  }
}

const provinceListSuccess = (state = false, action) => {
  switch (action.type) {
    case PROVINCE_LIST_SUCCESS:
      return action.provinceListSuccess
    default:
      return state
  }
}

const provinces = (state = [], action) => {
  switch (action.type) {
    case PROVINCE_LIST_SUCCESS:
      return action.provinces
    default:
      return state
  }
}

const regencyListError = (state = false, action) => {
  switch (action.type) {
    case REGENCY_LIST_ERROR:
      return action.regencyListError
    default:
      return state
  }
}

const regencyListLoading = (state = false, action) => {
  switch (action.type) {
    case REGENCY_LIST_LOADING:
      return action.regencyListLoading
    default:
      return state
  }
}

const regencyListSuccess = (state = false, action) => {
  switch (action.type) {
    case REGENCY_LIST_SUCCESS:
      return action.regencyListSuccess
    default:
      return state
  }
}

const regencies = (state = [], action) => {
  switch (action.type) {
    case REGENCY_LIST_SUCCESS:
      return action.regencies
    default:
      return state
  }
}

const districtListError = (state = false, action) => {
  switch (action.type) {
    case DISTRICT_LIST_ERROR:
      return action.districtListError
    default:
      return state
  }
}

const districtListLoading = (state = false, action) => {
  switch (action.type) {
    case DISTRICT_LIST_LOADING:
      return action.districtListLoading
    default:
      return state
  }
}

const districtListSuccess = (state = false, action) => {
  switch (action.type) {
    case DISTRICT_LIST_SUCCESS:
      return action.districtListSuccess
    default:
      return state
  }
}

const districts = (state = [], action) => {
  switch (action.type) {
    case DISTRICT_LIST_SUCCESS:
      return action.districts
    default:
      return state
  }
}

const regionReducer = combineReducers({
  provinceListError,
  provinceListLoading,
  provinceListSuccess,
  provinces,

  regencyListError,
  regencyListLoading,
  regencyListSuccess,
  regencies,

  districtListError,
  districtListLoading,
  districtListSuccess,
  districts,
})

export default regionReducer
