import { combineReducers } from 'redux'
import {
  BATCH_LIST_ERROR,
  BATCH_LIST_LOADING,
  BATCH_LIST_SUCCESS,

  BATCH_ADD_ERROR,
  BATCH_ADD_LOADING,
  BATCH_ADD_SUCCESS,

  BATCH_VIEW_ERROR,
  BATCH_VIEW_LOADING,
  BATCH_VIEW_SUCCESS,
  
  BATCH_DELETE_ERROR,
  BATCH_DELETE_LOADING,
  BATCH_DELETE_SUCCESS,
} from 'views/Batch/constant'

const batchListError = (state = false, action) => {
  switch (action.type) {
    case BATCH_LIST_ERROR:
      return action.batchListError
    default:
      return state
  }
}

const batchListLoading = (state = false, action) => {
  switch (action.type) {
    case BATCH_LIST_LOADING:
      return action.batchListLoading
    default:
      return state
  }
}

const batchListSuccess = (state = false, action) => {
  switch (action.type) {
    case BATCH_LIST_SUCCESS:
      return action.batchListSuccess
    default:
      return state
  }
}

const batches = (state = [], action) => {
  switch (action.type) {
    case BATCH_LIST_SUCCESS:
      return action.batches
    default:
      return state
  }
}

const batchAddError = (state = false, action) => {
  switch (action.type) {
    case BATCH_ADD_ERROR:
      return action.batchAddError
    default:
      return state
  }
}

const batchAddLoading = (state = false, action) => {
  switch (action.type) {
    case BATCH_ADD_LOADING:
      return action.batchAddLoading
    default:
      return state
  }
}

const batchAddSuccess = (state = false, action) => {
  switch (action.type) {
    case BATCH_ADD_SUCCESS:
      return action.batchAddSuccess
    default:
      return state
  }
}

const batchAdded = (state = [], action) => {
  switch (action.type) {
    case BATCH_ADD_SUCCESS:
      return action.batch
    default:
      return state
  }
}

const batchViewError = (state = false, action) => {
  switch (action.type) {
    case BATCH_VIEW_ERROR:
      return action.batchViewError
    default:
      return state
  }
}

const batchViewLoading = (state = false, action) => {
  switch (action.type) {
    case BATCH_VIEW_LOADING:
      return action.batchViewLoading
    default:
      return state
  }
}

const batchViewSuccess = (state = false, action) => {
  switch (action.type) {
    case BATCH_VIEW_SUCCESS:
      return action.batchViewSuccess
    default:
      return state
  }
}

const batch = (state = [], action) => {
  switch (action.type) {
    case BATCH_VIEW_SUCCESS:
      return action.batch
    default:
      return state
  }
}

const batchDeleteError = (state = false, action) => {
  switch (action.type) {
    case BATCH_DELETE_ERROR:
      return action.batchDeleteError
    default:
      return state
  }
}

const batchDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case BATCH_DELETE_LOADING:
      return action.batchDeleteLoading
    default:
      return state
  }
}

const batchDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case BATCH_DELETE_SUCCESS:
      return action.batchDeleteSuccess
    default:
      return state
  }
}

const batchDeleted = (state = [], action) => {
  switch (action.type) {
    case BATCH_DELETE_SUCCESS:
      return action.batch
    default:
      return state
  }
}


const batchReducer = combineReducers({
  batchListError,
  batchListLoading,
  batchListSuccess,
  batches,

  batchAddError,
  batchAddLoading,
  batchAddSuccess,
  batchAdded,

  batchViewError,
  batchViewLoading,
  batchViewSuccess,
  batch,

  batchDeleteError,
  batchDeleteLoading,
  batchDeleteSuccess,
  batchDeleted,
})

export default batchReducer
