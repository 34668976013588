import { combineReducers } from 'redux'
import {
  ATTENDANCE_LIST_ERROR,
  ATTENDANCE_LIST_LOADING,
  ATTENDANCE_LIST_SUCCESS,

  ATTENDANCE_ADD_ERROR,
  ATTENDANCE_ADD_LOADING,
  ATTENDANCE_ADD_SUCCESS,

  ATTENDANCE_VIEW_ERROR,
  ATTENDANCE_VIEW_LOADING,
  ATTENDANCE_VIEW_SUCCESS,
  
  ATTENDANCE_DELETE_ERROR,
  ATTENDANCE_DELETE_LOADING,
  ATTENDANCE_DELETE_SUCCESS,

  ATTENDANCE_STUDENT_LIST_ERROR,
  ATTENDANCE_STUDENT_LIST_LOADING,
  ATTENDANCE_STUDENT_LIST_SUCCESS,

  ATTENDANCE_STUDENT_PRESENCE_ERROR,
  ATTENDANCE_STUDENT_PRESENCE_LOADING,
  ATTENDANCE_STUDENT_PRESENCE_SUCCESS,

  ATTENDANCE_RECAP_LIST_ERROR,
  ATTENDANCE_RECAP_LIST_LOADING,
  ATTENDANCE_RECAP_LIST_SUCCESS,

  ATTENDANCE_STUDENT_ADD_ERROR,
  ATTENDANCE_STUDENT_ADD_LOADING,
  ATTENDANCE_STUDENT_ADD_SUCCESS,
} from 'views/Attendance/constant'

const attendanceListError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_LIST_ERROR:
      return action.attendanceListError
    default:
      return state
  }
}

const attendanceListLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_LIST_LOADING:
      return action.attendanceListLoading
    default:
      return state
  }
}

const attendanceListSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_LIST_SUCCESS:
      return action.attendanceListSuccess
    default:
      return state
  }
}

const attendances = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_LIST_SUCCESS:
      return action.attendances
    default:
      return state
  }
}

const attendanceAddError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_ADD_ERROR:
      return action.attendanceAddError
    default:
      return state
  }
}

const attendanceAddLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_ADD_LOADING:
      return action.attendanceAddLoading
    default:
      return state
  }
}

const attendanceAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_ADD_SUCCESS:
      return action.attendanceAddSuccess
    default:
      return state
  }
}

const attendanceAdded = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_ADD_SUCCESS:
      return action.attendance
    default:
      return state
  }
}

const attendanceViewError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_VIEW_ERROR:
      return action.attendanceViewError
    default:
      return state
  }
}

const attendanceViewLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_VIEW_LOADING:
      return action.attendanceViewLoading
    default:
      return state
  }
}

const attendanceViewSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_VIEW_SUCCESS:
      return action.attendanceViewSuccess
    default:
      return state
  }
}

const attendance = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_VIEW_SUCCESS:
      return action.attendance
    default:
      return state
  }
}

const attendanceDeleteError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_DELETE_ERROR:
      return action.attendanceDeleteError
    default:
      return state
  }
}

const attendanceDeleteLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_DELETE_LOADING:
      return action.attendanceDeleteLoading
    default:
      return state
  }
}

const attendanceDeleteSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_DELETE_SUCCESS:
      return action.attendanceDeleteSuccess
    default:
      return state
  }
}

const attendanceDeleted = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_DELETE_SUCCESS:
      return action.attendance
    default:
      return state
  }
}

const attendanceStudentListError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_LIST_ERROR:
      return action.attendanceStudentListError
    default:
      return state
  }
}

const attendanceStudentListLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_LIST_LOADING:
      return action.attendanceStudentListLoading
    default:
      return state
  }
}

const attendanceStudentListSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_LIST_SUCCESS:
      return action.attendanceStudentListSuccess
    default:
      return state
  }
}

const attendanceStudents = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_LIST_SUCCESS:
      return action.attendances
    default:
      return state
  }
}

const attendanceStudentPresenceError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_PRESENCE_ERROR:
      return action.attendanceStudentPresenceError
    default:
      return state
  }
}

const attendanceStudentPresenceLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_PRESENCE_LOADING:
      return action.attendanceStudentPresenceLoading
    default:
      return state
  }
}

const attendanceStudentPresenceSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_PRESENCE_SUCCESS:
      return action.attendanceStudentPresenceSuccess
    default:
      return state
  }
}

const attendanceStudentPresenced = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_PRESENCE_SUCCESS:
      return action.attendance
    default:
      return state
  }
}
const attendanceRecapListError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_RECAP_LIST_ERROR:
      return action.attendanceRecapListError
    default:
      return state
  }
}

const attendanceRecapListLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_RECAP_LIST_LOADING:
      return action.attendanceRecapListLoading
    default:
      return state
  }
}

const attendanceRecapListSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_RECAP_LIST_SUCCESS:
      return action.attendanceRecapListSuccess
    default:
      return state
  }
}

const attendanceRecaps = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_RECAP_LIST_SUCCESS:
      return action.attendanceRecaps
    default:
      return state
  }
}

const attendanceStudentAddError = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_ADD_ERROR:
      return action.attendanceStudentAddError
    default:
      return state
  }
}

const attendanceStudentAddLoading = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_ADD_LOADING:
      return action.attendanceStudentAddLoading
    default:
      return state
  }
}

const attendanceStudentAddSuccess = (state = false, action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_ADD_SUCCESS:
      return action.attendanceStudentAddSuccess
    default:
      return state
  }
}

const attendanceStudentAdded = (state = [], action) => {
  switch (action.type) {
    case ATTENDANCE_STUDENT_ADD_SUCCESS:
      return action.attendance
    default:
      return state
  }
}

const attendanceReducer = combineReducers({
  attendanceListError,
  attendanceListLoading,
  attendanceListSuccess,
  attendances,

  attendanceAddError,
  attendanceAddLoading,
  attendanceAddSuccess,
  attendanceAdded,

  attendanceViewError,
  attendanceViewLoading,
  attendanceViewSuccess,
  attendance,

  attendanceDeleteError,
  attendanceDeleteLoading,
  attendanceDeleteSuccess,
  attendanceDeleted,

  attendanceStudentListError,
  attendanceStudentListLoading,
  attendanceStudentListSuccess,
  attendanceStudents,

  attendanceStudentPresenceError,
  attendanceStudentPresenceLoading,
  attendanceStudentPresenceSuccess,
  attendanceStudentPresenced,

  attendanceRecapListError,
  attendanceRecapListLoading,
  attendanceRecapListSuccess,
  attendanceRecaps,

  attendanceStudentAddError,
  attendanceStudentAddLoading,
  attendanceStudentAddSuccess,
  attendanceStudentAdded,
})

export default attendanceReducer
