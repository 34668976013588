// URL for UI
export const YEAR_SUBJECT_LIST_URL = 'year_subjects'

export const YEAR_SUBJECT_LIST_ERROR = 'YEAR_SUBJECT_LIST_ERROR'
export const YEAR_SUBJECT_LIST_LOADING = 'YEAR_SUBJECT_LIST_LOADING'
export const YEAR_SUBJECT_LIST_SUCCESS = 'YEAR_SUBJECT_LIST_SUCCESS'

export const YEAR_SUBJECT_ADD_URL = 'year_subject'
export const YEAR_SUBJECT_ADD_ERROR = 'YEAR_SUBJECT_ADD_ERROR'
export const YEAR_SUBJECT_ADD_LOADING = 'YEAR_SUBJECT_ADD_LOADING'
export const YEAR_SUBJECT_ADD_SUCCESS = 'YEAR_SUBJECT_ADD_SUCCESS'

export const YEAR_SUBJECT_VIEW_URL = 'year_subject'
export const YEAR_SUBJECT_VIEW_ERROR = 'YEAR_SUBJECT_VIEW_ERROR'
export const YEAR_SUBJECT_VIEW_LOADING = 'YEAR_SUBJECT_VIEW_LOADING'
export const YEAR_SUBJECT_VIEW_SUCCESS = 'YEAR_SUBJECT_VIEW_SUCCESS'

export const YEAR_SUBJECT_DELETE_URL = 'year_subject/delete'
export const YEAR_SUBJECT_DELETE_ERROR = 'YEAR_SUBJECT_DELETE_ERROR'
export const YEAR_SUBJECT_DELETE_LOADING = 'YEAR_SUBJECT_DELETE_LOADING'
export const YEAR_SUBJECT_DELETE_SUCCESS = 'YEAR_SUBJECT_DELETE_SUCCESS'
