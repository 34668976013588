// URL for UI
export const BATCH_LIST_URL = 'batches'
export const BATCH_LIST_ERROR = 'BATCH_LIST_ERROR'
export const BATCH_LIST_LOADING = 'BATCH_LIST_LOADING'
export const BATCH_LIST_SUCCESS = 'BATCH_LIST_SUCCESS'

export const BATCH_ADD_URL = 'batch'
export const BATCH_ADD_ERROR = 'BATCH_ADD_ERROR'
export const BATCH_ADD_LOADING = 'BATCH_ADD_LOADING'
export const BATCH_ADD_SUCCESS = 'BATCH_ADD_SUCCESS'

export const BATCH_VIEW_URL = 'batch'
export const BATCH_VIEW_ERROR = 'BATCH_VIEW_ERROR'
export const BATCH_VIEW_LOADING = 'BATCH_VIEW_LOADING'
export const BATCH_VIEW_SUCCESS = 'BATCH_VIEW_SUCCESS'

export const BATCH_DELETE_URL = 'batch/delete'
export const BATCH_DELETE_ERROR = 'BATCH_DELETE_ERROR'
export const BATCH_DELETE_LOADING = 'BATCH_DELETE_LOADING'
export const BATCH_DELETE_SUCCESS = 'BATCH_DELETE_SUCCESS'

// URL for UI
export const BATCH_URL = {
    list: '/batches',
    view: '/batch',
    edit: '/batch/edit',
    url_param: '/:batchId',
}